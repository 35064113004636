import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import { useState } from "react";
import { getAnnotationAnalyticsByIdentity } from "services/annotation.service";

interface Options {
  participantId: string;
}

function useParticipantExperimentSummaryApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);

  const queryClient = useQueryClient();

  const annotationAnalyticsQuery = useQuery({
    queryKey: [QueryKeys.ANNOTATIONS_ANALYTICS_BY_IDENTITY, options.participantId],
    queryFn: async () => getAnnotationAnalyticsByIdentity(options.participantId),
  });

  const refreshAnnotationAnalyticsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ANNOTATIONS_ANALYTICS_BY_IDENTITY, options.participantId],
    });
  };

  return {
    annotationAnalyticsQuery,
    refreshAnnotationAnalyticsQuery,
    setOptions,
  };
}

export default useParticipantExperimentSummaryApi;
