import SkeletonTrialCard from "./SkeletonTrialCard";
const SkeletonTrialCardContainer = () => {
  return (
    <>
      <SkeletonTrialCard />
      <SkeletonTrialCard />
      <SkeletonTrialCard />
      <SkeletonTrialCard />
    </>
  );
};

export default SkeletonTrialCardContainer;
