import { ErrorMessage, Field, FieldProps } from "formik";
import { FormControl, Select as MuiSelect, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextError from "./TextError";
import TextLabel from "../TextLabel";
import { useTranslation } from "react-i18next";

interface Option {
  key: string;
  value: string;
}

interface SelectProps {
  label: string;
  name: string;
  options: Option[];
  isrequired?: boolean;
  [key: string]: any;
}

const Select = (props: SelectProps) => {
  const { label, name, variant, options, placeholder, isrequired, ...rest } =
    props;
  const variantProp = variant ? `${variant}` : {};
  const { t } = useTranslation();
  const renderPlaceholderMenuItem = () => {
    if (placeholder) {
      return (
        <MenuItem value="" disabled>
          {t(placeholder)}
        </MenuItem>
      );
    }
    return null;
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      {label && <TextLabel label={label} required={isrequired} />}
      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <FormControl
            {...variantProp}
            style={{ width: "100%" }}
            error={meta.touched && !!meta.error}
          >
            <MuiSelect
              MenuProps={{
                sx: { zIndex: 10000 },
              }}
              {...field}
              inputProps={{
                name: field.name,
                id: field.name,
              }}
              IconComponent={ArrowDropDownIcon}
              {...rest}
            >
              {renderPlaceholderMenuItem()}
              {options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {t(option.value)}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        )}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
        className="error"
      />
    </FormControl>
  );
};

export default Select;
