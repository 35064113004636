import { Grid, Skeleton, Typography } from "@mui/material";
import {
  StyledDataCardBox,
  StyledDataCardResult,
  StyledDataCardResultType,
} from "features/trials/components/experiment/summary/DataCard.styles";

const SkeletonExperimentSummary = () => {
  const StyledBox = StyledDataCardBox("div");

  return (
    <Grid item xs={12} md={4} sm={4}>
      <StyledBox>
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton variant="circular" width={60} height={60} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <StyledDataCardResult>
              <Skeleton
                variant="text"
                width={80}
                height={60}
                sx={{ fontSize: "1rem" }}
              />
            </StyledDataCardResult>
            <StyledDataCardResultType>
              <Skeleton
                variant="text"
                width={180}
                height={40}
                sx={{ fontSize: "1rem" }}
              />
            </StyledDataCardResultType>
          </Grid>
        </Grid>
      </StyledBox>
    </Grid>
  );
};

export default SkeletonExperimentSummary;
