import React from "react";
import { Grid } from "@mui/material";
import { Participant } from "services/cde.service";
import { useTranslation } from "react-i18next";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import CustomPopoverWrapper from "components/CustomPopoverWrapper";
import DialogBox, {
  ButtonTypes,
} from "components/DialogBox/DialogBox.component";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";
import {
  StyledPopoverBox,
  StyledPopoverItem,
} from "features/trials/components.styles";

interface CustomPopoverProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  participant: Participant;
  handleParticipantDetailsOpen: (participant: Participant) => void;
  handleDeleteParticipant: (participant: Participant) => void;
}

const CustomPopover: React.FC<CustomPopoverProps> = ({
  open,
  onClose,
  anchorEl,
  participant,
  handleParticipantDetailsOpen,
  handleDeleteParticipant,
}) => {
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);
  const { t } = useTranslation();
  const handleConfirmDelete = () => {
    handleDeleteParticipant(participant);
    handleCloseDialogBox();
    onClose();
  };

  const handleCloseDialogBox = async () => {
    closeDialogBox(`deleteParticipant_${participant?.uuid}`);
  };

  const actions = {
    handleCloseDialogBox: handleCloseDialogBox,
    handleConfirmDelete: handleConfirmDelete,
  };

  return (
    <>
      <CustomPopoverWrapper
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        applyZIndex={true}
      >
        <StyledPopoverBox>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <StyledPopoverItem
                onClick={() => {
                  onClose();
                  handleParticipantDetailsOpen(participant);
                }}
              >
                <ManageAccountsOutlinedIcon style={{ marginRight: "1rem" }} />
                {t("view_details")}
              </StyledPopoverItem>
            </Grid>
            {!participant?.analytics?.total ? (
              <Grid item xs={12} md={12} sm={12}>
                <StyledPopoverItem
                  onClick={() => {
                    openDialogBox(`deleteParticipant_${participant?.uuid}`, {
                      title:
                        "rightDrawer.participantDetails.dialog.delete.title",
                      description:
                        "rightDrawer.participantDetails.dialog.delete.message",
                      showCloseButton: false,
                      buttons: [
                        {
                          label:
                            "rightDrawer.participantDetails.dialog.cancel.button.label",
                          action: "handleCloseDialogBox",
                          type: ButtonTypes.SECONDARY,
                        },
                        {
                          label:
                            "rightDrawer.participantDetails.dialog.delete.button.label",
                          action: "handleConfirmDelete",
                          type: ButtonTypes.SECONDARY,
                        },
                      ],
                    });
                    onClose();
                  }}
                >
                  <PersonRemoveOutlinedIcon style={{ marginRight: "0.7rem" }} />
                  {t("delete_participant")}
                </StyledPopoverItem>
              </Grid>
            ) : null}
          </Grid>
        </StyledPopoverBox>
      </CustomPopoverWrapper>
      <DialogBox
        dialogKey={`deleteParticipant_${participant?.uuid}`}
        actions={actions}
      />
    </>
  );
};

export default CustomPopover;
