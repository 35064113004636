import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const TemperatureIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 175 175" sx={sx}>
      <g clipPath="url(#clip0_1424_15261)">
        <path
          d="M146.656 59.9524C146.656 70.5542 146.671 81.156 146.635 91.7542C146.635 92.8508 146.919 93.5477 147.865 94.2305C159.222 102.427 164.692 113.626 163.93 127.511C163.348 138.091 158.662 146.879 150.531 153.823C137.185 165.217 116.041 165.468 102.188 154.067C87.7367 142.17 84.2664 120.613 94.4787 104.903C97.1762 100.754 100.473 97.1807 104.535 94.3613C105.584 93.6326 105.978 92.9004 105.974 91.6375C105.939 70.8478 105.963 50.0581 105.939 29.2685C105.932 23.9269 107.484 19.1478 111.078 15.1717C115.116 10.7145 120.107 8.5 126.267 8.5C133.509 8.5 138.996 11.4821 143.105 17.2163C145.66 20.7821 146.656 24.8643 146.656 29.2119C146.656 39.4599 146.656 49.7079 146.656 59.956V59.9524ZM140.694 61.4912C140.694 50.7762 140.694 40.0577 140.694 29.3427C140.694 25.4975 139.535 22.0909 137.04 19.1053C130.819 11.6625 118.115 13.0103 113.577 21.578C112.152 24.27 111.667 27.1141 111.663 30.0821C111.653 51.222 111.628 72.3583 111.699 93.4982C111.706 95.695 111.039 97.1135 109.15 98.2702C103.227 101.9 98.9379 106.994 96.5452 113.499C92.1427 125.477 94.0497 136.546 102.405 146.15C112.223 157.438 128.451 159.766 141.166 153.104C153.817 146.479 160.793 131.14 157.106 117.316C154.969 109.3 150.492 102.926 143.466 98.4613C141.453 97.1807 140.616 95.7162 140.645 93.286C140.769 82.6877 140.698 72.0895 140.698 61.4912H140.694Z"
        />
        <path
          d="M86.9735 85.6232C89.8093 85.6232 92.6415 85.5949 95.4773 85.6338C97.4906 85.6621 98.7809 86.8401 98.7844 88.5452C98.7844 90.1865 97.6076 91.4459 95.6509 91.4706C89.8058 91.5449 83.957 91.5485 78.1118 91.4706C76.0595 91.4423 74.9145 90.137 75.0138 88.3718C75.1059 86.7234 76.3253 85.6586 78.2891 85.6338C81.1815 85.5949 84.0775 85.6232 86.97 85.6232H86.9735Z"
        />
        <path
          d="M86.9563 28.0833C89.7283 28.0833 92.5038 28.0515 95.2757 28.0939C97.4982 28.1258 98.7814 29.2294 98.7885 30.9946C98.7921 32.7563 97.5053 33.9307 95.3147 33.9484C89.7106 33.9909 84.1029 33.9873 78.4952 33.9484C76.2691 33.9343 75.0143 32.8164 75.0001 31.0336C74.9859 29.2365 76.2301 28.1293 78.4562 28.0939C81.2884 28.0479 84.1206 28.0833 86.9528 28.0833H86.9563Z"
        />
        <path
          d="M86.9747 62.6912C84.1425 62.6912 81.3067 62.716 78.4745 62.6841C76.2378 62.6594 75.0114 61.6017 75.0078 59.7834C75.0043 57.9758 76.2272 56.9004 78.471 56.8898C84.0787 56.8579 89.6864 56.8615 95.2941 56.8898C97.4953 56.9004 98.7891 58.0253 98.7856 59.7799C98.7856 61.5628 97.5024 62.6629 95.2976 62.6877C92.5221 62.7195 89.7502 62.6947 86.9747 62.6947V62.6912Z"
        />
        <path
          d="M87.0808 77.1167C85.3758 77.1167 83.6673 77.1662 81.9623 77.1025C80.1155 77.0318 78.8146 75.7901 78.7933 74.2018C78.7685 72.6064 80.0411 71.2834 81.8914 71.2445C85.3014 71.1737 88.7149 71.1737 92.1249 71.2445C93.9752 71.2869 95.2407 72.6135 95.2052 74.2124C95.1698 75.8043 93.876 77.0282 92.0186 77.099C90.3738 77.1626 88.7255 77.1131 87.0773 77.1131L87.0808 77.1167Z"
        />
        <path
          d="M87.0761 42.4595C88.6712 42.4595 90.2663 42.4206 91.8578 42.4701C93.8606 42.5302 95.1899 43.7224 95.2005 45.3779C95.2076 46.998 94.0024 48.2362 92.0634 48.2892C88.6995 48.3777 85.3321 48.3777 81.9682 48.2928C80.0257 48.2432 78.8099 47.0193 78.8028 45.4026C78.7957 43.7471 80.132 42.5267 82.117 42.4666C83.7689 42.417 85.4242 42.4559 87.0761 42.4559V42.4595Z"
        />
        <path
          d="M129.145 104.213C141.906 106.898 147.245 117.443 146.046 126.782C144.884 135.845 136.752 143.525 127.618 143.928C117.239 144.388 108.459 137.777 106.58 128.006C105.506 122.428 106.552 117.249 109.845 112.555C113.099 107.92 117.675 105.384 123.265 104.263V102.066C123.265 88.758 123.265 75.45 123.265 62.1421C123.265 59.2909 124.183 57.9148 126.101 57.8405C128.068 57.7662 129.138 59.2626 129.138 62.1279C129.142 75.3757 129.138 88.6271 129.138 101.875C129.138 102.635 129.138 103.392 129.138 104.21L129.145 104.213ZM126.154 138.042C133.895 138.06 140.272 131.774 140.322 124.076C140.371 116.449 133.963 110.039 126.228 109.99C118.536 109.94 112.127 116.279 112.071 123.995C112.017 131.632 118.423 138.024 126.15 138.042H126.154Z"
        />
      </g>
    </SvgIcon>
  );
};
