import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FieldConfig } from "utils/form.utils";
import { Participant } from "services/cde.service";
import { TranslationService } from "services/i18n.service";

import FormContainer from "components/FormContainer";
import CustomDrawer from "components/CustomDrawer";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { StyledDeleteButton } from "features/trials/components.styles";
import SkeletonParticipantExperiments from "components/SkeletonParticipantExperiments.component";
import ParticipantExperimentCard from "features/trials/components/participant/ParticipantExperimentCard";

import useParticipantDetailsApi from "../../../features/trials/TrialCards/useParticipantDetailsApi.hook";
import { useParams } from "react-router-dom";
import Loading from "components/Loading";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";
import DialogBox, {
  ButtonTypes,
} from "components/DialogBox/DialogBox.component";

interface IsOpenProps {
  isOpen: boolean;
  trial: any;
  participant: Participant | any;
  onClose: () => void;
  showAnnotations?: boolean;
}
const ParticipantDetails: React.FC<IsOpenProps> = ({
  isOpen,
  trial,
  participant,
  onClose,
  showAnnotations = true,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const { t } = useTranslation();
  const { trialId } = useParams();
  const queries = useParticipantDetailsApi({
    trialId: trial.uuid,
    participantId: participant?.uuid,
  });
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);

  const trialTranslations = trial?.configuration?.data.translations;

  useEffect(() => {
    if (trialTranslations) {
      TranslationService.updateTranslations(trialTranslations);
    }
  }, []);

  const schemaData: FieldConfig[] =
    trial?.configuration?.data.forms.participantRegistration.fields;
  if (!schemaData) return;
  const metadata = participant?.metadata?.participantRegistrationFields
    ? participant?.metadata?.participantRegistrationFields
    : participant?.metadata;

  const initialValues = schemaData.reduce((acc, field) => {
    const { name } = field;
    const value =
      name === "pui"
        ? participant?.pui || ""
        : (metadata && metadata[name]) || "";
    return { ...acc, [name]: value };
  }, {});

  const onSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      setLoading(true);
      const { pui, ...rest } = values;
      const payload = {
        participantId: participant.uuid,
        trialId,
        pui,
        metadata: {
          ...participant.preferences,
          participantRegistrationFields: rest,
        },
      };
      await queries.updateParticipantDetails.mutateAsync(payload);
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setDeleting(true);
      await queries.deleteParticipant.mutateAsync(participant.uuid);
      onClose();
      setDeleting(false);
    } catch (e) {
      setDeleting(false);
    }
  };

  const handleCloseDialogBox = async () => {
    closeDialogBox(`deleteParticipant_${participant?.uuid}`);
  };

  const actions = {
    handleConfirmDelete: handleConfirmDelete,
    handleCloseDialogBox: handleCloseDialogBox,
  };

  return (
    <CustomDrawer
      open={isOpen}
      onClose={onClose}
      additionalProps={{
        titleText: t("rightDrawer.participantDetails.title"),
        id: "rightDrawer.participantDetails",
        headerWidth: 12,
      }}
    >
      <Grid container flexDirection="column" sx={{ padding: "1.5rem" }}>
        <Grid item>
          <Typography
            sx={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("rightDrawer.participantDetails.detailsSection.title")}
          </Typography>
        </Grid>
        <Grid item flexGrow={1}>
          {showAnnotations &&
          !queries.annotationsQuery.data?.total &&
          queries.annotationsQuery.isFetched ? (
            <Typography
              sx={{
                fontWeight: "600",
                display: "flex",
                justifyContent: "end",
                paddingRight: "2rem",
              }}
            >
              <StyledDeleteButton
                onClick={() => {
                  openDialogBox(`deleteParticipant_${participant?.uuid}`, {
                    title: "rightDrawer.participantDetails.dialog.delete.title",
                    description:
                      "rightDrawer.participantDetails.dialog.delete.message",
                    showCloseButton: false,
                    buttons: [
                      {
                        label:
                          "rightDrawer.participantDetails.dialog.cancel.button.label",
                        action: "handleCloseDialogBox",
                        type: ButtonTypes.SECONDARY,
                      },
                      {
                        label:
                          "rightDrawer.participantDetails.dialog.delete.button.label",
                        action: "handleConfirmDelete",
                        type: ButtonTypes.PRIMARY,
                      },
                    ],
                  });
                }}
                sx={{
                  width: "auto",
                }}
              >
                <PersonRemoveIcon style={{ marginRight: "1rem" }} />
                {t(
                  "rightDrawer.participantDetails.detailsSection.button.delete"
                )}
              </StyledDeleteButton>
            </Typography>
          ) : null}
        </Grid>
        {deleting ? (
          <Loading height="50vh" />
        ) : (
          <Grid item flexGrow={1}>
            <FormContainer
              schemaData={schemaData}
              initialValues={initialValues}
              onSubmit={onSubmit}
              labels={{ preSubmit: "update", postSubmit: "updating" }}
              loading={loading}
            />
          </Grid>
        )}
        {showAnnotations ? (
          <>
            {queries.annotationsQuery.data?.length ||
            queries.annotationsQuery.isFetching ? (
              <Grid item flexGrow={1} sx={{ marginTop: "20px" }}>
                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                  {t("rightDrawer.participantDetails.experimentSection.title")}
                </Typography>
              </Grid>
            ) : null}

            {queries.annotationsQuery.isFetched ? (
              queries.annotationsQuery.data?.data.map(
                (annotation: any, i: number) => (
                  <ParticipantExperimentCard
                    key={annotation.uuid}
                    annotation={annotation}
                    participantId={participant.uuid}
                  />
                )
              )
            ) : (
              <Grid item>
                <SkeletonParticipantExperiments />
              </Grid>
            )}
          </>
        ) : null}
      </Grid>
      <DialogBox
        dialogKey={`deleteParticipant_${participant?.uuid}`}
        actions={actions}
      />
    </CustomDrawer>
  );
};

export default ParticipantDetails;
