import { styled } from "@mui/system";
import { Box, Typography, Dialog } from "@mui/material";

export const StyledErrorFallbackWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "100vh",
  width: "50vw",
  margin: "0rem auto",
  [theme.breakpoints.down("sm")]: {
    width: "70vw",
    padding: "2rem",
    margin: "0rem auto",
  },
}));
export const StyledErrorFallbacKTypography = styled(Typography)(
  ({ theme }) => ({
    padding: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  })
);

export const StyledSearchNotFoundWrapper = styled(Box)(({ theme }) => ({
  padding: "0.5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "30vh",
  width: "90vw",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    width: "60vw",
    padding: "0rem",
    margin: "0rem auto",
  },
}));

export const StyledSearchNotFoundMessage = styled(Typography)(({ theme }) => ({
  padding: "0.5rem",
  marginLeft: "2rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    marginBottom: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    marginBottom: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    marginBottom: theme.spacing(2),
  },
}));

export const StyledTextLabelTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  display: "flex",
  fontFamily: "Poppins",
  padding: "1rem 0rem",
}));
