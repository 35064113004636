import InformationBlock, { backgroundType } from "components/InformationBlock";
import { useEffect } from "react";
import { useInformationBlockStore } from "components/InformationBlock/informationblock.store";
import { useTranslation } from "react-i18next";
import { useResultStore } from "../result.store";
import { CardStatus } from "components/Card";

interface IllustrationProps {
  url: string;
  alt: string;
  timeout?: number;
}
interface InformationProps {
  illustrations: IllustrationProps[];
}

const Information: React.FC<InformationProps> = ({ illustrations }) => {
  const { t } = useTranslation();
  const currentEvent = useResultStore((state) => state.currentEvent);
  const events = useResultStore((state) => state.events);

  const setInformationBlock = useInformationBlockStore((state) => state.init);

  const getProgressCardStatus = () => {
    if (!events?.length) return CardStatus.PENDING;
    const event = events.find((e) => e.name === currentEvent?.name);
    if (!event) return CardStatus.IN_PROGRESS;
    return event.status;
  };

  const progressCardStatus = getProgressCardStatus();

  useEffect(() => {
    if (!currentEvent) return;
    const content = getCardContent(progressCardStatus);
    setInformationBlock({
      show: true,
      status: progressCardStatus || CardStatus.INFO,
      illustrations: illustrations as IllustrationProps[],
      title: t(content.title),
      description: t(content.description),
      background: backgroundType.GRADIENT,
    });
  }, [currentEvent, progressCardStatus]);

  const getCardContent = (status: CardStatus) => {
    switch (status) {
      case CardStatus.SUCCESS:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.success,
        };
      case CardStatus.FAIL:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.fail,
        };
      case CardStatus.WARNING:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.warning,
        };
      case CardStatus.IN_PROGRESS:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.progress,
        };
      default:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.pending,
        };
    }
  };
  return <InformationBlock />;
};

export default Information;
