import { create } from "zustand";

export enum Severity {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}

interface SnackbarOptions {
  autoHideDuration?: number;
  [key: string]: any;
}

interface httpErrorNotificationPayload {
  message: string;
  options?: Partial<SnackbarState["options"]>;
  errorLocalId?: string;
}

interface SnackbarState {
  isOpen: boolean;
  message: string;
  severity: Severity;
  options: SnackbarOptions;
  openSnackbar: (
    message: string,
    severity: Severity,
    _options?: Partial<SnackbarState["options"]>
  ) => void;
  openInfo: (
    message: string,
    options?: Partial<SnackbarState["options"]>
  ) => void;
  openSuccess: (
    message: string,
    options?: Partial<SnackbarState["options"]>
  ) => void;
  openWarn: (
    message: string,
    options?: Partial<SnackbarState["options"]>
  ) => void;
  openError: (
    message: string,
    options?: Partial<SnackbarState["options"]>
  ) => void;
  httpErrorNotification: (errorPayload: httpErrorNotificationPayload) => void;
  closeSnackbar: () => void;
}

const useSnackbarStore = create<SnackbarState>((set) => ({
  isOpen: false,
  message: "",
  severity: Severity.info,
  options: {
    autoHideDuration: 6000,
  },
  openSnackbar: (message, severity) =>
    set((state) => ({
      isOpen: true,
      message,
      severity,
    })),
  openInfo: (message, options) =>
    set((state) => ({
      isOpen: true,
      message,
      severity: Severity.info,
      options: { ...state.options, ...options },
    })),
  openSuccess: (message, options = {}) =>
    set((state) => ({
      isOpen: true,
      message,
      severity: Severity.success,
      options: { ...state.options, ...options },
    })),
  openWarn: (message, options = {}) =>
    set((state) => ({
      isOpen: true,
      message,
      severity: Severity.warning,
      options: { ...state.options, ...options },
    })),
  openError: (message, options = {}) =>
    set((state) => ({
      isOpen: true,
      message,
      severity: Severity.error,
      options: { ...state.options, ...options },
    })),
  httpErrorNotification: (payload: httpErrorNotificationPayload) => {
    set((state) => ({
      isOpen: true,
      message: payload.errorLocalId ? payload.errorLocalId : payload.message,
      severity: Severity.error,
      options: { ...state.options, ...payload.options },
    }));
  },
  closeSnackbar: () =>
    set(() => ({
      isOpen: false,
      message: "",
      severity: Severity.info,
    })),
}));

export default useSnackbarStore;
