import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  BreathIcon,
  HumidityIcon,
  TemperatureIcon,
  GearIcon,
  CloudIcon,
  SensorIcon,
  ConnectivityIcon,
  Co2Icon,
} from "components/icons";
import Tooltip from "components/Tooltip";
import {
  StyledTypography,
  iconStyle,
  StatusAnimation,
} from "../Devices.styles";
import { DeviceStateEnum } from "constants/index";

interface Rule {
  name: string;
  background: Backgrounds;
  verdict: boolean;
  tooltip?: string;
}

interface PrechecksIconsProps {
  rules: Rule[];
  state: string;
}
export enum Backgrounds {
  breath = "breath",
  humidity = "humidity",
  temperature = "temperature",
  gear = "gear",
  cloud = "cloud",
  sensor = "sensor",
  connection = "connection",
  co2 = "co2",
  none = "none",
}

const ICON_COMPONENTS: { [key in Backgrounds]: React.ElementType } = {
  [Backgrounds.temperature]: TemperatureIcon,
  [Backgrounds.humidity]: HumidityIcon,
  [Backgrounds.sensor]: SensorIcon,
  [Backgrounds.connection]: ConnectivityIcon,
  [Backgrounds.breath]: BreathIcon,
  [Backgrounds.gear]: GearIcon,
  [Backgrounds.cloud]: CloudIcon,
  [Backgrounds.co2]: Co2Icon,
  [Backgrounds.none]: React.Fragment,
};

const PrechecksIcons: React.FC<PrechecksIconsProps> = ({ rules, state }) => {
  const theme = useTheme();

  const renderIcon = (rule: Rule) => {
    const IconComponent = ICON_COMPONENTS[rule.background] || React.Fragment;
    const backgroundColor =
      rule.verdict &&
      [DeviceStateEnum.Ready, DeviceStateEnum.Preparing].includes(
        state as DeviceStateEnum
      )
        ? theme.palette.success.main
        : theme.palette.pending.dark;
    const isPreparing = !rule.verdict && state === DeviceStateEnum.Preparing;

    const icon = <IconComponent sx={iconStyle(theme, backgroundColor)} />;
    const content = (
      <StyledTypography
        component="span"
        sx={{ backgroundColor, width: "2.75rem", height: "2.75rem" }}
      >
        {isPreparing ? <StatusAnimation>{icon}</StatusAnimation> : icon}
      </StyledTypography>
    );

    return rule.tooltip ? (
      <Tooltip title={rule.tooltip} key={rule.name}>
        <Typography
          component="span"
          sx={{ marginRight: "0.5rem", position: "relative" }}
        >
          {content}
        </Typography>
      </Tooltip>
    ) : (
      <Typography
        key={rule.name}
        component="span"
        sx={{ marginRight: "0.5rem", position: "relative" }}
      >
        {content}
      </Typography>
    );
  };

  return (
    <Grid display="flex" justifyContent="space-between">
      {rules.map(renderIcon)}
    </Grid>
  );
};

export default PrechecksIcons;
