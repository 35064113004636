import { styled } from "@mui/system";
import { Grid as MuiGrid,  } from "@mui/material";

export const StyledGrid = styled(MuiGrid)(() => ({
  position: "relative",
  overflow: "hidden",
  borderRadius: "0.75rem",
  boxShadow: "none",
  backgroundSize: "cover",
  padding: "0.5rem",
  margin: "0.5rem",
  fontFamily: "Poppins",
}));
