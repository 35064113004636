import { DeviceStatusEnum } from "constants/index";
import { get, post } from "utils/privateHttp.utils";
import { buildLocalUrl } from "utils/url.utils";

const FLEET_SERVICE_ROUTES = {
  FLEET_SERVICE_BASE_CLIENT_PATH:
    "/fleet-service/fleet-service/rest/v1/client/",
  FLEET_SERVICE_CLIENT_ASSEMBLIES_GET_DEVICE_STATE:
    "/fleet-service/fleet-service/rest/v1/client/assemblies/:deviceId/state",
  FLEET_SERVICE_CLIENT_ASSEMBLIES_CREATE_RPC_EVENTS:
    "/fleet-service/fleet-service/rest/v1/client/assemblies/:serialNumber/rpc",
};

export enum rpcType {
  ONE = "ONE",
  TWO = "TWO",
}

export interface ICreateRpcEvent {
  serialNumber: string;
  rpcType: rpcType;
  method: string;
  params: { [key: string]: string };
}

export interface ICreateMultiRpcEvent {
  serialNumber: string;
  wait?: number;
  data: {
    rpcType: rpcType;
    method: string;
    params: { [key: string]: string };
  }[];
}

export enum rpcEventCommandType {
  "COMMANDS" = "commands",
}

export type CreateRpcEventParams =
  | {
      method: string;
      params: { [key: string]: string };
      serialNumber: string;
    }
  | {
      serialNumber: string;
      wait?: number;
      commands: Array<{
        method: string;
        params: { [key: string]: string };
      }>;
    };

export interface Metrics {
  [key: string]: string | number | boolean;
}

export interface DeviceState {
  state: { metrics: Metrics };
  serialNumber: string;
  status: DeviceStatusEnum;
}

export const getDeviceStatus = async (
  deviceId: string
): Promise<DeviceState> => {
  const route = buildLocalUrl({
    path: FLEET_SERVICE_ROUTES.FLEET_SERVICE_CLIENT_ASSEMBLIES_GET_DEVICE_STATE,
    params: { deviceId },
  });
  const response = await get(route);
  const { status, state, ...rest } = response;

  return {
    ...rest,
    status,
    state: { ...state, metrics: { ...state.metrics, STATUS: status } },
  };
};

export const getDevicesStatus = async (
  devicesId: string[]
): Promise<DeviceState[]> => {
  if (!devicesId.length) return [];
  const sn = devicesId.map((sn) => `sn=${sn}`).join("&");
  const response = await get(
    `/fleet-service/fleet-service/rest/v1/client/assemblies/states?${sn}`
  );

  return response.map((res: DeviceState) => {
    const { status, state, ...rest } = res;

    return {
      ...rest,
      status,
      state: { ...state, metrics: { ...state.metrics, STATUS: status } },
    };
  });
};

export const createRpcEvent = async (
  payload: ICreateRpcEvent | ICreateMultiRpcEvent
): Promise<DeviceState> => {
  const { serialNumber, ...restPayload } = payload;
  const route = buildLocalUrl({
    path: FLEET_SERVICE_ROUTES.FLEET_SERVICE_CLIENT_ASSEMBLIES_CREATE_RPC_EVENTS,
    params: { serialNumber },
  });
  const response = await post(route, restPayload);

  return response;
};
