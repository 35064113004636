import React from "react";

import { Timer as ShowTime } from "components/Timer.component";

import { useExperimentStore } from "../../../stores/experiment.store";
import { TimerDirection } from "constants/index";

interface TimerProps {
  sx?: any;
  time?: number;
  direction?: TimerDirection;
}

export const Timer: React.FC<TimerProps> = ({ direction, time, sx = {} }) => {
  const timeSinceEvent = useExperimentStore((state) => state.timeSinceEvent);
  const loading = useExperimentStore((state) => state.loading);

  return (
    <ShowTime
      loading={loading}
      seconds={timeSinceEvent}
      direction={direction}
      timeout={time}
      sx={sx}
    />
  );
};
