import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export const useIsMobile = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("sm"));
};

export const useIsTablet = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("md"));
};

export const useIsBetween768And1400 = () => {
    return useMediaQuery('(min-width: 768px) and (max-width: 1400px)');
};
