import { useEffect, useRef, useState } from "react";
import { useBlocker, BlockerFunction } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BrowserRefreshHandler } from "components/BrowserRefreshHandler";

const useCustomBlocker = (
  shouldBlock: BlockerFunction,
  isBlocking: boolean,
  callbackFunction: () => Promise<void>
) => {
  const { t } = useTranslation();
  const [registerEventListener, setRegisterEventListener] = useState(false);
  const handleBrowserRefreshRef = useRef(
    BrowserRefreshHandler(t("experiment.exit.dialog.message"))
  );

  let blocker = useBlocker(isBlocking);

  useEffect(() => {
    const handleNavigation = async () => {
      if (isBlocking && !registerEventListener) {
        setRegisterEventListener(true);
        window.addEventListener(
          "beforeunload",
          handleBrowserRefreshRef.current
        );
      }
      if (blocker.state === "blocked" && isBlocking) {
        if (window.confirm(t("experiment.exit.dialog.message"))) {
          window.removeEventListener(
            "beforeunload",
            handleBrowserRefreshRef.current
          );
          await callbackFunction();
          blocker.proceed();
        } else {
          blocker.reset();
        }
      }
    };
    handleNavigation();
  }, [blocker, isBlocking]);

  useEffect(() => {
    return () => {
      window.removeEventListener(
        "beforeunload",
        handleBrowserRefreshRef.current
      );
    };
  }, []);
};

export default useCustomBlocker;
