import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "constants/queryKeys.constants";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";

import {
  createAnnotation as _createAnnotation,
  createEvent as _createEvent,
  createIngest as _createIngest,
  CreateAnnotation,
  CreateEvent,
  getAnnotation,
  getIngest,
  IngestStatus,
  UpdateAnnotation,
  updateAnnotation as _updateAnnotation,
} from "services/annotation.service";
import { CustomError } from "utils/privateHttp.utils";
import { useExperimentStore } from "stores/experiment.store";
import { ErrorStatus } from "constants/index";

interface Options {
  annotationId?: string;
  ingestId?: string;
}

export enum ErrorTypes {
  TIMEOUT = "TIMEOUT",
}

export default function useAnnotationApi(initialOptions: Options) {
  const queryClient = useQueryClient();
  const [options, setOptions] = useState<Options>(initialOptions);
  const [refetchIngestInterval, setRefetchIngestInterval] = useState<
    number | undefined
  >(5000);
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );

  const currentEvent = useExperimentStore((state) => state.currentEvent);
  const setFailedStep = useExperimentStore((state) => state.setFailedStep);

  const annotation = useQuery({
    queryKey: [QueryKeys.ANNOTATION, options.annotationId],
    queryFn: async () => getAnnotation(options.annotationId),
    enabled: !!options.annotationId,
  });

  const refreshAnnotation = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ANNOTATION, options.annotationId],
    });
  };

  const ingest = useQuery({
    queryKey: [QueryKeys.INGEST, options.ingestId],
    queryFn: async () => {
      const ingest = await getIngest(options.ingestId);
      if (
        ingest.status !== IngestStatus.IN_PROGRESS &&
        ingest.status !== IngestStatus.PENDING
      ) {
        setRefetchIngestInterval(undefined);
      }
      return ingest;
    },
    enabled: !!options.ingestId,
    refetchInterval: refetchIngestInterval,
  });

  const refreshIngest = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.INGEST, options.ingestId],
    });
  };

  const createAnnotation = useMutation({
    mutationFn: async (annotation: CreateAnnotation) => {
      const result = await _createAnnotation(annotation);
      setOptions({ annotationId: result.uuid });
      return result;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ANNOTATION, options.annotationId],
      });
      refreshAnnotation();
    },
    onError: (error: CustomError) => {
      if (currentEvent) setFailedStep(currentEvent.name);
      httpErrorNotification(error);
    },
  });

  const createEvent = useMutation({
    mutationFn: async (event: CreateEvent) => await _createEvent(event),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ANNOTATION, options.annotationId],
      });
      refreshAnnotation();
    },
    onError: (error: CustomError) => {
      if (currentEvent) setFailedStep(currentEvent.name);
      if (
        [ErrorStatus.TIMEOUT, ErrorStatus.TERMINATED].includes(
          error?.customData?.status
        )
      )
        return;
      httpErrorNotification(error);
    },
  });

  const createIngest = useMutation({
    mutationFn: async (annotationId: string) =>
      await _createIngest(annotationId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ANNOTATION, options.annotationId],
      });
      refreshAnnotation();
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });
  const updateAnnotation = useMutation({
    mutationFn: async (payload: UpdateAnnotation) =>
      await _updateAnnotation(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ANNOTATION, options.annotationId],
      });
      refreshAnnotation();
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  useEffect(() => {
    if (annotation.data) {
      setOptions({ ...options, annotationId: annotation.data.uuid });
    }
  }, [annotation.data]);

  return {
    annotation,
    refreshAnnotation,
    createAnnotation,
    createEvent,
    ingest,
    refreshIngest,
    createIngest,
    setOptions,
    updateAnnotation,
  };
}
