import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface NumberIconProps {
  color: string;
  background: string;
  label?: string | number;
  fontSize?: number;
}

export const NumberIcon: React.FC<NumberIconProps> = ({
  color,
  background,
  label,
  fontSize = "1.5rem",
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "2.5rem",
        height: "2.5rem",
        backgroundColor: background,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color,
      }}
    >
      <Typography variant="h5" sx={{ fontSize }}>
        {label && t(label as string)}
      </Typography>
    </Box>
  );
};
