import React, { useState, useEffect, useRef, CSSProperties } from "react";
import { Box } from "@mui/material";
import { getAssetsPath } from "utils/assets.utils";
import { useTranslation } from "react-i18next";

interface IconProps {
  sx?: CSSProperties;
}

const LoadingIcon: React.FC<IconProps> = ({ sx }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex % 90) + 1);
    }, 200);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const currentImagePath = getAssetsPath(`icons/loading/${currentIndex}.svg`);

  useEffect(() => {
    const nextIndex = (currentIndex % 90) + 1;
    const nextImagePath = getAssetsPath(`icons/loading/${nextIndex}.svg`);
    const img = new Image();
    img.src = nextImagePath;
  }, [currentIndex]);

  return (
    <Box component="div" sx={{ width: "fit-content" }}>
      <img
        src={currentImagePath}
        alt={t("experiment.loadingIcon.alt.text")}
        style={{ ...sx }}
        onError={(e) => {
          e.currentTarget.src = getAssetsPath("icons/loading/default.svg");
        }}
      />
    </Box>
  );
};

export default LoadingIcon;
