import { Field, ErrorMessage, FieldProps } from "formik";
import { Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";
import TextError from "./TextError";
import TextLabel from "../TextLabel";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

interface Option {
  key: string;
  value: string;
}

interface CheckboxGroupProps {
  label: string;
  name: string;
  options: Option[];
  isrequired?: boolean;
  [key: string]: any;
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { label, name, options, isrequired, ...rest } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <FormControl sx={{ width: "100%" }}>
      {options.length && (
        <>
          {label && <TextLabel label={label} required={isrequired} />}
          <Field name={name}>
            {({ field, form }: FieldProps) => {
              return (
                <Grid container flexDirection="column">
                  {options.map((option) => (
                    <Grid item xs={6} key={option.key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={option.key}
                            {...field}
                            {...rest}
                            value={option.key}
                            checked={field.value.includes(option.key)}
                            onChange={() => {
                              if (!field.value) {
                                field.value = [];
                              }
                              if (field.value.includes(option.key)) {
                                const nextValue = field.value.filter(
                                  (value: string) => value !== option.key
                                );
                                form.setFieldValue(name, nextValue);
                              } else {
                                const nextValue = field.value.concat(
                                  option.key
                                );
                                form.setFieldValue(name, nextValue);
                              }
                            }}
                            color="default"
                            size="small"
                            sx={{
                              color: theme.palette.grey[600],
                              "& .MuiSvgIcon-root": { fontSize: "2.2rem" },
                              "& .MuiCheckbox-root": { padding: 0 },
                            }}
                          />
                        }
                        label={t(option.value)}
                        htmlFor={option.value}
                      />
                    </Grid>
                  ))}
                </Grid>
              );
            }}
          </Field>
        </>
      )}
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
        className="error"
      />
    </FormControl>
  );
};

export default CheckboxGroup;
