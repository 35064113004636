import React, { useEffect } from "react";
import useDeviceApi from "hooks/useDeviceApi.hook";

import { usePurgeStore } from "../purge.store";
import { CardOrientation } from "constants/index";
import { DeviceValidate } from "services/annotation.service";
import { CardStatus, getCardColor } from "components/Card";
import { Grid } from "@mui/material";
import MetricComponent from "components/MetricsContainer/Metric.component";
import { useTheme } from "@mui/system";

interface MetricsContainerProps {
  onEvent: (status: CardStatus) => void;
  serialNumbers: string[];
}

export const Metrics: React.FC<MetricsContainerProps> = ({
  onEvent,
  serialNumbers,
}) => {
  const theme = useTheme();

  const currentEvent = usePurgeStore((state) => state.currentEvent);
  const validate = usePurgeStore((state) => state.validate);
  const validation = usePurgeStore((state) => state.validation);

  const setRuleChecksStatus = usePurgeStore(
    (state) => state.setRuleChecksStatus
  );
  const setPurgeEventStatus = usePurgeStore(
    (state) => state.setPurgeEventStatus
  );
  const deviceQueries = useDeviceApi({
    serialNumber: serialNumbers && serialNumbers.length ? serialNumbers[0] : "",
  });

  useEffect(() => {
    if (!deviceQueries) return;
    if (!deviceQueries.device.data?.state.metrics) return;
    const metrics = deviceQueries.device.data.state.metrics;
    validate(metrics);
  }, [deviceQueries.device.data, validate]);

  useEffect(() => {
    if (!validation) return;
    if (!validation.results) return;
    if (!validation.results.length) return;
    const status = validation.verdict;
    setRuleChecksStatus(!status);
  }, [validation]);

  const getStatus = (title: string) => {
    const result = validation?.results?.find((item) => item.name === title);
    return result
      ? result.verdict
        ? CardStatus.SUCCESS
        : CardStatus.WARNING
      : CardStatus.SUCCESS;
  };

  useEffect(() => {
    if (deviceQueries.device.isError) {
      setPurgeEventStatus(CardStatus.FAIL);
      onEvent(CardStatus.FAIL);
      return;
    }
  }, [deviceQueries.device.isError]);

  useEffect(() => {
    if (deviceQueries.device.isError) {
      setPurgeEventStatus(CardStatus.FAIL);
      onEvent(CardStatus.FAIL);
      return;
    }
  }, [deviceQueries.device.isError]);

  useEffect(() => {
    if (deviceQueries.device.isError) {
      setPurgeEventStatus(CardStatus.FAIL);
      onEvent(CardStatus.FAIL);
      return;
    }
  }, [deviceQueries.device.isError]);

  if (!deviceQueries.device.data) return null;

  const orientation =
    currentEvent?.validate?.orientation || CardOrientation.HORIZONTAL;
  return (
    <Grid
      container
      spacing={"1.5rem"}
      alignItems={
        CardOrientation.VERTICAL === orientation ? "flex-start" : "center"
      }
      direction={CardOrientation.VERTICAL === orientation ? "column" : "row"}
    >
      {currentEvent?.validate &&
        currentEvent?.validate.rules &&
        currentEvent?.validate?.rules.map((item: DeviceValidate) => {
          const isVisible = item?.isVisible ?? true;
          const status = !isVisible
            ? CardStatus.SUCCESS
            : getStatus(item.title);
          const cardColor = getCardColor(status, theme);
          return (
            <MetricComponent
              key={item.title}
              label={item.title}
              color={cardColor.main}
              status={status}
              sx={isVisible ? { display: "flex" } : { display: "none" }}
            />
          );
        })}
    </Grid>
  );
};
