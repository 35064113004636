import React, { useState, useEffect } from "react";
import { CSSProperties } from "react";

type SvgImageProps = {
  url: string;
  primaryColor: string;
  sx?: CSSProperties;
};

const SvgImage: React.FC<SvgImageProps> = ({ url, primaryColor, sx }) => {
  const [src, setSrc] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isActive = true;
    const fetchAndProcessSvg = async () => {
      try {
        const response = await fetch(url);
        let data = await response.text();
        data = data.replace(/fill="([^"]*)"/g, `fill="${primaryColor}"`);
        data = data.replace(/stroke="([^"]*)"/g, `stroke="${primaryColor}"`);
        const svgBlob = new Blob([data], {
          type: "image/svg+xml;charset=utf-8",
        });
        const svgUrl = URL.createObjectURL(svgBlob);

        if (isActive) {
          setSrc(svgUrl);
        }
      } catch (error) {
        if (isActive) {
          setError("Error fetching or processing the SVG");
          console.error("Error fetching or processing the SVG:", error);
        }
      }
    };

    fetchAndProcessSvg();

    return () => {
      isActive = false;
      if (src) {
        URL.revokeObjectURL(src);
      }
    };
  }, [url, primaryColor]);

  if (error) {
    return <div>{error}</div>;
  }

  return <img src={src || ""} alt="" style={sx} />;
};

export default SvgImage;
