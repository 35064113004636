import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DeviceReadyStatus, DeviceStateEnum } from "constants/index";
import Table from "components/Table";
import { find } from "utils/lodashHelpers";
import { buildLocalUrl } from "utils/url.utils";
import { ROUTES } from "constants/routes.constants";
import Button, { ButtonColor } from "components/Button";
import IconButton from "components/IconButton";
import EditIcon from "components/icons/EditIcon";
import PrechecksIcons from "./PrecheckIcons.component";
import DeviceStatus from "./DeviceStatus.component";
import { CardStatus, getCardColor } from "components/Card";
import { useTheme } from "@mui/material/styles";
import { statusIcon } from "utils/device.utils";
import { useExperimentStore } from "stores/experiment.store";
import { useDeviceStore } from "../device.store";
import Menu from "./Menu.component";
import { StyledGrid } from "../Devices.styles";

interface DevicesListActionsHandlerProps {
  paginatedDevicesData: any;
  showPrechecks: boolean;
  actions: any;
  handleOpenExperimentFormModal: any;
  isButtonDisabled: boolean | undefined;
  searchSerialNumbers: string[];
  input: string;
  deviceData: any;
  hideActionButton: boolean;
}

const DevicesListActionsHandler: React.FC<DevicesListActionsHandlerProps> = ({
  paginatedDevicesData,
  showPrechecks,
  actions,
  handleOpenExperimentFormModal,
  isButtonDisabled,
  searchSerialNumbers,
  input,
  deviceData,
  hideActionButton,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { trialId } = useParams();
  const location = useLocation();
  const [menuAnchor, setMenuAnchor] = useState(undefined);
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(null);
  const deviceStatusIcon: Record<DeviceStateEnum, React.ElementType> = {
    ...statusIcon,
  };
  const selectDeviceOptions = useDeviceStore(
    (state) => state.selectDeviceOptions
  );
  const deviceActions = useDeviceStore((state) => state.deviceActions);
  const serialNumbers = useExperimentStore((state) => state.serialNumbers);
  const setSerialNumbers = useExperimentStore(
    (state) => state.setSerialNumbers
  );

  const handleAddRemove = (status: string, serialNumber: string) => {
    if (serialNumbers.includes(serialNumber)) {
      setSerialNumbers(serialNumbers.filter((num) => num !== serialNumber));
    } else {
      status === DeviceStateEnum.Ready &&
        Number(selectDeviceOptions.maximumDevices) > serialNumbers.length &&
        setSerialNumbers([...serialNumbers, serialNumber]);
      handleOpenExperimentFormModal();
    }
  };

  const handleOpenMenu = (event: any, serialNumber: any) => {
    setMenuAnchor(event.currentTarget);
    setSelectedSerialNumber(serialNumber);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(undefined);
  };

  const handlePurgeNavigate = () => {
    if (!selectedSerialNumber) return;
    const url = buildLocalUrl({
      path: ROUTES.DEVICES_PURGE,
      params: {
        trialId,
        serialNumber: selectedSerialNumber,
      },
      query: {
        referrerUrl: location.pathname,
      },
    });
    navigate(url);
  };

  const performAction = async (deviceData: any, serialNumber: string) => {
    const promises = deviceActions.map(async (deviceAction) => {
      const func = actions[deviceAction.action];
      if (func) return func({ ...deviceAction, sn: serialNumber, deviceData });
    });

    await Promise.all(promises);
  };

  const allColumns = [
    {
      key: "sn",
      label: t("trialDevices.devicelist.label.serialnumber"),
      sx: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
      },
    },
    {
      key: "prechecks",
      label: t("trialDevices.devicelist.label.prechecks"),
      sx: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
      },
    },
    {
      key: "status",
      label: t("trialDevices.devicelist.label.status"),
      width: { md: 2 },
      sx: { display: "flex", justifyContent: "center" },
    },
    {
      key: "action",
      label: t("trialDevices.devicelist.label.action"),
      width: { md: 2 },
      sx: {
        display: hideActionButton ? "none" : "flex",
        justifyContent: "center",
      },
    },
    {
      key: "more",
      label: t("trialDevices.devicelist.label.more"),
      width: { md: 1 },
      sx: {
        display: "flex",
        justifyContent: "end",
      },
    },
  ];

  const columns = showPrechecks
    ? allColumns
    : allColumns.filter((column) => column.key !== "prechecks");

  const data = paginatedDevicesData
    .map((device: any) => {
      if (
        searchSerialNumbers.includes(device.serialNumber) ||
        (searchSerialNumbers.length === 0 && !input)
      ) {
        const deviceConfig = find(deviceData, {
          serialNumber: device.serialNumber,
        });

        const color = getCardColor(
          (deviceConfig?.isDeviceReadyObj?.status === DeviceReadyStatus.COMPLETE
            ? CardStatus.SUCCESS
            : deviceConfig?.isDeviceReadyObj?.status ===
              DeviceReadyStatus.WARNING
            ? CardStatus.FAIL
            : deviceConfig?.isDeviceReadyObj?.status ===
              DeviceReadyStatus.PENDING
            ? CardStatus.WARNING
            : deviceConfig?.isDeviceReadyObj?.status === DeviceReadyStatus.INUSE
            ? CardStatus.PENDING
            : deviceConfig?.isDeviceReadyObj?.status) as CardStatus,
          theme
        );
        return {
          sn: <StyledGrid>{device.serialNumber}</StyledGrid>,
          prechecks: (
            <PrechecksIcons
              rules={deviceConfig.validationResults.results}
              state={deviceConfig?.isDeviceReadyObj?.label}
            />
          ),
          status: (
            <DeviceStatus
              text={deviceConfig?.isDeviceReadyObj?.label}
              color={color}
              Icon={
                deviceStatusIcon[
                  deviceConfig?.isDeviceReadyObj?.label as DeviceStateEnum
                ] as React.ElementType
              }
            />
          ),
          action: !hideActionButton ? (
            serialNumbers.includes(device.serialNumber) ? (
              <Button
                onClick={() => {
                  handleAddRemove(
                    deviceConfig?.isDeviceReadyObj?.label,
                    device.serialNumber
                  );
                }}
                color={ButtonColor.primary}
                size="large"
                disabled={isButtonDisabled}
              >
                {t("Selected")}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleAddRemove(
                    deviceConfig?.isDeviceReadyObj?.label,
                    device.serialNumber
                  );
                }}
                disabled={
                  isButtonDisabled ||
                  !deviceConfig.isDeviceRulePassed ||
                  deviceConfig?.isDeviceReadyObj?.label !==
                    DeviceStateEnum.Ready
                }
                color={ButtonColor.primary}
                size="large"
              >
                {t("deviceCard.button.select.label")}
              </Button>
            )
          ) : null,
          more: ![DeviceStateEnum.InUse, DeviceStateEnum.Unavailable].includes(
            deviceConfig.isDeviceReadyObj.label as DeviceStateEnum
          ) && (
            <>
              <IconButton
                onClick={(event: any) =>
                  handleOpenMenu(event, device.serialNumber)
                }
                disabled={
                  deviceConfig?.isDeviceReadyObj?.status ===
                  DeviceReadyStatus.INUSE
                }
                sx={{ cursor: "pointer" }}
              >
                <EditIcon />
              </IconButton>
            </>
          ),
        };
      }
      return null;
    })
    .filter(Boolean);

  useEffect(() => {
    paginatedDevicesData.forEach(async (device: any) => {
      const deviceConfig = find(deviceData, {
        serialNumber: device.serialNumber,
      });
      await performAction(deviceConfig, device.serialNumber);
    });
  }, []);

  return (
    <>
      <Table columns={columns} data={data} hideHeaderOnMobile={true} />
      <Menu
        anchorEl={menuAnchor || null}
        onClose={handleCloseMenu}
        menuItems={[
          {
            label: t("Purge"),
            icon: "ic_analytics.svg",
            action: handlePurgeNavigate,
          },
        ]}
      />
    </>
  );
};

export default DevicesListActionsHandler;
