import { CardStatus } from "components/Card";
import { useEffect } from "react";
import { useTheme } from "@mui/system";
import { Grid } from "@mui/material";
import Button, { ButtonColor } from "components/Button";
import { useTranslation } from "react-i18next";
import { useResultStore } from "../result.store";

interface Actions {
  [key: string]: (...args: any) => void;
}

interface ExperimentProps {
  actions: Actions;
}

const ActionButtonContainer: React.FC<ExperimentProps> = ({ actions }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentEvent = useResultStore((state) => state.currentEvent);
  const events = useResultStore((state) => state.events);

  useEffect(() => {
    if (!currentEvent) return;
    if (!currentEvent.status) return;
  }, [currentEvent]);

  const getProgressCardStatus = () => {
    if (!events?.length) return CardStatus.PENDING;
    const event = events.find((e) => e.name === currentEvent?.name);
    if (!event) return CardStatus.IN_PROGRESS;
    return event.status;
  };

  const getActionButtons = (status: CardStatus) => {
    if (!currentEvent) return [];
    if (!currentEvent.actions) return [];
    const actionsConfig = currentEvent.actions.reduce(
      (acc: any[], action: any) => {
        if (action.showInStatuses.includes(status)) {
          acc.push({
            action: async () => {
              for (const subAction of action.actions) {
                if (typeof subAction === "object") {
                  const { name, params } = subAction;
                  await actions[name](status, params);
                } else {
                  await actions[subAction](status);
                }
              }
            },
            label: action.label,
            color: action.color,
            isLoading: false,
          });
        }
        return acc;
      },
      []
    );
    return actionsConfig;
  };

  const progressCardStatus = getProgressCardStatus();
  const actionLists = getActionButtons(progressCardStatus);

  if (!actionLists.length) return null;

  return (
    <>
      {actionLists.length > 0 && (
        <Grid item>
          <Grid
            container
            justifyContent={"center"}
            display={"flex"}
            flexDirection={{ md: "row", sm: "column" }}
          >
            {actionLists.map(
              ({ label, action, disabled, isLoading, color }, i) => (
                <Grid
                  item
                  key={`action-button-${label}-${i}`}
                  sx={{ margin: "0.2rem" }}
                >
                  <Button
                    size="large"
                    onClick={action}
                    disabled={disabled}
                    isLoading={isLoading}
                    color={color || ButtonColor.primary}
                    sx={{
                      width: "17rem",
                      ...(disabled && {
                        border: "none",
                        backgroundColor: theme.palette.pending.light,
                      }),
                    }}
                  >
                    {t(label)}
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ActionButtonContainer;
