import InformationBlock, { backgroundType } from "components/InformationBlock";
import { useEffect } from "react";
import { useInformationBlockStore } from "components/InformationBlock/informationblock.store";
import { usePurgeStore } from "../purge.store";
import { CardStatus } from "components/Card";

interface IllustrationProps {
  url: string;
  alt: string;
  timeout?: number;
}
interface InformationProps {
  illustrations: IllustrationProps[];
}

const Information: React.FC<InformationProps> = ({ illustrations }) => {
  const currentEvent = usePurgeStore((state) => state.currentEvent);
  const setInformationBlock = useInformationBlockStore((state) => state.init);
  const purgeEvent = usePurgeStore((state) => state.purgeEvent);

  useEffect(() => {
    if (!currentEvent) return;
    const status = purgeEvent?.status || CardStatus.INFO;
    const content = getCardContent(status);
    setInformationBlock({
      show: true,
      status,
      illustrations: illustrations as IllustrationProps[],
      title: content.title,
      description: content.description,
      background: backgroundType.GRADIENT,
    });
  }, [currentEvent, purgeEvent]);

  type CardContent = {
    title: string | undefined;
    description: string | undefined;
  };

  const getCardContent = (status: CardStatus): CardContent => {
    switch (status) {
      case CardStatus.SUCCESS:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.success,
        };
      case CardStatus.FAIL:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.fail,
        };
      case CardStatus.WARNING:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.warning,
        };
      default:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.pending,
        };
    }
  };
  return <InformationBlock />;
};

export default Information;
