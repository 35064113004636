import { Field, ErrorMessage, FieldProps } from "formik";
import {
  Radio,
  FormControl,
  FormControlLabel,
  Grid,
} from "@mui/material";
import TextError from "./TextError";
import { useTranslation } from "react-i18next";
import TextLabel from "components/TextLabel";

interface Option {
  key: string;
  value: string;
}

interface RadioButtonsProps {
  label: string;
  name: string;
  options: Option[];
  isrequired?: boolean;
  [key: string]: any;
}

const RadioButtons = (props: RadioButtonsProps) => {
  const { label, name, options, isrequired, ...rest } = props;
  const { t } = useTranslation();

  return (
    <FormControl sx={{ width: "100%" }}>
      <TextLabel label={label} required={isrequired} />
      <Field name={name}>
        {({ field }: FieldProps) => {
          return (
            <Grid container spacing={2}>
              {options.map((option) => (
                <Grid item xs={6} key={option.key}>
                  <FormControlLabel
                    control={
                      <Radio
                        id={option.value}
                        {...field}
                        {...rest}
                        value={option.value}
                        checked={field.value === option.value}
                      />
                    }
                    label={t(option.key)}
                    htmlFor={option.value}
                  />
                </Grid>
              ))}
            </Grid>
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
        className="error"
      />
    </FormControl>
  );
};

export default RadioButtons;
