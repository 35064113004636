import React, { ChangeEvent, useEffect, useState } from "react";
import {
  DialogContentText,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AuthService } from "services/auth.service";
import { useTranslation } from "react-i18next";
import { TranslationService } from "services/i18n.service";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import DialogBox from "components/DialogBox/DialogBox.component";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";
import { LanguagesLabel } from "constants/index";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  languages: string[];
}

const LanguageDialog: React.FC<SimpleDialogProps> = ({
  open,
  onClose,
  languages,
}) => {
  const { t } = useTranslation();
  const currentLanguage = AuthService.locale;
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const openErrorNotification = useSnackbarStore((state) => state.openError);
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);

  const handleClose = () => {
    onClose();
  };

  const handleLanguageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage(event.target.value);
  };

  const handleSave = async () => {
    try {
      await TranslationService.changeLanguage(selectedLanguage);
      await AuthService.updateLanguage(selectedLanguage);
      closeDialogBox("languages");
    } catch (err: any) {
      openErrorNotification(err.toString());
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      const descriptionElement = (
        <>
          <DialogContentText sx={{ mb: 2 }}>
            {t("account.changelanguage.dialog.description")}
          </DialogContentText>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            {languages.map((language: string) => (
              <FormControlLabel
                key={language}
                value={language}
                control={<Radio color="secondary" />}
                label={LanguagesLabel[language as keyof typeof LanguagesLabel]}
              />
            ))}
          </RadioGroup>
        </>
      );

      openDialogBox("languages", {
        title: "account.changelanguage.dialog.title",
        description: descriptionElement,
        showCloseButton: false,
        buttons: [
          {
            label: "account.changelanguage.dialog.cancel.button.label",
            action: "handleClose",
          },
          {
            label: "account.changelanguage.dialog.apply.button.label",
            action: "handleSave",
          },
        ],
      });
    } else {
      closeDialogBox("languages");
    }
  }, [open, selectedLanguage]);

  const actions = {
    handleClose: handleClose,
    handleSave: handleSave,
  };
  return <DialogBox dialogKey="languages" actions={actions} />;
};

export default LanguageDialog;
