import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const ProgressIconPie: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 46 46" sx={{ fontSize: "1.6rem", ...sx }}>
      <path d="M23.0001 0C18.4505 0 14.0061 1.34885 10.2226 3.87681C6.43913 6.40477 3.49224 9.99619 1.75143 14.1989C0.00912587 18.4017 -0.44598 23.0265 0.443197 27.4876C1.33087 31.9488 3.52078 36.0449 6.73353 39.2653C9.95529 42.4812 14.0482 44.6742 18.5151 45.5589C22.9745 46.4451 27.6006 45.99 31.8017 44.2476C36.0073 42.5097 39.594 39.5597 42.1249 35.7745C44.6527 31.9893 46 27.5447 46 22.995C45.994 16.8966 43.5683 11.0506 39.2561 6.73973C34.9439 2.43183 29.0996 0.00600823 23.0001 0ZM23.0001 42.714C17.7731 42.714 12.7595 40.6366 9.0601 36.9401C5.36371 33.2405 3.28647 28.2267 3.28647 22.9995C3.28647 17.7708 5.36371 12.757 9.0601 9.05891C12.7595 5.36234 17.7731 3.285 23.0001 3.285V22.9995L36.931 36.9311C35.1061 38.7666 32.9327 40.2176 30.5415 41.2134C28.1504 42.2093 25.588 42.7155 23.0001 42.714Z" />
    </SvgIcon>
  );
};
