import Keycloak, { KeycloakConfig } from "keycloak-js";
import { put } from "../utils/privateHttp.utils";
import { TranslationService } from "services/i18n.service";
class AuthServiceClass {
  private config = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || "",
    VALIDITY_THRESHOLD:
      Number(process.env.REACT_APP_AUTH_PRE_REFRESH_TOKEN_TIME) || 300,
  };
  keycloak: Keycloak;
  private userProfile: any | null = null;
  private initialized: boolean = false;

  constructor(config?: KeycloakConfig) {
    this.keycloak = new Keycloak({
      ...this.config,
      ...config,
    });
  }

  init = async () => {
    try {
      const authenticated = await this.keycloak.init({
        onLoad: "login-required",
        checkLoginIframe: false,
        locale: localStorage.getItem("language") || "en",
        enableLogging:
          process.env.REACT_APP_LOG_LEVEL?.toLowerCase() === "debug",
      });
      if (authenticated) {
        this.userProfile = await this.keycloak.loadUserProfile();
        const language = this.userProfile?.attributes?.locale?.[0] || "en";
        localStorage.setItem("language", language);
        TranslationService.changeLanguage(language);
      }
      this.initialized = true;
      return authenticated;
    } catch (error) {
      throw error;
    }
  };

  async loadUserProfile() {
    try {
      if (this.initialized) {
        this.userProfile = await this.keycloak.loadUserProfile();
      }
    } catch (error) {
      throw error;
    }
  }

  get initStatus() {
    return this.initialized;
  }

  get locale() {
    if (!this.userProfile) return localStorage.getItem("language");
    return this.userProfile?.attributes?.locale?.[0] || "en";
  }

  getToken(): string {
    if (!this.keycloak.token) {
      this.keycloak.login();
      throw new Error("Token is not available");
    }
    return this.keycloak.token;
  }

  clear() {
    this.keycloak.clearToken();
  }

  async checkTokenValidity() {
    return this.keycloak.isTokenExpired(this.config.VALIDITY_THRESHOLD);
  }

  async updateToken() {
    return this.keycloak.updateToken(this.config.VALIDITY_THRESHOLD);
  }

  async getUserProfile() {
    return this.userProfile;
  }

  async login() {
    return this.keycloak.login();
  }

  async logout() {
    return this.keycloak.logout();
  }

  getAuthStatus() {
    return this.keycloak.authenticated;
  }

  async updateLanguage(language: string) {
    try {
      const realmUrl = `${this.keycloak.authServerUrl}/admin/realms/${this.keycloak.realm}`;
      const userId = this.keycloak.subject!;
      const profile = this.keycloak.profile!;
      const profileUrl = `${realmUrl}/users/${userId}`;
      const updatedAttributes = {
        id: profile.id,
        username: profile.username,
        email: profile.email,
        attributes: { locale: [language] },
      };
      TranslationService.changeLanguage(language);
      localStorage.setItem("language", language);
      await put(profileUrl, updatedAttributes);
    } catch (error) {
      throw new Error("Error updating language", { cause: error });
    }
  }
}

export const AuthService = new AuthServiceClass();
