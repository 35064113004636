import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLeftNavigationStore } from "stores/navigation.store";

const DashboardProtectedRoute: React.FC = () => {
  const dashboardCdes = useLeftNavigationStore((state) => state.dashboardCdes);

  if (!dashboardCdes.length) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default DashboardProtectedRoute;
