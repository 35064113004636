import { Grid, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { StyledParticipantDetailTrialWrapper } from "features/trials/components.styles";

const SkeletonParticipantExperiments = () => {
  const theme = useTheme();

  return (
    <Grid item sx={{ marginTop: "1rem" }}>
      <StyledParticipantDetailTrialWrapper
        container
        sx={{
          fontFamily: theme.typography.fontFamily,
          height: {
            md: "6rem",
            sx: "6rem",
            sm: "6rem",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sm={3}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "left" },
            paddingLeft: { md: "1rem" },
          }}
        >
          <Skeleton variant="circular" width={50} height={50} />
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              <Skeleton variant="rectangular" width={150} height={20} />
            </Grid>
            <Grid item md={6} xs={6}>
              <Skeleton variant="rectangular" width={150} height={20} />
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              <Skeleton variant="rectangular" width={150} height={20} />
            </Grid>
            <Grid item md={6} xs={6}>
              <Skeleton variant="rectangular" width={150} height={20} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sm={3}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "right" },
            paddingRight: { md: "1rem" },
          }}
        >
          <Skeleton variant="circular" width={50} height={50} />
        </Grid>
      </StyledParticipantDetailTrialWrapper>
    </Grid>
  );
};

export default SkeletonParticipantExperiments;
