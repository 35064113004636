import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { StyledParticipantDetailTrialWrapper } from "features/trials/components.styles";
import ParticipantExperimentSummary from "./ParticipantExperimentSummary";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AnnotationStatus } from "constants/index";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { buildLocalUrl } from "utils/url.utils";
import { ROUTES } from "constants/routes.constants";

interface SearchResultRowProps {
  annotation: any;
  participantId: string;
}

const ParticipantExperimentCard: React.FC<SearchResultRowProps> = ({
  annotation,
}) => {
  const navigate = useNavigate();
  const [summaryOpen, setSummaryOpen] = useState(false);
  const theme = useTheme();
  const { trialId, pui } = useParams();
  const { t } = useTranslation();

  const handleSummaryClose = () => {
    const url = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_DETAILS,
      params: {
        trialId,
        pui,
      },
    });
    navigate(url);
    setSummaryOpen(false);
  };

  return (
    <Grid item flexGrow={1} sx={{ marginTop: "1rem" }}>
      <StyledParticipantDetailTrialWrapper
        container
        sx={{
          fontFamily: theme.typography.fontFamily,
          height: {
            md: "6rem",
            sx: "6rem",
            sm: "6rem",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sm={3}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "left" },
            paddingLeft: { md: "1rem" },
          }}
        >
          <CheckCircleOutlineIcon
            sx={{ color: theme.palette.primary.main, fontSize: "50px" }}
          />
        </Grid>
        <Grid item sm={6} xs={12} md={6}>
          <Grid container>
            <Grid
              item
              md={6}
              xs={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              {t("rightDrawer.participantExperimentCard.started.label")}
            </Grid>
            <Grid item md={6} xs={6}>
              {moment(annotation.createdAt).format("DD MMM YYYY hh:mm A")}
            </Grid>
            {annotation.status === AnnotationStatus.COMPLETE && (
              <>
                <Grid
                  item
                  md={6}
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "left" },
                  }}
                >
                  {t(
                    `rightDrawer.participantExperimentCard.status.${AnnotationStatus.COMPLETE}`
                  )}
                </Grid>
                <Grid item md={6} xs={6}>
                  {moment(annotation?.completedAt).format(
                    "DD MMM YYYY hh:mm A"
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sm={3}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "right" },
            paddingRight: { md: "1rem" },
          }}
        ></Grid>
      </StyledParticipantDetailTrialWrapper>
      {summaryOpen && (
        <ParticipantExperimentSummary
          isOpen={summaryOpen}
          annotation={annotation}
          onClose={handleSummaryClose}
        />
      )}
    </Grid>
  );
};

export default ParticipantExperimentCard;
