import { Grid, Typography } from "@mui/material";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { getAssetsPath } from "utils/assets.utils";
import Button, { ButtonColor } from "components/Button";
import { useNavigate } from "react-router-dom";
import {
  StyledErrorFallbackWrapper,
  StyledErrorTitle,
  StyledErrorSubTitleContainer,
} from "./Error.styles";
import React from "react";

interface ErrorFallbackProps extends FallbackProps {
  error: Error | null;
}

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const errorCode = (error as any).code || (error as any).status || "500";
  const navigate = useNavigate();

  const { t } = useTranslation();
  const data = t(error?.message || "error.fallback.default.message");

  const handleClick = () => {
    navigate("/");
  };

  return (
    <StyledErrorFallbackWrapper container>
      <Grid
        item
        sx={{
          position: "absolute",
          top: "1rem",
          left: "1rem",
        }}
      >
        <Grid container sx={{ width: "100%" }} justifyContent={"left"}>
          <Grid item>
            <img alt="logo" src={getAssetsPath("images/logo.svg")} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <img alt="" src={getAssetsPath(`icons/${errorCode}.svg`)} />
      </Grid>
      <Grid item>
        <StyledErrorTitle>
          {data.split("|").map((errorMessage: any, index: number) => {
            return (
              <React.Fragment key={index}>{t(errorMessage)}</React.Fragment>
            );
          })}
        </StyledErrorTitle>
      </Grid>
      <StyledErrorSubTitleContainer item>
        <Typography
          dangerouslySetInnerHTML={{
            __html: t("error.fallback.default.subTitle"),
          }}
        />
      </StyledErrorSubTitleContainer>
      <Grid item>
        <Button color={ButtonColor.primary} onClick={handleClick}>
          {t("error.fallback.button.label")}
        </Button>
      </Grid>
    </StyledErrorFallbackWrapper>
  );
}

export default ErrorFallback;
