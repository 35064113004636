import React from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

interface LoadingProps {
  height?: string;
  sx?: any;
}

const Loading: React.FC<LoadingProps> = ({ height, sx }) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height || "80vh",
        ...sx,
      }}
    >
      <CircularProgress style={{ color: "#04e098" }} />
    </Box>
  );
};

export default Loading;
