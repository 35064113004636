import React, { CSSProperties } from "react";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

interface EditIconProps {
  editopen?: boolean;
  sx?: CSSProperties;
}

const EditIcon: React.FC<EditIconProps> = ({ editopen, sx }) => {
  return (
    <PendingOutlinedIcon
      sx={(theme) => ({
        ...sx,
        background: theme.palette.grey[200],
        borderRadius: "50%",
        zIndex: editopen ? "1400" : "1",
        fontSize: theme.spacing(6),
        "&.MuiSvgIcon-root": {
          fill: theme.palette.grey[200],
        },
        "&.MuiSvgIcon-root circle": {
          fill: theme.palette.grey[700],
        },
        height: "auto",
      })}
    />
  );
};

export default EditIcon;
