import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import { useState } from "react";

import {
  AnnotationStatus,
  getAnnotationAnalyticsByIdentity,
  getParticipantAnnotation,
} from "services/annotation.service";
import { getParticipant } from "services/cde.service";
interface Options {
  participantId: string;
  trialId: string;
  from?: string;
  to?: string;
  search?: string;
  skip?: number;
  take?: number;
  expand?: string;
  status?: AnnotationStatus[] | string[];
  fetchAnnotations?: boolean;
}

function useParticipantDashboardApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);
  const queryClient = useQueryClient();
  const annotationAnalyticsQuery = useQuery({
    queryKey: [
      QueryKeys.ANNOTATIONS_ANALYTICS_BY_IDENTITY,
      options.participantId,
    ],
    queryFn: async () =>
      getAnnotationAnalyticsByIdentity(options.participantId, options),
    enabled: !!options.participantId,
  });

  const participant = useQuery({
    queryKey: [
      QueryKeys.TRIAL_PARTICIPANT,
      options.participantId,
      options.trialId,
    ],
    queryFn: async () => getParticipant(options.trialId, options.participantId),
    enabled: !!options.participantId && !!options.trialId,
  });

  const annotationsQuery = useQuery({
    queryKey: [QueryKeys.PARTICIPANT_ANNOTATIONS, options],
    queryFn: async () => {
      const { participantId, status, trialId, ...restOptions } = options;
      const updatedStatus = status?.reduce((acc: string[], current) => {
        if (current === AnnotationStatus.FAILED) {
          return acc.concat([
            AnnotationStatus.TERMINATED,
            AnnotationStatus.TIMEOUT,
          ]);
        }
        return acc.concat(current);
      }, []);

      const updatedOptions = status?.length
        ? { ...restOptions, status: updatedStatus?.join("|") }
        : { ...restOptions, status: " " };
      return getParticipantAnnotation(participantId, {
        ...updatedOptions,
        ...{ order: "createdAt|desc" },
      });
    },
    enabled: options.fetchAnnotations ?? true,
  });

  const refreshAnnotationAnalyticsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: [
        QueryKeys.ANNOTATIONS_ANALYTICS_BY_IDENTITY,
        options.participantId,
      ],
    });
  };

  const refreshParticipantQuery = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TRIAL_PARTICIPANT],
    });
  };

  return {
    annotationAnalyticsQuery,
    refreshAnnotationAnalyticsQuery,
    annotationsQuery,
    participant,
    refreshParticipantQuery,
    setOptions,
    options,
  };
}

export default useParticipantDashboardApi;
