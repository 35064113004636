import { styled } from "@mui/system";
import { Box, Button, Grid, Typography, Paper } from "@mui/material";

interface StyledCardTypographyProps {
  color?: string;
}
interface StyledLabelContainerProps {
  background?: string;
}

export const StyledProgressMessage = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  padding: "2rem",
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
    margin: "0rem auto",
  },
}));

export const StyledTrimerWrapper = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  padding: "2rem",
  color: theme.palette.primary.main,
}));

export const StyledDeviceStateWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "0rem auto",
  height: "70px",
  width: "100%",
  position: "relative",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
  },
}));
export const StyledDeviceState = styled(Typography)(({ theme }) => ({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  width: "calc(50% - 10px)",
  justifyContent: "center",
  margin: "0rem auto",
}));

export const StyledDeviceStateDevider = styled(Typography)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  backgroundColor: "#000000",
  width: "1px",
  transform: "translateX(-50%)",
  padding: "0re, 10rem",
  height: "60%",
  [theme.breakpoints.down("xl")]: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    backgroundColor: "#000000",
    height: "0.5px",
    width: "40%",
  },
}));

export const StyledDeviceSearchGridWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0.5rem 0rem",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

export const StyledParticipantDetailFormWrapper = styled(Box)(({ theme }) => ({
  flex: "none",
  order: 0,
  width: "100%",
  height: "auto",
  flexGrow: 0,
  boxSizing: "border-box",
  alignItems: "center",
}));

export const StyledParticipantDetailTrialWrapper = styled(Grid)(
  ({ theme }) => ({
    border: `0.09rem solid ${theme.palette.primary.light}`,
    boxShadow: "0px 0.2rem 2.5rem rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    background: theme.palette.grey[100],
    borderRadius: "0.8rem",
    alignItems: "center",
  })
);

export const StyledCancelButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: "center",
  fontSize: "1.2rem",
  lineHeight: "1.5rem",
}));

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.text.error,
  border: `1px solid ${theme.palette.text.error}`,
  float: "left",
  cursor: "pointer",
  display: "flex",
  fontSize: "1rem",
  textTransform: "none",
  verticalAlign: "middle",
  justifyContent: "center",
}));

export const StyledCardGrid = styled(Grid)(({ theme }) => ({
  "@media (min-width: 600px)": {
    padding: "0.1rem 0.3rem",
  },
  "@media (min-width: 1280px)": {
    padding: "1rem",
  },
}));

export const StyledCardPaper = styled(Paper)(({ theme }) => ({
  flex: "none",
  order: 0,
  width: "100%",
  border: `1px solid ${theme.palette.grey[400]}`,
  flexGrow: 0,
  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
  boxSizing: "border-box",
  background: `${theme.palette.grey[100]}`,
  borderRadius: "12px",
  paddingTop: "1rem",
}));

export const StyledSmallCardPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.grey[400]}`,
  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
  boxSizing: "border-box",
  background: `${theme.palette.grey[100]}`,
  borderRadius: "0.75rem",
  padding: "1rem 0rem",
}));

export const StyledLabelContainer = styled(Grid)<StyledLabelContainerProps>(
  ({ theme, background }) => ({
    background: background,
    padding: "0.75rem 0.875rem 0.75rem 1rem",
    borderRadius: "3.125rem 0px 0px 3.125rem",
  })
);

export const StyledCardTypography = styled(
  Typography
)<StyledCardTypographyProps>(({ theme, color }) => ({
  color: color ? color : theme.palette.primary.main,
  marginLeft: "1rem",
  fontWeight: 500,
}));

export const StyledCardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    "& > :first-of-type": {
      alignSelf: "flex-start",
    },
    "& > :last-child": {
      alignSelf: "flex-end",
    },
  },
}));

export const StyledContainerBox = styled(Box)(({ theme }) => ({
  minWidth: "100%",
  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
  },
  "@media (min-width: 800px) and (max-width: 1000px)": {
    minWidth: "46rem",
  },
  "@media (min-width: 1001px) and (max-width: 1100px)": {
    minWidth: "53rem",
  },
  "@media (min-width: 1100px) and (max-width: 1200px)": {
    minWidth: "63rem",
  },
  [theme.breakpoints.down("xs")]: {
    minWidth: "100%",
  },
}));

export const StyledTrialName = styled(Typography)(({ theme }) => ({
  flex: "none",
  color: theme.palette.text.primary,
  order: 0,
  height: "3.5rem",
  flexGrow: 0,
  fontSize: "1.3rem",
  marginTop: "1.8rem",
  fontWeight: 500,
  marginLeft: "0.8rem",
}));

export const StyledTrialDescription = styled(Typography)(({ theme }) => ({
  left: "0.8rem",
  color: theme.palette.grey[600],
  height: "3.8rem",
  margin: "0.9rem",
  fontSize: "0.9rem",
  maxWidth: "100%",
  overflowY: "auto",
  fontWeight: 400,
  overflowWrap: "anywhere",
}));

export const StyledTrialDevices = styled(Typography)(({ theme }) => ({
  gap: "1rem",
  flex: "none",
  order: 1,
  height: "2rem",
  padding: "0.7rem 1.2rem",
  flexGrow: 1,
  boxShadow: "none",
  alignItems: "center",
  borderRadius: "4rem 0 0 4rem",
  flexDirection: "row",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
}));

export const StyledTrialDeviceStatus = styled(Typography)(({ theme }) => ({
  display: "contents",
  fontSize: "1.2rem",
  fontWeight: 500,
}));

export const StyledPopoverBox = styled(Box)(({ theme }) => ({
  margin: "2rem 1rem",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "10rem",
  [theme.breakpoints.up("sm")]: {
    width: "14rem",
  },
  [theme.breakpoints.up("md")]: {
    width: "16rem",
  },
}));

export const StyledPopoverItem = styled(Typography)(({ theme }) => ({
  float: "left",
  cursor: "pointer",
  display: "flex",
  fontSize: "1rem",
  textTransform: "none",
  verticalAlign: "middle",
  justifyContent: "center",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  float: "left",
  cursor: "pointer",
  display: "flex",
  fontSize: "1rem",
  textTransform: "none",
  verticalAlign: "middle",
  justifyContent: "center",
}));
