import React from "react";
import { Typography } from "@mui/material";
import { TimerDirection } from "constants/index";

interface TimerProps {
  seconds: number;
  timeout: number | undefined | null;
  loading?: boolean;
  showHours?: boolean;
  direction?: TimerDirection;
  sx?: any;
}

const padStart = (num: number) => num.toString().padStart(2, "0");

const getFormattedTime = (
  diffInSeconds: number,
  showHours: boolean
): string => {
  const diff = diffInSeconds < 0 ? 0 : diffInSeconds;
  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = Math.floor(diff % 60);
  if (showHours || hours > 0) {
    return `${padStart(hours)}:${padStart(minutes)}:${padStart(seconds)}`;
  }
  return `${padStart(minutes)}:${padStart(seconds)}`;
};

export const Timer: React.FC<TimerProps> = ({
  seconds,
  timeout,
  showHours = false,
  sx = {},
  direction,
  loading = false,
}) => {
  let diffInSeconds = seconds;
  if (timeout && timeout > 0 && direction === TimerDirection.DOWN) {
    diffInSeconds = timeout - seconds;
  }

  return (
    <Typography
      component="span"
      sx={{
        fontFamily: "'Chivo Mono', monospace",
        fontSize: "3rem",
        marginLeft: "1rem",
        ...sx,
      }}
    >
      {getFormattedTime(loading ? 0 : diffInSeconds, showHours)}
    </Typography>
  );
};
