export interface Params {
  [key: string]: string | number | undefined;
}
export const buildLocalUrl = (payload: {
  path: string;
  params?: Params;
  query?: Params;
}) => {
  const { path, params, query } = payload;

  let url = path;

  if (params) {
    Object.keys(params).forEach((key) => {
      const paramValue = params[key];
      if (paramValue !== undefined && paramValue !== null) {
        url = url.replace(`:${key}`, paramValue.toString());
      }
    });
  }

  if (query) {
    const queryParams = new URLSearchParams();

    Object.keys(query).forEach((key) => {
      const queryValue = query[key];
      if (queryValue !== undefined && queryValue !== null) {
        queryParams.append(key, queryValue.toString());
      }
    });

    const queryString = queryParams.toString();

    if (queryString) {
      url += `?${queryString}`;
    }
  }

  return url;
};
