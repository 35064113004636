import { Grid, Skeleton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  StyledAnalyticsHeading,
} from "features/trials/components/experiment/summary/DataCard.styles";
import SkeletonExperimentSummary from "./SkeletonExperimentSummary.component";

const SkeletonExperimentSummaryContainer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const DivStyledAnalyticsHeading = StyledAnalyticsHeading("div");
  return (
    <Grid container spacing={isMobile ? 3 : 8}>
      <Grid item xs={12} md={12} sm={12}>
        <DivStyledAnalyticsHeading>
          <Skeleton
            variant="text"
            height={60}
            width={200}
            sx={{ fontSize: "1rem" }}
          />
        </DivStyledAnalyticsHeading>
      </Grid>
      <SkeletonExperimentSummary />
      <SkeletonExperimentSummary />
      <SkeletonExperimentSummary />
    </Grid>
  );
};

export default SkeletonExperimentSummaryContainer;
