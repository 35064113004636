import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const SuccessIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 46 46" sx={{ fontSize: "1.6rem", ...sx }}>
      <path d="M22.9948 -0.00390625C25.1054 -0.00390625 27.1415 0.264395 29.1046 0.803976C31.0647 1.34356 32.8921 2.12163 34.5868 3.13818C36.2816 4.15623 37.8303 5.35464 39.2359 6.7334C40.6415 8.11217 41.8503 9.66085 42.8535 11.3824C43.8566 13.104 44.6242 14.9389 45.1668 16.8856C45.7094 18.8322 45.9851 20.8683 46 22.9954C46 25.1075 45.7272 27.1451 45.1936 29.1036C44.654 31.0652 43.8745 32.8912 42.8594 34.5859C41.8399 36.2807 40.6444 37.8294 39.2642 39.235C37.8854 40.6405 36.3353 41.8464 34.6137 42.851C32.8921 43.8557 31.0587 44.6263 29.1135 45.1659C27.1683 45.704 25.1308 45.9842 23.0052 46.0021C20.8916 46.0021 18.857 45.7278 16.8939 45.1927C14.9324 44.6546 13.1065 43.8736 11.4117 42.8585C9.71992 41.8419 8.17121 40.6435 6.76413 39.2633C5.35854 37.889 4.15118 36.3403 3.14655 34.6172C2.1449 32.8941 1.3728 31.0593 0.834707 29.1126C0.293636 27.1674 0.0149055 25.1328 0 23.0043C0 20.8937 0.269779 18.8576 0.807869 16.8945C1.34596 14.9329 2.12404 13.1055 3.13911 11.4152C4.16014 9.72346 5.35408 8.17179 6.73433 6.7662C8.10863 5.35911 9.66029 4.15325 11.3819 3.15011C13.1035 2.14846 14.9413 1.37784 16.885 0.836767C18.8317 0.288243 20.8663 0.0109993 22.9948 -0.00390625ZM36.5365 15.3816L33.4823 12.326L18.6782 27.1302L12.5013 20.9503L9.44415 24.0045L18.6737 33.2355L36.5365 15.3816Z" />
    </SvgIcon>
  );
};
