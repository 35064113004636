import { styled } from "@mui/system";
import { Chip, Grid, InputAdornment, Typography } from "@mui/material";

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: "2.5rem",
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.white.light,
  width: "100%",
  height: "3rem",
  borderRadius: "0.75rem 0.75rem 0rem 0rem",
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  borderRight: `1px solid ${theme.palette.text.secondary}`,
  borderLeft: `1px solid ${theme.palette.text.secondary}`,
  background: theme.palette.pending.main,
  padding: "0.5rem",
}));

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  width: "100%",
  borderRadius: "0.75rem",
  border: `1px solid ${theme.palette.grey[400]}`,
  background: theme.palette.grey[100],
  marginTop: "0.75rem",
  paddingLeft: "0.5rem",
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: "0.1rem",
  cursor: "pointer",
}));

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  "&:after": {
    content: '""',
    display: "block",
    position: "absolute",
    width: "4.25rem",
    backgroundColor: theme.palette.white.dark,
    right: 0,
    top: 0,
    bottom: 0,
    borderRadius: "0 10px 10px 0",
  },
}));
