import React from "react";
import { Grid } from "@mui/material";
import { Formik, Form, FormikProps } from "formik";
import FormikControl from "components/form/FormikControl";
import { useTranslation } from "react-i18next";
import { FieldConfig, generateValidationSchema } from "utils/form.utils";
import Button, { ButtonColor } from "./Button";
import TextError from "./form/TextError";

interface InitialFields<T> {
  [key: string]: T | "";
}

interface FormContainerProps {
  schemaData: FieldConfig[];
  onSubmit: (values: any, data: any) => void;
  initialValues: InitialFields<string | number>;
  onClose?: () => void | undefined;
  loading?: boolean;
  customError?: { isError: boolean; message: string };
  labels: {
    preSubmit: string;
    postSubmit: string;
    cancel?: string;
    default?: string;
  };
}
const FormContainer: React.FC<FormContainerProps> = ({
  schemaData,
  onSubmit,
  onClose,
  initialValues,
  labels,
  loading = false,
  customError,
}) => {
  const { t } = useTranslation();

  const validationSchema = generateValidationSchema(schemaData);

  const handleFieldVisibility = (formik: FormikProps<any>) => {
    const schemaDataMap = schemaData.reduce((map, item) => {
      map[item.name] = item;
      return map;
    }, {} as { [key: string]: FieldConfig });

    schemaData.forEach((field) => {
      const { name, hasChildren, options } = field;
      if (hasChildren && formik.values[name]) {
        options?.forEach(({ children: childFieldName, key }) => {
          if (childFieldName && schemaDataMap[childFieldName]) {
            const childField = schemaDataMap[childFieldName];
            const shouldShowChild = key === formik.values[name];
            childField.width = shouldShowChild ? 6 : 0;

            if (!shouldShowChild && formik.values[childFieldName] !== "") {
              formik.setFieldValue(childFieldName, "");
            }
          }
        });
      }
    });
  };

  const renderFields = (formik: any) => {
    return schemaData.map((field) => {
      const { name, type, label, placeholder, width, required } = field;

      const control = type === "text" || type === "number" ? "input" : type;

      return (
        width > 0 && (
          <Grid item md={width} sm={12} xs={12} key={name}>
            <FormikControl
              control={control}
              label={t(label)}
              isrequired={required}
              type={type}
              name={name}
              placeholder={t(placeholder)}
              options={field.options}
              onClose={onClose}
            />
          </Grid>
        )
      );
    });
  };

  return (
    <Grid container flexDirection="column">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          handleFieldVisibility(formik);
          return (
            <Form>
              <Grid
                container
                spacing={2}
                display={"flex"}
                flexDirection={"row"}
                alignContent={"center"}
                justifyContent="left"
              >
                {renderFields(formik)}
                {customError?.isError && (
                  <TextError sx={{ padding: "1rem" }}>
                    {customError.message}
                  </TextError>
                )}
                <Grid item sx={{ width: "100%" }}>
                  <Grid
                    container
                    display={"flex"}
                    justifyContent={"space-around"}
                  >
                    {!!onClose ? (
                      <Grid
                        item
                        md={!!onClose ? 5 : 12}
                        sm={10}
                        xs={12}
                        sx={{ width: "100%" }}
                      >
                        <Button
                          disabled={!onClose}
                          onClick={onClose}
                          color={ButtonColor.secondary}
                          sx={{
                            width: {
                              marginTop: "1.2rem",
                              xs: "-webkit-fill-available",
                              textTransform: "none",
                            },
                          }}
                        >
                          {t(labels?.cancel || "component.form.cancel")}
                        </Button>
                      </Grid>
                    ) : null}
                    <Grid item md={!!onClose ? 5 : 12} sm={10} xs={12}>
                      <Button
                        disabled={formik.isSubmitting}
                        type="submit"
                        color={ButtonColor.primary}
                        isLoading={loading}
                        sx={{
                          width: {
                            marginTop: "1.2rem",
                            xs: "-webkit-fill-available",
                            textTransform: "none",
                          },
                        }}
                      >
                        {formik.isSubmitting
                          ? t(`${labels.postSubmit}`)
                          : t(`${labels.preSubmit}`)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default FormContainer;
