import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const FailIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 46 47" sx={sx}>
      <path
        d="M22.9993 0.942383C10.2825 0.942383 0 11.2234 0 23.9416C0 36.6599 10.2795 46.9424 22.9993 46.9424C35.7175 46.9424 46 36.6629 46 23.9416C46 11.2204 35.7175 0.942383 22.9993 0.942383ZM34.4996 32.1999L31.256 35.442L22.9993 27.1853L14.7425 35.442L11.5004 32.1999L19.7571 23.9416L11.5004 15.6849L14.7425 12.4413L22.9993 20.6995L31.256 12.4413L34.4996 15.6849L26.2444 23.9416L34.4996 32.1999Z"
      />
    </SvgIcon>
  );
};
