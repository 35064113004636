import ErrorFallback from "components/errors/ErrorFallback";
import React from "react";
import { useRouteError } from "react-router-dom";

interface RouteError extends Error {
  status?: number;
  data?: string | null;
}

interface ErrorBoundaryProps {
  error?: {
    statusCode: number;
    message: string;
  };
}

const NotFound: React.FC<ErrorBoundaryProps> = ({ error }) => {
  const routeError = useRouteError() as RouteError | null;
  const actualError =
    routeError ||
    (error
      ? { status: error.statusCode, message: error.message }
      : { status: 404, message: "Page Not Found" });

  return (
    <ErrorFallback
      error={
        {
          name: "notfound",
          message: actualError.message,
          status: actualError.status,
        } as Error
      }
      resetErrorBoundary={() => {}}
    />
  );
};

export default NotFound;
