import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/system";
import { Image } from "./styles";

import Card, { CardSize, CardProps } from "components/Card";
import Loading from "components/Loading";
export interface IllustrationProps {
  url: string;
  alt: string;
  timeout?: number;
}

interface IllustrationCardProps extends CardProps {
  illustrations: IllustrationProps[];
  size?: CardSize;
}

const IllustrationCard: React.FC<IllustrationCardProps> = ({
  illustrations,
  size,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(-1);
  const { t } = useTranslation();

  useEffect(() => {
    if (!illustrations.length) return;
    if (illustrations.length === 1) {
      setCurrentImage(0);
      return;
    }
    const _currentImage = currentImage === -1 ? 0 : currentImage;
    const timeout = (illustrations[_currentImage].timeout || 5) * 1000;
    const timeoutReference = setTimeout(() => {
      setCurrentImage((_currentImage) =>
        _currentImage === illustrations.length - 1 ? 0 : _currentImage + 1
      );
    }, timeout);
    return () => clearTimeout(timeoutReference);
  }, [currentImage, illustrations]);

  const consolidateLoadingStates = () => {
    if (currentImage === -1) return true;
    if (illustrations.length < currentImage) return true;
    if (!illustrations[currentImage]) return true;
    if (!illustrations[currentImage].url) return true;
    return false;
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.white.light,
        backgroundImage: `url(${process.env.REACT_APP_GCP_BUCKET_URL}/assets/images/illustrationCard-background.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundSize: "80%",
        padding: "0",
        ...sx,
      }}
      {...rest}
    >
      {consolidateLoadingStates() ? (
        <Loading height={"13rem"} />
      ) : (
        <Image
          src={`${process.env.REACT_APP_GCP_BUCKET_URL}${illustrations[currentImage].url}`}
          alt={t(illustrations[currentImage]?.alt)}
        />
      )}
    </Card>
  );
};

export default IllustrationCard;
