import { Grid, Slider, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const GaugeSliderStyle = styled(Slider)(({ theme }) => ({
  height: 3,
  "& .MuiSlider-thumb": {
    height: 42,
    width: 42,
    marginLeft: 21,
    backgroundColor: "#fff",
  },
  "& .MuiSlider-thumb::before": {
    boxShadow: "none",
  },
  "& .MuiSlider-track, & .MuiSlider-rail": {
    color: theme.palette.grey[400],
    backgroundColor: "transparent",
    border: "none",
  },
  "& .MuiSlider-rail": {
    height: 5,
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[400],
  },
}));

export const VisualSliderStyle = styled(Slider)({
  zIndex: 1,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  margin: "0 20px",
  pointerEvents: "none",
  "& .MuiSlider-thumb": {
    display: "none",
  },
  "& .MuiSlider-rail": {
    display: "none",
  },
});

export const LabelStyle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: 45,
  left: 0,
  fontSize: "1rem",
  fontWeight: 400,
  color: theme.palette.grey[600],
}));

export const GridStyle = styled(Grid)(({ theme }) => ({
  display: "block",
  width: "95%",
  height: "4rem",
  margin: "8px 0",
}));
