import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import CustomPopoverWrapper from "components/CustomPopoverWrapper";
import { getAssetsPath } from "utils/assets.utils";
import {
  StyledPopoverBox,
  StyledPopoverItem,
} from "features/trials/components.styles";

interface MenuItem {
  label: string;
  icon: string;
  action: () => void;
}

interface Props {
  anchorEl: HTMLElement | null;
  menuItems: MenuItem[];
  onClose?: () => void;
}

const Menu: React.FC<Props> = ({ anchorEl, menuItems, onClose = () => {} }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = (action: () => void) => {
    if (action) action();
    setIsOpen(true);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    if (anchorEl) {
      setIsOpen(true);
    }
  }, [anchorEl]);

  return (
    <>
      <CustomPopoverWrapper
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        applyZIndex={false}
      >
        <StyledPopoverBox>
          <Grid container spacing={2}>
            {menuItems.map((item, index) => (
              <Grid item xs={12} md={12} sm={12} key={index}>
                <StyledPopoverItem
                  onClick={() => {
                    handleClickOpen(item.action);
                  }}
                >
                  <img
                    style={{ marginRight: "1rem" }}
                    src={getAssetsPath(`icons/${item.icon}`)}
                    alt="Icon"
                  />{" "}
                  {t(item.label)}
                </StyledPopoverItem>
              </Grid>
            ))}
          </Grid>
        </StyledPopoverBox>
      </CustomPopoverWrapper>
    </>
  );
};

export default Menu;
