import axios, { AxiosRequestConfig } from "axios";
import { handleResponse } from "./privateHttp.utils";

export const baseURL = process.env.REACT_APP_API_ENDPOINT;
const REQUEST_TIMEOUT = 60000;

const publicClient = axios.create({
  baseURL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
  },
});

export const get = async (
  url: string,
  headers?: Record<string, string>
): Promise<any> => {
  try {
    const options: AxiosRequestConfig = {
      headers,
    };
    const response = await publicClient.get(url, options);
    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = await handleResponse(error.response);
    throw new Error(errorMessage);
  }
};
