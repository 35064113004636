import moment from "moment";
import { get } from "utils/publicHttp.utils";

const TIME_SERVICE_ROUTES = {
  TIME_SERVICE_UTC_ENDPOINT: process.env.REACT_APP_UTCAPI_ENDPOINT,
};

export const fetchUtcTime = async () => {
  try {
    const response = await get(
      TIME_SERVICE_ROUTES.TIME_SERVICE_UTC_ENDPOINT as string
    );
    const utcTime = response.dateTime;
    return moment.utc(utcTime);
  } catch (err) {
    console.error("Error fetching UTC time:", err);
    return moment();
  }
};

export const getAdjustedTime = (elapsedTime: number) => {
  const now = moment.utc();
  return elapsedTime >= 0
    ? now.clone().subtract(Math.abs(elapsedTime), "milliseconds")
    : now.clone().add(Math.abs(elapsedTime), "milliseconds");
};

export const getTimeDifference = async () => {
   const fetchedUtcTime = await fetchUtcTime();
   const fetchLocalTime = moment.utc();
   const differenceInMilliseconds = fetchLocalTime.diff(fetchedUtcTime);
   return differenceInMilliseconds;
};
