import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ITitleItem } from "components/HeadingTitle";
import { useTheme } from "@mui/system";

interface HeadingTitleProps {
  titleItem: ITitleItem;
}

export const TitleItem: React.FC<HeadingTitleProps> = ({ titleItem }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid container flexDirection={"column"} sx={{ color: theme.palette.grey[600] }}>
      <Grid item>
        {titleItem.label && (
          <Typography
            component="div"
            sx={{
              fontSize: "0.9rem",
            }}
          >
            {t(titleItem.label)}:
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Typography
          component="h2"
          sx={{
            fontSize: titleItem.label ? "1.1rem" : "2rem",
            fontWeight: "bold",
          }}
        >
          {t(titleItem.text)}
        </Typography>
      </Grid>
    </Grid>
  );
};
