import React from "react";
import { useDialogBoxStore } from "./useDialogBoxStore";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { StyledDialogBox, StyledIconButton } from "./DialogBox.styles";

export interface ButtonActions {
  [key: string]: (...args: any) => void;
}

interface DialogBoxProps {
  dialogKey: string;
  actions?: ButtonActions;
  sx?: any;
}

enum Reasons {
  BACKDROPCLICK = "backdropClick",
  ESCAPEKEYDOWN = "escapeKeyDown",
}

export enum ButtonTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

const DialogBox: React.FC<DialogBoxProps> = ({ dialogKey, actions, sx }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { dialogs, close } = useDialogBoxStore();
  const dialog = dialogs[dialogKey] || { isOpen: false };
  const [disabled, setDisabled] = React.useState(false);

  const handleButtonClick = async (button: {
    label: string;
    action: string | string[];
    type?: ButtonTypes | undefined;
  }) => {
    if (!actions) return;
    await executeAction(button.action);
    setDisabled(button.type === ButtonTypes.PRIMARY);
  };

  async function executeAction(action: string | string[]) {
    if (Array.isArray(action)) {
      for (const singleAction of action) {
        await executeSingleAction(singleAction);
      }
    } else {
      await executeSingleAction(action);
    }
  }

  async function executeSingleAction(singleAction: string) {
    if (actions && actions[singleAction]) {
      await actions[singleAction]();
    }
  }

  return dialog.isOpen ? (
    <StyledDialogBox
      key={dialogKey}
      sx={{
        ...sx,
        fontFamily: theme.typography.fontFamily,
      }}
      open={dialog.isOpen}
      fullWidth
      onClose={(e, reason: Reasons) => {
        if (![Reasons.BACKDROPCLICK, Reasons.ESCAPEKEYDOWN].includes(reason)) {
          close(dialogKey);
        }
      }}
    >
      {dialog.title ? (
        <DialogTitle>
          {t(dialog.title)}
          {dialog.showCloseButton && (
            <StyledIconButton
              aria-label="close"
              onClick={() => {
                close(dialogKey);
                setDisabled(false);
              }}
            >
              <CloseIcon />
            </StyledIconButton>
          )}
        </DialogTitle>
      ) : null}
      <DialogContent>
        {typeof dialog.description === "string"
          ? t(dialog.description)
          : dialog.description}
      </DialogContent>
      {dialog.buttons && (
        <DialogActions>
          {dialog.buttons.map((button, index) => (
            <Button
              disabled={disabled}
              key={index}
              onClick={() => handleButtonClick(button)}
            >
              {t(button.label)}
            </Button>
          ))}
        </DialogActions>
      )}
    </StyledDialogBox>
  ) : null;
};

export default DialogBox;
