import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const CloudIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 100 100" sx={sx}>
      <g clipPath="url(#clip0_1424_15978)">
        <path d="M93.4996 38.7142C92.939 40.4089 92.632 42.2484 91.7601 43.7664C90.0654 46.7193 87.3068 48.2501 83.8896 48.512C82.5082 48.6184 81.8346 48.1585 81.7749 47.1707C81.7152 46.1892 82.3163 45.6804 83.7063 45.5441C86.7057 45.2503 88.8076 43.7196 89.9204 40.9327C91.0076 38.2097 90.1933 34.914 88.0615 33.0617C85.6867 30.9987 82.4826 30.6112 79.7284 32.0568C78.9311 32.4762 78.1594 33.1958 77.2022 32.4507C76.3175 31.763 76.665 30.8475 76.8569 29.9959C78.7115 21.7503 73.7082 13.3321 65.7354 10.4218C56.8245 7.16865 46.4406 12.1186 43.4775 21.1265C43.324 21.5927 43.147 22.076 42.872 22.4763C42.3519 23.2342 41.4651 23.2193 40.4397 22.4528C37.6279 20.3558 34.5624 19.7426 31.2048 20.8582C26.3444 22.4741 23.7052 27.8478 25.351 32.704C25.4938 33.1256 25.6707 33.66 25.5258 34.0219C25.3552 34.4498 24.8457 35.046 24.4961 35.0353C23.9141 35.0204 23.1467 34.7351 22.8035 34.2944C21.4391 32.5486 19.7508 31.4501 17.538 31.2372C13.5729 30.8561 10.0618 33.6791 9.69092 37.5156C9.28801 41.6821 12.0295 45.1013 16.1204 45.5377C16.4018 45.5675 16.6768 45.6335 16.956 45.6889C17.7874 45.8507 18.1967 46.3851 18.1285 47.1984C18.0539 48.0734 17.4847 48.5269 16.6213 48.529C14.1016 48.5354 11.8781 47.6859 10 46.0295C6.20759 42.687 5.40391 37.1643 8.06437 32.9936C10.7952 28.7121 16.0415 27.0834 20.7676 29.0528C21.0917 29.1869 21.4136 29.3317 21.8378 29.5148C22.2322 24.799 24.2936 21.1179 28.4548 18.8804C32.5841 16.6598 36.7709 16.8663 40.8426 19.0847C46.3788 8.79309 54.6672 4.303 66.2299 7.53059C73.9534 9.68728 81.0181 17.7669 80.1419 28.5759C80.9776 28.4737 81.7983 28.3183 82.6255 28.28C88.0636 28.0223 92.5126 31.6885 93.3057 37.0472C93.3568 37.3921 93.4336 37.7328 93.4996 38.0755V38.7142Z" />
        <path d="M49.8736 76.1132C41.9541 76.1132 34.0366 76.1132 26.1171 76.1132C22.5293 76.1132 20.8516 74.4249 20.8494 70.8184C20.8494 69.294 20.8196 67.7696 20.8558 66.2474C20.9241 63.3604 22.6295 61.653 25.5138 61.5359C25.6907 61.5295 25.8698 61.5337 26.0467 61.5337C41.9903 61.5337 57.9339 61.5401 73.8796 61.5273C75.9112 61.5273 77.6187 62.0873 78.5396 64.0332C78.8317 64.6506 78.9703 65.3872 78.9958 66.0749C79.0598 67.8079 79.0257 69.5473 79.015 71.2825C78.998 74.2972 77.2073 76.0983 74.1631 76.109C68.3753 76.126 62.5875 76.1153 56.7998 76.1153C54.491 76.1153 52.1845 76.1153 49.8757 76.1153L49.8736 76.1132ZM49.9674 73.1347C57.8166 73.1347 65.6637 73.1347 73.5129 73.1347C73.8689 73.1347 74.2249 73.1496 74.5767 73.1156C75.4784 73.0283 76.0263 72.5684 76.0284 71.6167C76.0369 69.739 76.0348 67.8612 76.0284 65.9834C76.0241 65.136 75.5487 64.674 74.7429 64.5548C74.3251 64.4931 73.8945 64.5016 73.4681 64.5016C58.9081 64.4994 44.348 64.5016 29.788 64.5016C28.4386 64.5016 27.0892 64.4845 25.7397 64.508C24.3051 64.5314 23.8425 65.0062 23.8254 66.4518C23.8105 67.7973 23.8211 69.145 23.8211 70.4905C23.8211 72.8899 24.062 73.1369 26.4198 73.1369C34.269 73.1369 42.1161 73.1369 49.9653 73.1369L49.9674 73.1347Z" />
        <path d="M49.9013 44.172C57.8912 44.172 65.8811 44.2529 73.871 44.1336C77.2776 44.0825 79.2623 46.4159 79.0385 49.3114C78.9234 50.7911 79.0236 52.2856 79.0172 53.7738C79.0065 56.9354 77.2457 58.7003 74.0672 58.7003C58.6907 58.7067 43.3163 58.7025 27.9398 58.7025C27.1233 58.7025 26.3068 58.7131 25.4903 58.6982C22.9386 58.6535 21.1778 57.2313 20.9539 54.7063C20.7642 52.5645 20.7621 50.3802 20.9369 48.2362C21.1479 45.6516 22.881 44.1975 25.5074 44.1826C30.3359 44.1528 35.1665 44.172 39.995 44.172C43.2971 44.172 46.5992 44.172 49.9013 44.172ZM49.8502 55.7431C57.8422 55.7431 65.8342 55.7431 73.8263 55.7431C75.6575 55.7431 76.0305 55.3791 76.0348 53.5992C76.0369 52.2175 76.0348 50.8336 76.0348 49.4519C76.0348 47.3974 75.7577 47.1185 73.6962 47.1185C63.679 47.1185 53.6618 47.1185 43.6446 47.1185C37.7118 47.1185 31.7812 47.1143 25.8485 47.1228C24.2518 47.1249 23.8339 47.555 23.8233 49.1538C23.8126 50.642 23.8148 52.1302 23.8233 53.6205C23.8318 55.328 24.256 55.7431 25.9785 55.7431C33.9343 55.7431 41.8922 55.7431 49.848 55.7431H49.8502Z" />
        <path d="M49.8826 93.4932C41.9631 93.4932 34.0456 93.4953 26.1261 93.4932C22.5298 93.4932 20.8414 91.8134 20.8563 88.2175C20.8648 86.344 20.8094 84.4534 21.0141 82.5969C21.2464 80.4998 22.8581 79.1458 24.9749 78.9606C25.2926 78.9329 25.6123 78.9286 25.9321 78.9286C41.9481 78.9286 57.9621 78.935 73.9781 78.918C75.9926 78.9158 77.6298 79.5354 78.5316 81.4153C78.8407 82.0604 78.9686 82.8375 78.9942 83.5614C79.0581 85.2944 79.0283 87.0338 79.0134 88.7689C78.9899 91.6537 77.1992 93.4634 74.2766 93.4826C69.4118 93.5124 64.5471 93.4911 59.6824 93.4911C56.4144 93.4911 53.1485 93.4911 49.8805 93.4911L49.8826 93.4932ZM49.9551 90.5445C54.3615 90.5445 58.7657 90.5445 63.1721 90.5445C66.7599 90.5445 70.3498 90.5509 73.9376 90.5403C75.6217 90.536 76.0353 90.1208 76.0395 88.4815C76.0438 86.9209 76.0481 85.3625 76.0395 83.8019C76.031 82.3521 75.5727 81.8816 74.1401 81.8816C58.0111 81.8773 41.8799 81.8773 25.7509 81.8816C24.346 81.8816 23.8493 82.384 23.8323 83.8168C23.8152 85.271 23.8259 86.7229 23.828 88.1771C23.8302 90.1741 24.1947 90.5403 26.1879 90.5424C34.1117 90.5424 42.0334 90.5424 49.9572 90.5424L49.9551 90.5445Z" />
        <path d="M39.7966 30.666C39.7966 33.4636 39.7987 36.227 39.7945 38.9884C39.7945 39.378 39.8968 39.8591 39.7092 40.1338C39.4022 40.5809 38.9503 41.0641 38.4579 41.2217C37.7842 41.4367 37.2086 41.0194 36.9955 40.3169C36.8739 39.9187 36.842 39.4823 36.8398 39.0629C36.827 36.2675 36.8334 33.4742 36.8334 30.385C36.1662 31.0471 35.6951 31.5453 35.1898 32.0094C34.4053 32.7312 33.5377 32.7567 32.928 32.1095C32.3546 31.4985 32.3908 30.6469 33.0943 29.9294C34.4352 28.5626 35.791 27.2085 37.1724 25.8821C37.9697 25.1178 38.7094 25.1263 39.5131 25.9013C40.8668 27.2043 42.2013 28.5285 43.5144 29.874C44.2606 30.6384 44.3032 31.5389 43.702 32.1478C43.0881 32.7695 42.2204 32.712 41.4317 31.9839C40.9648 31.5538 40.515 31.1067 40.0524 30.6703C40.0354 30.6533 39.9863 30.6682 39.7987 30.6682L39.7966 30.666Z" />
        <path d="M63.4618 36.2513C63.9222 35.6659 64.3294 35.0272 64.8559 34.5098C65.5019 33.8754 66.28 33.8264 66.9259 34.5183C67.5569 35.1953 67.4844 35.9639 66.8598 36.6069C65.4784 38.0291 64.0715 39.4278 62.6474 40.8096C61.993 41.4461 61.2127 41.4972 60.5519 40.8649C59.066 39.4491 57.6122 37.9929 56.1881 36.5153C55.5934 35.8979 55.5678 35.1634 56.1881 34.5204C56.8511 33.8349 57.5845 33.9009 58.2688 34.4949C58.8209 34.9718 59.3325 35.4955 60.0701 36.196C60.0701 34.612 60.0701 33.2877 60.0701 31.9635C60.0701 30.4051 60.0594 28.8488 60.0744 27.2903C60.085 26.0491 60.6286 25.3487 61.5474 25.338C62.4769 25.3274 63.0162 26.015 63.0184 27.2626C63.0248 30.156 63.0205 33.0493 63.0205 35.9426C63.1676 36.0448 63.3147 36.1449 63.4618 36.2471V36.2513Z" />
        <path d="M41.7047 70.3062C37.4795 70.3062 33.2565 70.3062 29.0313 70.3062C28.7115 70.3062 28.3917 70.319 28.0741 70.2935C27.0444 70.2104 26.4262 69.6675 26.4049 68.8521C26.3815 67.9899 27.0253 67.3874 28.1061 67.349C29.2061 67.3086 30.3061 67.3341 31.4061 67.3341C39.1444 67.3341 46.8828 67.3341 54.6211 67.3341C56.1624 67.3341 56.9064 67.8046 56.9384 68.7776C56.9704 69.7825 56.1795 70.302 54.5892 70.3041C50.2936 70.3084 45.9981 70.3041 41.7026 70.3041L41.7047 70.3062Z" />
        <path d="M73.4427 68.7781C73.4598 70.3323 72.1914 71.6438 70.6586 71.6566C69.1302 71.6693 67.7935 70.3728 67.7679 68.8548C67.7424 67.3432 69.0513 66.0062 70.5798 65.9827C72.1189 65.9593 73.4278 67.2367 73.4449 68.7803L73.4427 68.7781Z" />
        <path d="M62.498 70.2568C61.8244 70.2568 61.1465 70.31 60.4792 70.2461C59.6201 70.1631 59.1277 69.6692 59.1554 68.7644C59.181 67.9149 59.6756 67.4273 60.4814 67.3911C61.8265 67.3337 63.1759 67.3379 64.5211 67.3911C65.3397 67.4231 65.8321 67.9489 65.8556 68.7686C65.879 69.6415 65.3887 70.1716 64.5168 70.2461C63.8496 70.3015 63.1717 70.2568 62.498 70.2589V70.2568Z" />
        <path d="M41.3525 52.9233C37.0228 52.9233 32.6932 52.9275 28.3635 52.9169C27.9415 52.9169 27.3893 52.9893 27.1292 52.7636C26.6986 52.3889 26.1785 51.7757 26.2168 51.3074C26.2574 50.8432 26.9075 50.3897 27.3616 50.0214C27.5364 49.8788 27.9116 49.9703 28.1951 49.9703C36.9973 49.9703 45.7994 49.9703 54.6015 49.9703C54.8488 49.9703 55.1749 49.8873 55.3305 50.0108C55.791 50.3748 56.3815 50.7559 56.5585 51.2563C56.8079 51.9652 56.3261 52.5635 55.6013 52.7955C55.209 52.9211 54.7635 52.9169 54.3414 52.9169C50.0118 52.9254 45.6821 52.9211 41.3525 52.9211V52.9233Z" />
        <path d="M70.1615 48.6974C71.7305 48.6804 73.016 49.9301 73.0352 51.4886C73.0543 53.047 71.7966 54.3265 70.2276 54.3436C68.714 54.3606 67.3582 53.047 67.3305 51.5418C67.3028 50.0132 68.5947 48.7145 70.1615 48.6974Z" />
        <path d="M61.6009 49.9817C62.2362 49.9817 62.8736 49.9497 63.5067 49.9902C64.3594 50.0455 64.8966 50.499 64.9286 51.3847C64.9585 52.2746 64.4298 52.8239 63.6027 52.8771C62.266 52.9644 60.9188 52.9516 59.58 52.8856C58.7315 52.8452 58.237 52.2767 58.1879 51.4273C58.141 50.6353 58.787 50.0306 59.6951 49.9838C60.3282 49.9497 60.9657 49.9774 61.6009 49.9774V49.9817Z" />
        <path d="M41.6978 87.6543C37.2253 87.6543 32.7507 87.6458 28.2782 87.6628C27.3616 87.6671 26.6261 87.4286 26.4428 86.4472C26.2936 85.6424 26.6965 85.0633 27.4831 84.8355C27.8498 84.729 28.2548 84.7269 28.6428 84.7269C37.3404 84.7205 46.0381 84.7227 54.7379 84.7248C55.0214 84.7248 55.3092 84.7248 55.5863 84.7695C56.4433 84.9058 56.9698 85.4316 56.9166 86.2832C56.8633 87.1157 56.3346 87.6373 55.4392 87.6479C54.4096 87.6607 53.3799 87.6564 52.3503 87.6564C48.8009 87.6564 45.2493 87.6564 41.6999 87.6564L41.6978 87.6543Z" />
        <path d="M70.6628 83.3945C72.2467 83.4158 73.4789 84.6954 73.4426 86.2772C73.4064 87.8037 72.1487 89.0343 70.6287 89.0322C69.0832 89.0322 67.7529 87.7079 67.7657 86.1878C67.7785 84.6613 69.113 83.3754 70.6628 83.3967V83.3945Z" />
        <path d="M62.4397 87.6495C61.8364 87.6495 61.2331 87.6623 60.6298 87.6474C59.7601 87.624 59.2143 87.2067 59.1568 86.3082C59.1013 85.4183 59.5703 84.835 60.4316 84.786C61.8087 84.7094 63.1986 84.7094 64.5757 84.786C65.4498 84.835 65.9038 85.4566 65.8441 86.3189C65.7887 87.1279 65.3048 87.6155 64.4606 87.641C63.787 87.6623 63.1133 87.6453 62.4376 87.6453V87.6495H62.4397Z" />
      </g>
    </SvgIcon>
  );
};
