import { styled } from "@mui/system";
import { Stepper, Step } from "@mui/material";

export const PendingStep = styled(Step)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    color: theme.palette.pending.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-active .Mui-completed": {
    color: theme.palette.pending.dark,
  },
  "& .MuiStepLabel-iconContainer .Mui-disabled": {
    color: theme.palette.pending.light,
  },
  "& .MuiStepLabel-iconContainer.MuiSvgIcon-root": {
    color: theme.palette.pending.dark,
  },
  "& .MuiStepConnector-line": {
    backgroundColor: theme.palette.pending.main,
  },
  "& .MuiStepIcon-text": {
    fill: theme.palette.white.light,
  },
}));

export const InProgressStep = styled(Step)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    color: theme.palette.progress.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-active": {
    color: theme.palette.progress.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-disabled": {
    color: theme.palette.progress.light,
  },
  "& .MuiStepLabel-iconContainer.MuiSvgIcon-root": {
    color: theme.palette.pending.dark,
  },
  "& .MuiStepConnector-line": {
    backgroundColor: theme.palette.progress.main,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.progress.main,
    borderColor: theme.palette.progress.light,
  },
  "& .MuiStepIcon-text": {
    fill: theme.palette.white.light,
  },
}));

export const SuccessStep = styled(Step)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    color: theme.palette.success.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-active .Mui-completed": {
    color: theme.palette.success.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-disabled": {
    color: theme.palette.success.light,
  },
  "& .MuiStepLabel-iconContainer.MuiSvgIcon-root": {
    color: theme.palette.pending.dark,
  },
  "& .MuiStepConnector-line": {
    backgroundColor: theme.palette.success.main,
  },
  "& .MuiSvgIcon-root.Mui-completed": {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.light,
  },
  "& .MuiStepIcon-text": {
    fill: theme.palette.white.light,
  },
}));

export const WarningStep = styled(Step)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    color: theme.palette.warning.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-active .Mui-completed": {
    color: theme.palette.warning.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-disabled": {
    color: theme.palette.warning.light,
  },
  "& .MuiStepLabel-iconContainer.MuiSvgIcon-root": {
    color: theme.palette.pending.dark,
  },
  "& .MuiStepConnector-line": {
    backgroundColor: theme.palette.warning.main,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.light,
  },
  "& .MuiStepIcon-text": {
    fill: theme.palette.white.light,
  },
}));

export const FailStep = styled(Step)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    color: theme.palette.error.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-active .Mui-completed": {
    color: theme.palette.error.main,
  },
  "& .MuiStepLabel-iconContainer .Mui-disabled": {
    color: theme.palette.error.light,
  },
  "& .MuiStepLabel-iconContainer.MuiSvgIcon-root": {
    color: theme.palette.pending.dark,
  },
  "& .MuiStepConnector-line": {
    backgroundColor: theme.palette.error.main,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
  },
  "& .MuiStepIcon-text": {
    fill: theme.palette.white.light,
  },
}));

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    height: 4,
    margin: -2,
    border: "20px",
    borderRadius: 5,
  },
  "& .MuiStepLabel-iconContainer .Mui-active": {
    margin: -8,
    borderStyle: "solid",
    borderWidth: 8,
    borderRadius: 100,
  },
}));
