import { Table, TableHead, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";

interface StyledTypographyProps {
  component?: React.ElementType;
  children: React.ReactNode;
}

export const StyledTypography = (component: React.ElementType) =>
  styled(Typography)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "red",
    fontSize: "2rem",
  }));

export const StyledAnalyticsHeading = (component: React.ElementType) =>
  styled(Typography)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "500",
    marginTop: "2rem",
  }));

export const StyledDataCardBox = (component: React.ElementType) =>
  styled(Box)(({ theme }) => ({
    display: "flex",
    padding: "2.5rem 0rem 3.125rem 0rem",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.5rem",
    borderRadius: "1rem",
    border: "1px solid #DFE3E8",
    background: "#F9FAFB",
  }));

export const StyledDataCardResult = styled(Typography)<StyledTypographyProps>(
  ({ theme, component = "div" }) => ({
    display: "flex",
    justifyContent: "center",
    color: "#04E098",
    textAlign: "center",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "2.75rem",
    marginTop: "1.5rem",
  })
);

export const StyledDataCardResultType = styled(
  Typography
)<StyledTypographyProps>(({ theme, component = "div" }) => ({
  display: "flex",
  justifyContent: "center",
  color: "#04E098",
  textAlign: "center",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "1.25rem",
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  boxShadow: "none",
  border: "1px solid #DFE3E8",
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  height: "3rem",
  background: "#F9FAFB",
}));

export const StyledLoader = (component: React.ElementType) =>
  styled(Typography)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    margin: "0.5rem;",
  }));

export const StyledMessage = (component: React.ElementType) =>
  styled(Typography)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "2rem",
    maxWidth: "40rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
  }));

export const StyledBox = (component: React.ElementType) =>
  styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: "5rem",
    minHeight: "80vh",
    alignItems: "center",
    flexDirection: "column",
  }));

export const StyledButtonContainer = (component: React.ElementType) =>
  styled(Typography)(({ theme }) => ({
    margin: "2rem",
    padding: "1rem",
    borderRadius: "0.8rem",
  }));
