import React from "react";
import { Grid, Divider } from "@mui/material";

import { useHeaderStore } from "stores/header.store";
import ProgressBar from "components/ProgressBar";
import Breadcrumb from "components/Breadcrumb";
import HeadingTitle from "components/HeadingTitle";
import { ProgressBarStyle } from "constants/index";
import { useInformationBlockStore } from "components/InformationBlock/informationblock.store";

const Header: React.FC = () => {
  const mainTitle = useHeaderStore((state) => state.mainTitle);
  const subTitleItems = useHeaderStore((state) => state.subTitleItems);
  const { progressBarType } = useHeaderStore((state) => state);
  const show = useInformationBlockStore((state) => state.show);
  return (
    <Grid
      container
      flexDirection="row"
      alignItems={
        progressBarType === ProgressBarStyle.BREADCRUMB ? "start" : "center"
      }
      justifyContent="flex-end"
      sx={{ marginTop: "2rem" }}
    >
      <Grid item flexGrow={1}>
        <HeadingTitle mainTitle={mainTitle} subTitleItems={subTitleItems} />
      </Grid>
      <Grid item>
        {progressBarType === ProgressBarStyle.BREADCRUMB ? (
          <Breadcrumb />
        ) : (
          <ProgressBar />
        )}
      </Grid>
      {!show && (
        <Divider
          sx={{ width: "100%", marginTop: "1.5rem", marginBottom: "2rem" }}
        />
      )}
    </Grid>
  );
};

export default Header;
