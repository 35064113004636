import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const BoxContainer = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  width: "fit-content",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.625rem",
  borderRadius: "2.75rem",
  cursor: "pointer",
}));

export const BoxCount = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "1.5rem",
  padding: "0 0.5rem",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "1.25rem",
  background: "white",
  color: theme.palette.text.primary,
}));

export const BoxBackground = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  borderRadius: "inherit",
}));

export const BoxText = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
}));
