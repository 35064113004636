import React from "react";
import { Tooltip as MuiTooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CustomTooltipProps {
  title: string;
  children: React.ReactElement;
  placement?: "top" | "bottom" | "left" | "right";
}

const Tooltip: React.FC<CustomTooltipProps> = ({
  title,
  children,
  placement = "top",
}) => {
  const { t } = useTranslation();

  return (
    <MuiTooltip
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "100%",
          },
        },
      }}
      title={<Typography>{t(title)}</Typography>}
      arrow
      placement={placement}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
