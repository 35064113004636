import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import { useState } from "react";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import {
  updateTrialStatus as _updateTrialStatus,
  UpdateTrialStatus,
  fetchParticipantsData,
  deleteParticipant as _deleteParticipant,
  getAnalyticsById,
} from "services/cde.service";
import useTrialApi from "hooks/useTrialApi.hook";
import { CustomError } from "utils/privateHttp.utils";

interface Options {
  trialId: string;
  participants?: boolean;
  annotations?: boolean;
}

function useTrialCardApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const successNotification = useSnackbarStore((state) => state.openSuccess);
  const queryClient = useQueryClient();
  const trialQuery = useTrialApi({
    trialId: options.trialId,
    participants: options.participants ?? false,
    annotations: options.annotations ?? true,
  });

  const analytics = useQuery({
    queryKey: [QueryKeys.TRIAL_ANALYTICS, options.trialId],
    queryFn: async () => {
      const result = await getAnalyticsById(options.trialId, true);
      return result.trial;
    },
    enabled: options.annotations ?? true,
  });

  const refreshAnalytics = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TRIAL_ANALYTICS, options.trialId],
    });
  };

  const participantsQuery = useQuery({
    queryKey: [QueryKeys.TRIAL_PARTICIPANTS, options.trialId],
    queryFn: async () => await fetchParticipantsData(options.trialId),
    enabled: options.participants ?? false,
  });

  const refreshParticipantsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TRIAL_PARTICIPANTS, options.trialId],
    });
  };

  const updateTrialStatus = useMutation({
    mutationFn: async (data: UpdateTrialStatus) =>
      await _updateTrialStatus(data),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [QueryKeys.TRIALS],
      });
      successNotification("trial.updateStatus.success");
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  const deleteParticipant = useMutation({
    mutationFn: async (participantId: string) =>
      await _deleteParticipant(options.trialId, participantId),
    onSuccess: () => {
      trialQuery.refreshTrial();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL_PARTICIPANTS],
      });
      successNotification("trial.participant.delete.success");
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  return {
    trialQuery: trialQuery.trial,
    refreshTrialQuery: trialQuery.refreshTrial,
    analytics,
    refreshAnalytics,
    participantsQuery,
    refreshParticipantsQuery,
    updateTrialStatus,
    deleteParticipant,
    setOptions,
  };
}

export default useTrialCardApi;
