import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  border: `0.1rem solid ${theme.palette.grey[200]}`,
  borderRadius: "1.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "25%",
  minHeight: "20rem",
}));

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  width: "100%",
  borderRadius: "0.75rem",
  border: `1px solid ${theme.palette.grey[400]}`,
  background: theme.palette.grey[100],
  marginTop: "0.75rem",
  minHeight: "4rem",
  fontFamily: "inherit",
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.white.light,
  width: "100%",
  height: "3rem",
  borderRadius: "0.75rem 0.75rem 0rem 0rem",
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  borderRight: `1px solid ${theme.palette.text.secondary}`,
  borderLeft: `1px solid ${theme.palette.text.secondary}`,
  background: theme.palette.pending.main,
  padding: "0.5rem",
}));

export const StyledGridStatsContainer = styled(Grid)(({ theme }) => ({
  borderRadius: "0.5rem",
  backgroundColor: theme.palette.grey[100],
  fontFamily: "inherit",
}));

export const StyledGridStatsItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    borderRight: `1px solid ${theme.palette.grey[600]}`,
  },
}));

export const annotationTotalFontStyle = (theme: Theme) => ({
  color: theme.palette.text.primary,
  size: 30,
  weight: "bold",
});

export const annotationCountFontStyle = (theme: Theme) => ({
  color: theme.palette.grey[500],
  size: 20,
});

export const graphHoverFontStyle = (theme: Theme) => ({
  family: theme.typography.fontFamily,
  color: theme.palette.common.white,
});
