import React from "react";
import { Grid } from "@mui/material";
import { TitleItem } from "./TitleItem.component";

export interface ITitleItem {
  label?: string;
  text: string;
  link?: string;
}

interface HeadingTitleProps {
  mainTitle: ITitleItem;
  subTitleItems?: ITitleItem[];
}

export const HeadingTitle: React.FC<HeadingTitleProps> = ({
  mainTitle,
  subTitleItems,
}) => {
  return (
    <Grid container flexDirection={"column"}>
      <Grid item>
        <TitleItem titleItem={mainTitle} />
      </Grid>
      {subTitleItems?.length ? (
        <Grid item sx={{ marginTop: "1rem" }}>
          <Grid container flexDirection={"row"} spacing={2}>
            {subTitleItems.map((subTitleItem) => (
              <Grid item key={subTitleItem.label}>
                <TitleItem titleItem={subTitleItem} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
