import { styled } from "@mui/system";
import { Dialog, IconButton } from "@mui/material";

export const StyledDialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    marginBottom: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    marginBottom: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    marginBottom: theme.spacing(2),
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}));
