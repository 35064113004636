import { CardStatus } from "components/Card";
import { useEffect } from "react";
import { useTheme } from "@mui/system";
import { Grid } from "@mui/material";
import { Timer } from "./Timer.component";
import { useResultStore } from "../result.store";

interface Actions {
  [key: string]: (...args: any) => void;
}

interface ExperimentProps {
  actions: Actions;
}

const TimerButtonContainer: React.FC<ExperimentProps> = ({ actions }) => {
  const theme = useTheme();
  const currentEvent = useResultStore((state) => state.currentEvent);
  const events = useResultStore((state) => state.events);

  const showTimer = () => {
    const event = events.find((e) => e.name === currentEvent?.name);
    if (!event) return false;
    if (
      event.status === CardStatus.IN_PROGRESS ||
      event.status === CardStatus.PENDING
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (!currentEvent) return;
    if (!currentEvent.status) return;
  }, [currentEvent]);

  const getTimer = (status: CardStatus) => {
    if (!currentEvent) return [];
    if (!currentEvent.timer) return [];
    const timerConfig = currentEvent.timer.actions.reduce(
      (acc: any[], action: any) => {
        if (action.statuses.includes(status)) {
          acc.push({
            action: async () => {
              for (const subAction of action.actions) {
                if (typeof subAction === "object") {
                  const { name, params } = subAction;
                  await actions[name](status, params);
                } else {
                  await actions[subAction](status);
                }
              }
            },
            isVisible: currentEvent.timer.isVisible ?? true,
            time: action.time,
            direction: currentEvent.timer.direction,
          });
        }
        return acc;
      },
      []
    );
    return timerConfig;
  };

  const timerStatus = getTimer(currentEvent?.status as CardStatus);

  return (
    <Grid container spacing={2} direction={"column"} alignItems={"end"}>
      <Grid item sx={{ color: theme.palette.text.secondary }}>
        {showTimer() &&
          timerStatus?.map((data: any, i: number) => (
            <Timer
              key={`timer-${i}`}
              direction={data.direction}
              sx={{ display: data.isVisible ? "block" : "none" }}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default TimerButtonContainer;
