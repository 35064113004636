import { Logo, MenuItem } from "services/configuration.service";
import { create } from "zustand";
import { Permissions } from "services/cde.service";
import { LeftNavigationItemsOrientation, PermissionTypes } from "constants/index";

export interface NavigationInitOptions {
  navigationStates: { [key: string]: MenuItem[] };
  logo: Logo;
  dashboardPermissions: PermissionTypes[];
}

interface NavigationState {
  open: boolean;
  setOpen: (open: boolean) => void;
  logo: Logo;
  allowLogoAction: boolean;
  setAllowLogoAction: (allowLogoAction: boolean) => void;
  dashboardCdes: Permissions[];
  setDashboardCdes: (dashboardCdes: Permissions[]) => void;
  dashboardPermissions: PermissionTypes[];
  navigationStates: { [key: string]: MenuItem[] };
  getNavigationStatesTop: { [key: string]: MenuItem[] };
  getNavigationStatesBottom: { [key: string]: MenuItem[] };
  addNavigationState: (name: string, state: MenuItem[]) => void;
  switchNavigationState: (name: string) => void;
  activeNavigationState: string;
  allowAction: boolean;
  setAllowAction: (allowAction: boolean) => void;
  initialize: (options: NavigationInitOptions) => void;
}

const setDefaultOrientation = (menuItem: MenuItem): MenuItem => ({
  ...menuItem,
  orientation: menuItem.orientation || LeftNavigationItemsOrientation.TOP,
  show: true,
});

const updateMenuItemVisibility = (
  menuItems: MenuItem[],
  userPermissions: Permissions[]
): MenuItem[] => {
  return menuItems.map((item) => ({
    ...item,
    show: item.permissions
      ? item.permissions.some((permission: string) =>
          userPermissions.some(
            (userPermission: Permissions) =>
              userPermission.permission === permission
          )
        )
      : true,
  }));
};

const filterNavigationItems = (
  items: MenuItem[],
  orientation: LeftNavigationItemsOrientation
) => items.filter((item) => item.orientation === orientation);

export const useLeftNavigationStore = create<NavigationState>()((set) => ({
  open: false,
  logo: {
    small: "",
    large: "",
  },
  allowLogoAction: true,
  navigationStates: { default: [] },
  activeNavigationState: "default",
  allowAction: true,
  setAllowAction: (allowAction) => set({ allowAction }),
  dashboardCdes: [],
  dashboardPermissions: [],
  setDashboardCdes: (dashboardCdes) =>
    set((state) => {
      const updatedNavigationStates = Object.fromEntries(
        Object.entries(state.navigationStates).map(([key, menuItems]) => [
          key,
          updateMenuItemVisibility(menuItems, dashboardCdes),
        ])
      );
      const activeState =
        updatedNavigationStates[state.activeNavigationState] || [];
      return {
        dashboardCdes,
        navigationStates: updatedNavigationStates,
        getNavigationStatesBottom: {
          [state.activeNavigationState]: filterNavigationItems(
            activeState,
            LeftNavigationItemsOrientation.BOTTOM
          ),
        },
        getNavigationStatesTop: {
          [state.activeNavigationState]: filterNavigationItems(
            activeState,
            LeftNavigationItemsOrientation.TOP
          ),
        },
      };
    }),
  addNavigationState: (name, state) =>
    set((prev) => {
      const newState = state.map(setDefaultOrientation);
      return {
        navigationStates: { ...prev.navigationStates, [name]: newState },
        getNavigationStatesBottom: {
          ...prev.getNavigationStatesBottom,
          [name]: prev.getNavigationStatesBottom["default"],
        },
        getNavigationStatesTop: {
          ...prev.getNavigationStatesTop,
          [name]: filterNavigationItems(
            newState,
            LeftNavigationItemsOrientation.TOP
          ),
        },
      };
    }),
  getNavigationStatesTop: { default: [] },
  getNavigationStatesBottom: { default: [] },
  switchNavigationState: (name) =>
    set(() => ({
      activeNavigationState: name,
    })),
  setOpen: (open) => set({ open }),
  setAllowLogoAction: (allowLogoAction) => set({ allowLogoAction }),
  initialize: (options) =>
    set((state) => {
      return { ...state, ...options };
    }),
}));
