import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { buildLocalUrl } from "utils/url.utils";
import { ROUTES } from "constants/routes.constants";
import { useTranslation } from "react-i18next";

interface TabsProps {
  tabValue: number;
  trialId: string | undefined;
}

const StudyTabs: React.FC<TabsProps> = ({ tabValue, trialId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tabLabels = [
    t("studiesDashboard.study.overview.tab.label"),
    t("studiesDashboard.study.participants.tab.label"),
    t("studiesDashboard.study.devices.tab.label"),
  ];
  const tabPaths = [
    ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW,
    ROUTES.STUDIES_DASHBOARD_STUDY_PARTICIPANTS,
    ROUTES.STUDIES_DASHBOARD_STUDY_DEVICES,
  ];

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => {
          const newPath = buildLocalUrl({
            path: tabPaths[newValue],
            params: { trialId },
          });
          navigate(newPath);
        }}
      >
        {tabLabels.map((label, index) => (
          <Tab
            key={index}
            label={label}
            id={`study-tab-${index}`}
            aria-controls={`study-tabpanel-${index}`}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default StudyTabs;
