import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const RecoveryIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 126 90" sx={sx}>
      <g clipPath="url(#clip0_3003_29777)">
        <path d="M58.1942 51.2806C76.4082 51.2806 94.6223 51.2806 112.836 51.2806C114.592 51.2806 115.652 52.211 115.601 53.6868C115.562 54.7973 114.621 55.7687 113.468 55.8784C113.243 55.8997 113.014 55.8866 112.787 55.8866C76.3297 55.8866 39.8725 55.8866 3.41533 55.8866C1.61715 55.8866 0.529363 54.9317 0.609624 53.4329C0.669392 52.3076 1.61203 51.3756 2.7852 51.2921C3.096 51.2691 3.41021 51.2789 3.72272 51.2789C21.8804 51.2789 40.0381 51.2789 58.1959 51.2789L58.1942 51.2806Z" />
        <path d="M16.0417 9.8494C16.11 7.9002 15.111 6.01488 13.7961 4.21801C12.6879 2.70451 13.5144 0.835574 15.3843 0.54565C16.3491 0.396594 17.1039 0.786434 17.6726 1.50879C21.8239 6.77982 21.9605 12.9173 18.0568 18.3473C15.374 22.077 15.374 25.7248 18.0568 29.5019C21.4516 34.2832 21.1784 40.9973 17.4062 45.4494C16.4464 46.5812 14.9659 46.804 13.8747 45.9801C12.8262 45.1873 12.7083 43.777 13.5946 42.6238C16.2603 39.1579 16.3081 35.302 13.803 31.687C10.8572 27.4348 10.4406 22.9303 12.5769 18.2572C13.0618 17.1958 13.7825 16.2277 14.4433 15.2466C15.5209 13.6446 16.0417 12.0558 16.04 9.8494H16.0417Z" />
        <path d="M87.5159 24.4865C87.5313 25.9295 86.6808 28.5503 84.9458 30.8746C83.3475 33.0155 82.4458 35.3086 82.8095 37.9818C83.0008 39.3889 83.5319 40.6599 84.3328 41.8376C84.5958 42.2258 84.8929 42.5944 85.1405 42.9891C85.7894 44.0194 85.494 45.3642 84.4882 46.0473C83.4346 46.7614 82.0189 46.5714 81.2009 45.6066C77.0478 40.7107 76.737 34.0245 80.6135 28.8468C83.2826 25.2809 83.3697 21.0516 80.8577 17.6692C80.0209 16.5439 80.3146 15.1369 81.4092 14.4227C82.5619 13.6709 83.981 13.9542 84.8383 15.1074C86.604 17.4857 87.4954 20.136 87.5159 24.4865Z" />
        <path d="M42.9091 16.6441C42.6717 17.124 42.5044 17.6498 42.1885 18.0757C40.8718 19.8496 40.006 21.7595 40.0317 23.9806C40.0539 25.8627 40.6379 27.5662 41.7462 29.1108C43.8381 32.0313 44.7688 35.2401 44.2872 38.7765C43.9337 41.3727 42.8903 43.7036 41.1314 45.715C40.3237 46.6389 38.7919 46.729 37.8185 45.9722C36.799 45.1794 36.6163 43.8133 37.465 42.7585C39.0088 40.842 39.726 38.7077 39.5416 36.2835C39.4169 34.6619 38.8414 33.2074 37.8868 31.8855C35.9264 29.1681 35.0043 26.1706 35.2553 22.8701C35.4824 19.8955 36.7273 17.3026 38.6314 14.9865C39.2137 14.2772 40.3527 14.0741 41.2885 14.4099C42.1936 14.7342 42.7947 15.6138 42.9091 16.6457V16.6441Z" />
        <path d="M75.5261 23.4516C75.5773 26.4016 74.3068 29.2599 72.2815 31.8528C71.5182 32.8291 70.1042 33.0469 69.0779 32.3999C68.0243 31.7365 67.664 30.4638 68.2582 29.3795C68.4956 28.9487 68.8184 28.5605 69.0916 28.1477C71.3355 24.739 71.3543 21.3091 69.0404 17.9529C67.0731 15.0995 66.0844 12.0398 66.4379 8.61309C66.7077 5.99723 67.6998 3.65819 69.3819 1.59269C70.3621 0.388768 71.7504 0.16764 72.8895 0.991546C73.996 1.79252 74.1326 3.19464 73.1849 4.39692C71.2347 6.87519 70.7036 9.62209 71.607 12.577C71.8888 13.4992 72.3891 14.3919 72.9475 15.1978C74.6108 17.6007 75.5415 20.2002 75.5244 23.4516H75.5261Z" />
        <path d="M28.7437 13.3469C30.6563 13.5156 31.8209 15.2404 31.0268 16.7964C30.6665 17.5008 30.1662 18.1396 29.7239 18.8046C27.4493 22.2329 27.4441 25.6645 29.7529 29.0715C30.3011 29.8807 30.5726 30.6866 30.1559 31.6006C29.408 33.2418 27.1641 33.517 26.0558 32.0412C23.0486 28.033 22.3928 23.6432 24.2286 19.0421C24.8775 17.4156 25.9687 15.9332 26.9984 14.4771C27.3639 13.9595 28.1494 13.7154 28.7437 13.3452V13.3469Z" />
        <path d="M90.3194 10.8241C90.3638 6.83565 91.3525 4.05271 93.3215 1.59573C94.2334 0.457335 95.7686 0.205086 96.8563 0.992956C97.9544 1.78902 98.079 3.237 97.1518 4.40488C94.6432 7.56455 94.4485 11.4253 96.6224 14.7864C97.0254 15.4105 97.4592 16.0231 97.799 16.6783C98.3625 17.7659 97.9749 18.9829 96.9332 19.6005C95.8471 20.2442 94.5373 20.1082 93.8082 19.1287C91.7641 16.3818 90.2203 13.4433 90.3211 10.8258L90.3194 10.8241Z" />
        <path d="M89.8203 36.6931C89.9364 35.8594 89.9723 35.0044 90.1892 34.1952C90.5034 33.0158 91.7705 32.3426 92.9693 32.6031C94.2449 32.8799 95.0441 33.9741 94.8528 35.214C94.4601 37.7529 94.9587 40.0854 96.5639 42.1722C96.7996 42.4785 97.0216 42.7979 97.2077 43.1337C97.7713 44.1541 97.4075 45.4531 96.3983 46.0886C95.3839 46.7274 93.9717 46.5652 93.2408 45.666C91.0994 43.0338 89.8562 40.0854 89.822 36.6948L89.8203 36.6931Z" />
        <path d="M27.5486 6.9047C27.5537 5.63199 28.6091 4.64265 29.9445 4.65739C31.2747 4.67213 32.342 5.68441 32.342 6.93255C32.342 8.16431 31.2406 9.24211 29.965 9.25357C28.6347 9.26504 27.5418 8.20363 27.5469 6.9047H27.5486Z" />
        <path d="M96.5746 24.3014C97.9066 24.3145 98.8987 25.3104 98.8817 26.6175C98.8646 27.8951 97.8075 28.9009 96.4943 28.8828C95.1863 28.8665 94.1258 27.8411 94.1173 26.5831C94.107 25.2924 95.188 24.2883 96.5746 24.3014Z" />
        <path d="M58.0445 79.4969C39.8031 79.4969 21.56 79.4969 3.31858 79.4969C1.6553 79.4969 0.644358 78.6763 0.610205 77.3053C0.577759 76.0539 1.4999 75.0449 2.80115 74.9286C3.13927 74.8974 3.48252 74.9122 3.82405 74.9122C40.0524 74.9122 76.2807 74.9122 112.507 74.9171C112.986 74.9171 113.482 74.9253 113.938 75.0449C115.062 75.3381 115.738 76.4306 115.583 77.5854C115.443 78.632 114.504 79.4428 113.366 79.4953C113.112 79.5067 112.856 79.4985 112.6 79.4985C94.4145 79.4985 76.2295 79.4985 58.0445 79.4985V79.4969Z" />
        <path d="M53.2792 64.452C51.9336 64.4405 50.9107 63.4512 50.9141 62.1637C50.9175 60.8992 51.978 59.8885 53.3031 59.8853C54.6573 59.882 55.7417 60.9352 55.7143 62.2276C55.687 63.4987 54.6317 64.4634 53.2775 64.452H53.2792Z" />
        <path d="M110.746 64.4518C109.405 64.4174 108.386 63.4002 108.415 62.1259C108.445 60.8335 109.579 59.8278 110.937 59.8867C112.262 59.9457 113.258 60.9875 113.213 62.2667C113.169 63.5526 112.113 64.4862 110.746 64.4502V64.4518Z" />
        <path d="M46.1487 69.0416C46.1692 67.7443 47.199 66.7648 48.5326 66.773C49.8407 66.7796 50.9183 67.8148 50.9183 69.0645C50.9183 70.3422 49.7741 71.4331 48.4678 71.4019C47.1665 71.3708 46.1282 70.3143 46.1487 69.0432V69.0416Z" />
        <path d="M106.047 66.7744C107.372 66.7826 108.417 67.7965 108.415 69.0725C108.412 70.3387 107.351 71.3804 106.048 71.3952C104.722 71.4116 103.612 70.3239 103.637 69.0332C103.663 67.7539 104.716 66.7679 106.047 66.7761V66.7744Z" />
        <path d="M5.40944 64.4517C4.05355 64.4615 3.02041 63.5033 2.9965 62.2126C2.97259 60.948 4.01256 59.9096 5.33089 59.8834C6.68337 59.8555 7.8019 60.8973 7.79677 62.1782C7.79165 63.4624 6.76192 64.4435 5.40944 64.4533V64.4517Z" />
        <path d="M17.369 62.1899C17.3639 63.479 16.3392 64.452 14.9851 64.452C13.6394 64.452 12.5824 63.4692 12.5704 62.2063C12.5585 60.9074 13.6565 59.8656 15.009 59.8886C16.3529 59.9115 17.3741 60.9074 17.369 62.1883V62.1899Z" />
        <path d="M65.2942 62.2341C65.2652 63.5134 64.2116 64.4733 62.8591 64.452C61.5203 64.4307 60.4837 63.4266 60.4922 62.1588C60.5008 60.8746 61.633 59.8394 62.9786 59.8853C64.3055 59.9311 65.3233 60.9614 65.2942 62.2341Z" />
        <path d="M101.251 64.4518C99.8953 64.4649 98.8536 63.5001 98.84 62.2143C98.8263 60.935 99.8509 59.9129 101.173 59.8867C102.539 59.8605 103.644 60.8859 103.64 62.1783C103.637 63.4477 102.597 64.437 101.251 64.4502V64.4518Z" />
        <path d="M70.062 69.0448C70.0791 70.311 69.0374 71.3642 67.7362 71.4003C66.4383 71.4363 65.2891 70.3356 65.2891 69.0579C65.2891 67.8082 66.37 66.7762 67.6798 66.773C69.0237 66.7713 70.0449 67.7443 70.062 69.0448Z" />
        <path d="M79.6451 69.045C79.6571 70.3259 78.6273 71.3709 77.3227 71.3971C76.0026 71.4233 74.867 70.3357 74.879 69.0597C74.891 67.7854 75.9412 66.7797 77.2629 66.7764C78.6069 66.7731 79.6315 67.7493 79.6434 69.0466L79.6451 69.045Z" />
        <path d="M94.0703 69.0823C94.0669 67.7834 95.0761 66.7875 96.4098 66.7711C97.7555 66.7564 98.8689 67.8211 98.845 69.102C98.8228 70.342 97.7025 71.4083 96.4337 71.3968C95.1274 71.3853 94.0754 70.3534 94.072 69.0823H94.0703Z" />
        <path d="M12.5717 69.0809C12.5717 70.3405 11.501 71.3823 10.1963 71.3938C8.90361 71.4052 7.8107 70.3618 7.79704 69.1055C7.78167 67.7853 8.84043 66.7665 10.2151 66.7779C11.5488 66.7894 12.5734 67.7902 12.5734 69.0809H12.5717Z" />
        <path d="M55.7189 69.0514C55.7308 67.7688 56.7691 66.7828 58.1079 66.7778C59.474 66.7746 60.526 67.8114 60.4935 69.1316C60.4628 70.3896 59.3511 71.4232 58.0652 71.392C56.7571 71.3609 55.7069 70.3126 55.7189 69.0514Z" />
        <path d="M24.5628 64.4566C23.2496 64.4665 22.1618 63.4509 22.1447 62.2011C22.1277 60.9776 23.2223 59.9047 24.5133 59.8785C25.8589 59.8506 26.9672 60.8989 26.9587 62.1913C26.9518 63.4575 25.905 64.4468 24.5628 64.4566Z" />
        <path d="M34.14 64.4518C32.8166 64.4518 31.7373 63.4396 31.7305 62.1914C31.7237 60.9171 32.8507 59.8622 34.193 59.8851C35.484 59.9081 36.5957 60.9891 36.5701 62.198C36.5444 63.4461 35.4618 64.4518 34.1417 64.4518H34.14Z" />
        <path d="M43.6786 64.4535C42.3466 64.4371 41.3152 63.4215 41.3322 62.1456C41.3493 60.8794 42.4149 59.8769 43.7418 59.8819C45.0379 59.8868 46.1462 60.9351 46.1496 62.1652C46.1547 63.4477 45.0498 64.4698 43.6786 64.4535Z" />
        <path d="M94.0678 62.2094C94.0439 63.4575 92.9544 64.4649 91.6378 64.4567C90.2665 64.4485 89.206 63.4018 89.2556 62.1029C89.3034 60.8318 90.3775 59.8572 91.7078 59.8818C92.9971 59.9047 94.0934 60.9842 94.0695 62.2094H94.0678Z" />
        <path d="M22.1397 69.0958C22.1312 70.3619 21.0588 71.3971 19.7609 71.3939C18.4836 71.3906 17.3736 70.3242 17.3634 69.0876C17.3531 67.8034 18.4665 66.7534 19.8139 66.7747C21.1493 66.7977 22.1483 67.7936 22.138 69.0958H22.1397Z" />
        <path d="M31.73 69.0888C31.7266 70.3402 30.6491 71.3885 29.3547 71.4C28.0876 71.4114 26.9656 70.332 26.9571 69.097C26.9485 67.8341 28.0056 66.789 29.3068 66.7727C30.6884 66.7563 31.7335 67.7538 31.7283 69.0904L31.73 69.0888Z" />
        <path d="M36.5685 68.9663C36.6214 67.6722 37.6717 66.7353 39.0258 66.7746C40.3322 66.8123 41.3688 67.8754 41.3278 69.1366C41.2868 70.388 40.17 71.4216 38.8909 71.3937C37.5385 71.3643 36.5138 70.293 36.5685 68.9679V68.9663Z" />
        <path d="M74.882 62.1753C74.882 63.4398 73.825 64.4537 72.5015 64.457C71.1713 64.4603 70.0903 63.4693 70.063 62.2162C70.0357 60.9599 71.173 59.869 72.5015 59.8805C73.8165 59.8903 74.8803 60.9157 74.882 62.1753Z" />
        <path d="M84.4769 62.1601C84.494 63.4033 83.4164 64.4451 82.1032 64.4533C80.7524 64.4631 79.6271 63.4082 79.6407 62.147C79.6544 60.9267 80.7781 59.88 82.0708 59.8833C83.384 59.8866 84.4581 60.9038 84.4769 62.1601Z" />
        <path d="M84.4853 69.0007C84.5211 67.7001 85.5543 66.7567 86.9119 66.7812C88.2251 66.8058 89.277 67.8476 89.2565 69.1023C89.236 70.3389 88.1107 71.4118 86.8487 71.3971C85.5065 71.3807 84.4494 70.3095 84.4853 69.0007Z" />
      </g>
    </SvgIcon>
  );
};
