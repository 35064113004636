import { Field, ErrorMessage, FieldProps, FormikValues } from "formik";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import TextError from "./TextError";
import { FormControl } from "@mui/material";
import TextLabel from "../TextLabel";

interface DatePickerProps {
  label: string;
  name: string;
  isrequired?: boolean;
}

const DatePicker = (props: DatePickerProps) => {
  const { label, name, isrequired } = props;

  return (
    <FormControl sx={{ width: "100%" }}>
      <TextLabel label={label} required={isrequired} />
      <Field name={name}>
        {({ form, field }: FieldProps<FormikValues>) => {
          const { setFieldValue } = form;
          const { value } = field;
          const dateValue = value ? dayjs(value as any) : null;
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MuiDatePicker
                sx={{ width: "100%" }}
                slotProps={{ popper: { sx: { zIndex: "10000" } } }}
                onChange={(date) =>
                  setFieldValue(
                    name,
                    date ? dayjs(date).toISOString() : "",
                    true
                  )
                }
                value={dateValue}
              />
            </LocalizationProvider>
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
        className="error"
      />
    </FormControl>
  );
};

export default DatePicker;
