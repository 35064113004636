import { get } from "utils/publicHttp.utils";
import localConfig from "../configurations/default.configuration.json";
import { getBucketUrl } from "utils/assets.utils";
import { buildLocalUrl } from "utils/url.utils";
import {
  LeftNavigationItemsOrientation,
  ProgressBarStyle,
  PermissionTypes,
} from "constants/index";

const CONFIGURATION_SERVICE_ROUTES = {
  CONFIGURATION_SERVICE_CLIENT_CONFIGURATIONS:
    "/configuration-service/configuration-service/rest/v1/client/configurations",
  CONFIGURATION_SERVICE_CLIENT_TRANSLATIONS:
    "/configuration-service/configuration-service/rest/v1/client/translations/:uuid",
};

export interface MenuItem {
  id?: string;
  icon: string;
  label: string;
  link?: string;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  orientation?: LeftNavigationItemsOrientation;
  params?: { languages: string[] };
  show?: boolean;
  permissions?: PermissionTypes[];
}

export interface Translations {
  [key: string]: string;
}

export interface Language {
  id: string;
  label: string;
}

export interface Logo {
  small: string;
  large: string;
}

export interface Config {
  favicon: string;
  languages: Language[];
  logo?: Logo;
  drawerTransitionDuration?: number;
  experimentSummaryPage?: any;
  trialStatus?: any;
  heading: {
    style: ProgressBarStyle;
  };
}

export interface Configuration {
  theme: any;
  config: Config;
  menuItems: MenuItem[];
  translations: {
    [key: string]: Translations;
  };
}

export interface MetaData {
  uuid: string;
  name: string;
  description: string;
  type: "APPLICATION";
  domains: string;
  version: Number;
}
class _ConfigurationService {
  private _configuration: Configuration;
  private _isInitialized: boolean = false;
  private _metaData: MetaData;

  constructor() {
    this._configuration = localConfig as Configuration;
    this._metaData = {} as MetaData;
  }

  async init(): Promise<Configuration> {
    try {
      const apiUrl = buildLocalUrl({
        path: CONFIGURATION_SERVICE_ROUTES.CONFIGURATION_SERVICE_CLIENT_CONFIGURATIONS,
      });
      const result = await get(apiUrl);
      const { data, ...rest } = result[0];
      data.translations = await this.fetchTranslationData(data.translations);
      this._metaData = rest;
      this._configuration = this._replacePaths(data);
      this._isInitialized = true;
      return this._configuration;
    } catch (error) {
      this._configuration = this._replacePaths(localConfig);
      this._isInitialized = true;
      throw error;
    }
  }

  fetchTranslationData = async (
    uuid: string
  ): Promise<{
    [key: string]: Translations;
  }> => {
    try {
      if (!uuid) return {};
      const apiUrl = buildLocalUrl({
        path: CONFIGURATION_SERVICE_ROUTES.CONFIGURATION_SERVICE_CLIENT_TRANSLATIONS,
        params: {
          uuid,
        },
      });
      const response = await get(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  get configuration(): Configuration {
    return this._configuration;
  }

  get initialized(): boolean {
    return this._isInitialized;
  }

  get metaData(): MetaData {
    return this._metaData;
  }

  private _replacePaths(configuration: any) {
    let stringifiedData = JSON.stringify(configuration);
    stringifiedData = stringifiedData.replaceAll(
      "{STORAGE_BASE_PATH}",
      getBucketUrl()
    );
    return JSON.parse(stringifiedData);
  }
}

export const ConfigurationService = new _ConfigurationService();
