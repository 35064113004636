import React from "react";
import { Snackbar as MUISnackbar, Alert, AlertTitle } from "@mui/material";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import { useTranslation } from "react-i18next";
import Slide, { SlideProps } from "@mui/material/Slide";

type TransitionProps = Omit<SlideProps, "direction">;
function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="right" />;
}
function capitalize(s: String) {
  return s[0].toUpperCase() + s.slice(1);
}

const Snackbar: React.FC = () => {
  const { isOpen, message, severity, options, closeSnackbar } =
    useSnackbarStore();
  const { t } = useTranslation();

  return (
    <MUISnackbar
      open={isOpen}
      TransitionComponent={TransitionLeft}
      autoHideDuration={options.autoHideDuration}
      onClose={closeSnackbar}
    >
      <Alert onClose={closeSnackbar} severity={severity}>
        <AlertTitle>{t(capitalize(severity))}</AlertTitle>
        {t(message)}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
