import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import { getParticipantAnnotation } from "services/annotation.service";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import { AnnotationStatus } from "services/annotation.service";

import {
  updateParticipant,
  deleteParticipant as _deleteParticipant,
  UpdateParticipant,
} from "services/cde.service";
import { CustomError } from "utils/privateHttp.utils";

interface Options {
  trialId: string;
  participantId: string;
}

function useParticipantDetailsApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);
  const successNotification = useSnackbarStore((state) => state.openSuccess);
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const queryClient = useQueryClient();

  const annotationsQuery = useQuery({
    queryKey: [QueryKeys.PARTICIPANT_ANNOTATIONS, options.participantId],
    queryFn: async () =>
      getParticipantAnnotation(options.participantId, {
        status: `${AnnotationStatus.COMPLETE}|${AnnotationStatus.PENDING}`,
      }),
  });

  const refreshAnnotationsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.PARTICIPANT_ANNOTATIONS, options.participantId],
    });
  };

  const updateParticipantDetails = useMutation({
    mutationFn: async (data: UpdateParticipant) =>
      await updateParticipant(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL_PARTICIPANTS],
      });
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  const deleteParticipant = useMutation({
    mutationFn: async (participantId: string) =>
      await _deleteParticipant(options.trialId, participantId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL_PARTICIPANTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL],
      });
      successNotification("participant.delete.success");
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  return {
    annotationsQuery,
    refreshAnnotationsQuery,
    updateParticipantDetails,
    deleteParticipant,
    setOptions,
  };
}

export default useParticipantDetailsApi;
