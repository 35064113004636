import React from "react";

interface DynamicIconProps {
  component: React.ElementType;
  sx?: any;
}

const DynamicIcon: React.FC<DynamicIconProps> = ({
  component: Component,
  sx,
}) => {
  return <Component sx={sx} />;
};

export default DynamicIcon;
