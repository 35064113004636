import { useEffect, useState } from "react";
import { useExperimentStore } from "stores/experiment.store";
import { ExperimentActions } from "../experiment.dto";
import { CardStatus } from "components/Card";
import { TimerDirection } from "constants/index";
import { getTimeDifference } from "services/time.services";

interface ExperimentTimerControllerProps {
  actions: ExperimentActions;
}

export const ExperimentTimerController: React.FC<
  ExperimentTimerControllerProps
> = ({ actions }) => {
  const setTimeSinceEvent = useExperimentStore(
    (state) => state.setTimeSinceEvent
  );
  const timeSinceEvent = useExperimentStore((state) => state.timeSinceEvent);
  const currentEvent = useExperimentStore((state) => state.currentEvent);
  const validation = useExperimentStore((state) => state.validation);
  const timerStatus = useExperimentStore((state) => state.timerStatus);
  const annotationEvent = useExperimentStore((state) => state.annotationEvent);
  const setElapsedTime = useExperimentStore((state) => state.setElapsedTime);
  const stopTimer = useExperimentStore((state) => state.stopTimer);
  const setStopExperiment = useExperimentStore(
    (state) => state.setStopExperiment
  );
  const [asyncFunctionInProgress, setAsyncFunctionInProgress] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchInitialTime = async () => {
      try {
        const differenceInMilliseconds = await getTimeDifference()
        setElapsedTime(differenceInMilliseconds);
      } catch (error) {
        console.error("Error fetching UTC time:", error);
      }
    };
    fetchInitialTime();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSinceEvent();
    }, 500);
    if (!timerStatus) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timerStatus]);

  const getTimer = (status: CardStatus) => {
    if (!currentEvent) return {};
    if (!currentEvent.timer) return {};
    const timerConfig = currentEvent.timer.actions.reduce(
      (acc: any, action: any) => {
        if (action.statuses.includes(status)) {
          acc = {
            actions: action.actions,
            time: action.time,
            direction: currentEvent.timer.direction,
          };
        }
        return acc;
      },
      {}
    );
    return timerConfig;
  };

  useEffect(() => {
    const timer = getTimer(annotationEvent?.status);
    if (!timer) return;
    const executeAsyncFunction = async () => {
      setAsyncFunctionInProgress(true);
      if (currentEvent && timer?.actions) {
        for (const action of timer.actions) {
          typeof action === "object"
            ? await actions[action.name](annotationEvent.status, action.params)
            : await actions[action](annotationEvent.status);
        }
      }
      setAsyncFunctionInProgress(false);
    };

    if (!currentEvent) return;
    if (!validation.verdict) {
      if (currentEvent.stopOn?.status?.includes(annotationEvent.status)) {
        stopTimer();
        setStopExperiment(true);
        if (!asyncFunctionInProgress) {
          executeAsyncFunction();
        }
        return;
      }
    }
    if (
      timeSinceEvent >= Number(timer.time) &&
      validation.verdict &&
      timer.direction === TimerDirection.DOWN
    ) {
      if (!asyncFunctionInProgress) {
        executeAsyncFunction();
      }
      return;
    }
  }, [timeSinceEvent]);

  return <></>;
};
