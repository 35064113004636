import React from "react";

import { Timer as ShowTime } from "components/Timer.component";

import { useResultStore } from "../result.store";
import { TimerDirection } from "constants/index";

interface TimerProps {
  sx?: any;
  direction?: TimerDirection;
}

export const Timer: React.FC<TimerProps> = ({ direction, sx = {} }) => {
  const timeSinceEvent = useResultStore((state) => state.timeSinceEvent);
  const currentEvent = useResultStore((state) => state.currentEvent);

  return (
    <ShowTime
      seconds={timeSinceEvent}
      timeout={currentEvent?.timeout.value}
      direction={direction}
      sx={sx}
    />
  );
};
