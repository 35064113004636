import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface SpinnerIconProps extends SvgIconProps {
  sx?: {
    baseColor?: string;
    [key: string]: any;
  };
}

const adjustColor = (color: string, amount: number): string => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
};
export const SpinnerIcon: React.FC<SpinnerIconProps> = ({ sx }) => {
  const theme = useTheme();
  const baseColor = sx?.baseColor || theme.palette.info.main;

  const colors: string[] = [
    adjustColor(baseColor, 80),
    adjustColor(baseColor, 60),
    adjustColor(baseColor, 40),
    adjustColor(baseColor, 20),
    baseColor,
    adjustColor(baseColor, -20),
    adjustColor(baseColor, -40),
    adjustColor(baseColor, -60),
    adjustColor(baseColor, -80),
  ];

  const positions: string[] = [
    "translate(18.02 3.18)",
    "translate(26.78 6.05)",
    "translate(32.14 13.45)",
    "translate(32.14 22.59)",
    "translate(26.74 30.03)",
    "translate(17.99 32.82)",
    "translate(9.23 30.03)",
    "translate(3.87 22.59)",
    "translate(3.86 13.45)",
  ];

  return (
    <SvgIcon
      viewBox="0 0 36 36"
      sx={{
        ...sx,
        animation: `${"spin"} 5s linear infinite`,
        "@keyframes spin": {
          from: { transform: "rotate(0deg)" },
          to: { transform: "rotate(360deg)" },
        },
      }}
    >
      <g id="eNnGrJtY9Q62" transform="translate(.000001 0.000001)">
        {colors.map((color, index) => (
          <circle
            key={index}
            r="3.16"
            transform={positions[index]}
            fill={color}
            strokeWidth="0"
          />
        ))}
      </g>
    </SvgIcon>
  );
};
