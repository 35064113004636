import React from "react";

import { Timer as ShowTime } from "components/Timer.component";
import { usePurgeStore } from "../purge.store";
import { TimerDirection } from "constants/index";

interface TimerProps {
  sx?: any;
  direction?: TimerDirection;
  time?: number;
}

export const Timer: React.FC<TimerProps> = ({ direction, time, sx = {} }) => {
  const timeSinceEvent = usePurgeStore((state) => state.timeSinceEvent);
  const currentEvent = usePurgeStore((state) => state.currentEvent);
  if (!currentEvent) return;
  if (currentEvent.timer.timeout && timeSinceEvent > currentEvent.timer.timeout)
    return;
  return (
    <ShowTime
      seconds={timeSinceEvent}
      timeout={time}
      sx={sx}
      direction={direction}
    />
  );
};
