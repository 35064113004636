import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import moment from "moment";
import { getAnnotationAnalyticsByIdentity } from "services/annotation.service";
import {
  TrialAnnotationStatus,
  fetchParticipantsList,
  userTrailsAnalytics,
  userTrailsMonthlyAnalytics,
} from "services/cde.service";

interface Options {
  trialId?: string;
  cdeId?: string;
  resultsGraph?: boolean;
  engagementGraph?: boolean;
  cdeIds?: string[];
  take?: number;
  skip?: number;
  expand?: string;
}

function useStudiesDashboardApi(options: Options) {
  const CdeAnalytics = useQuery({
    queryKey: [QueryKeys.STUDIES_DASHBOARD, options],
    queryFn: async () => await userTrailsAnalytics(options),
    enabled: options.cdeId ? true : false,
  });

  const CdeMonthyAnalytics = useQuery({
    queryKey: [QueryKeys.STUDY_OVERVIEW, options],
    queryFn: async () => {
      const currentMonth = moment().month();
      const currentYear = moment().year();

      const completedTrials = {
        x: [] as string[],
        y: [] as number[],
      };

      const failedTrials = {
        x: [] as string[],
        y: [] as number[],
      };

      for (let month = currentMonth + 1; month <= currentMonth + 12; month++) {
        const yearOffset = month > 11 ? currentYear : currentYear - 1;
        const monthOffset = month % 12;
        const startDate = moment({ year: yearOffset, month: monthOffset });
        const from = startDate.startOf("month").format("YYYY-MM-DD");
        const to = startDate.endOf("month").format("YYYY-MM-DD");

        const result = await userTrailsMonthlyAnalytics({
          ...options,
          from,
          to,
        } as any);
        const complete = result?.statistics?.complete || 0;
        const total = result?.statistics?.total || 0;
        if (total > 0) {
          completedTrials.x.push(startDate.format("MMM YYYY"));
          failedTrials.x.push(startDate.format("MMM YYYY"));
          completedTrials.y.push(complete);
          failedTrials.y.push(total - complete);
        }
      }
      return { completedTrials, failedTrials };
    },
    enabled: options.cdeId && options.resultsGraph ? true : false,
  });

  const ParticipantsMonthyAnalytics = useQuery({
    queryKey: [QueryKeys.STUDY_PARTICIPANTS, options],
    queryFn: async () => {
      const { trialId } = options;
      if (!trialId) return;

      const currentMonth: number = moment().month();
      const currentYear: number = moment().year();

      interface TrialData {
        x: string[];
        y: number[];
      }

      const trialTypes: string[] = [
        TrialAnnotationStatus.complete,
        TrialAnnotationStatus.terminated,
        TrialAnnotationStatus.inProgress,
        TrialAnnotationStatus.timeout,
      ];
      const trialsData: Record<string, TrialData> = trialTypes.reduce(
        (acc: any, type) => {
          acc[type] = { x: [], y: [] };
          return acc;
        },
        {}
      );

      for (
        let month: number = currentMonth + 1;
        month <= currentMonth + 12;
        month++
      ) {
        const yearOffset: number = month > 11 ? currentYear : currentYear - 1;
        const monthOffset: number = month % 12;
        const startDate: moment.Moment = moment({
          year: yearOffset,
          month: monthOffset,
        });
        const from: string = startDate.startOf("month").format("YYYY-MM-DD");
        const to: string = startDate.endOf("month").format("YYYY-MM-DD");

        const result: any = await getAnnotationAnalyticsByIdentity(trialId, {
          from,
          to,
        });

        trialTypes.forEach((type) => {
          const count: number = result?.annotations?.[type] || 0;
          if (count > 0) {
            trialsData[type].x.push(startDate.format("MMM YYYY"));
            trialsData[type].y.push(count);
          }
        });
      }
      return trialsData;
    },
    enabled: options.engagementGraph ?? false,
  });

  const TrialAnalytics = useQuery({
    queryKey: [QueryKeys.STUDY_PARTICIPANTS, options],
    queryFn: async () => {
      const aggregatedTrialAnalytics = [];
      if (options?.cdeIds?.length) {
        for (const cdeId of options.cdeIds) {
          const result = await userTrailsMonthlyAnalytics({
            cdeId,
          });
          if (result) {
            aggregatedTrialAnalytics.push(...result.trialAnalytics);
          }
        }
      }
      return aggregatedTrialAnalytics;
    },
    enabled: options.cdeIds?.length ? true : false,
  });

  const TrialAllParticipants = useQuery({
    queryKey: [QueryKeys.TRIAL_PARTICIPANTS, options.trialId, options],
    queryFn: async () => {
      const participants = await fetchParticipantsList(
        options.trialId || "",
        undefined,
        options.take || 1000,
        options.skip || 0,
        options.expand
      );
      if (options?.take && participants.total > options?.take) {
        const allParticipants = await fetchParticipantsList(
          options.trialId || "",
          undefined,
          participants.total,
          0,
          options.expand
        );
        return allParticipants;
      }
      return participants;
    },
    enabled: !!options.trialId,
  });

  return {
    CdeAnalytics,
    CdeMonthyAnalytics,
    ParticipantsMonthyAnalytics,
    TrialAnalytics,
    TrialAllParticipants,
  };
}

export default useStudiesDashboardApi;
