import * as Yup from "yup";

export interface FieldConfig {
  name: string;
  type: string;
  width: number;
  label: string;
  placeholder: string;
  required: boolean;
  error: string;
  validation?: string | RegExp;
  options?: { key: string; value: string; children?: string }[];
  dependencies?: { [key: string]: string }[];
  onClose?: () => void;
  hasChildren?: boolean;
}

export function generateValidationSchema(
  schemaData: FieldConfig[]
): Yup.ObjectSchema<any> {
  const shape: { [key: string]: Yup.Schema<any> } = {};

  for (const field of schemaData) {
    const { name, type, required, error, validation } = field;
    let fieldSchema: Yup.Schema<any>;

    if (type === "checkbox") {
      fieldSchema = Yup.array();
    } else {
      fieldSchema = Yup.string();

      if (required) {
        fieldSchema = (fieldSchema as Yup.StringSchema).required(error);
      }

      if (type === "text" && validation) {
        fieldSchema = (fieldSchema as Yup.StringSchema).matches(
          validation instanceof RegExp ? validation : new RegExp(validation),
          error
        );
      }
    }

    shape[name] = fieldSchema;
  }

  return Yup.object().shape(shape);
}
