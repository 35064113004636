import { CardStatus } from "components/Card";
import { ExperimentActions } from "../experiment.dto";
import { ValidationResult, useExperimentStore } from "stores/experiment.store";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/system";
import { Grid } from "@mui/material";
import Button, { ButtonColor } from "components/Button";
import { useTranslation } from "react-i18next";
import { Timer } from "./Timer.component";
import { AnnotationStatus } from "services/annotation.service";
import useDeviceApi from "hooks/useDeviceApi.hook";
import { hasControlsState } from "utils/device.utils";

interface ExperimentProps {
  actions: ExperimentActions;
}

enum PurgeActions {
  REDIRECT_TO_PURGE = "redirectToPurge",
  TERMINATE_ANNOTATION_AND_REDIRECT_TO_PURGE = "terminateAnnotationAndRedirectToPurge",
}

const TimerButtonContainer: React.FC<ExperimentProps> = ({ actions }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validate = useExperimentStore((state) => state.validate);
  const currentEvent = useExperimentStore((state) => state.currentEvent);
  const loading = useExperimentStore((state) => state.loading);
  const validation = useExperimentStore((state) => state.validation);
  const annotationStatus = useExperimentStore((state) => state.status);
  const updateEventStatus = useExperimentStore(
    (state) => state.updateEventStatus
  );
  const failedStep = useExperimentStore((state) => state.failedStep);
  const setLoading = useExperimentStore((state) => state.setLoading);
  const serialNumbers = useExperimentStore((state) => state.serialNumbers);
  const timerStatus = useExperimentStore((state) => state.timerStatus);
  const deviceQueries = useDeviceApi({
    serialNumber: serialNumbers && serialNumbers.length ? serialNumbers[0] : "",
    refetchInterval: 1000,
  });

  const [progressStatus, setProgressStatus] = useState(CardStatus.PENDING);
  const isExecutedRef = useRef(false);
  const isAllVerdictPass = useRef(false);

  const getActionButtons = (status: CardStatus) => {
    if (!currentEvent?.actions) return [];

    return currentEvent.actions
      .filter((action) => action.showInStatuses.includes(status))
      .map((action) => ({
        action: async () => {
          for (const subAction of action.actions) {
            if (typeof subAction === "object") {
              const { name, params } = subAction;
              await actions[name](status, params);
            } else {
              const isPurgeAction = [
                PurgeActions.TERMINATE_ANNOTATION_AND_REDIRECT_TO_PURGE,
                PurgeActions.REDIRECT_TO_PURGE,
              ].includes(subAction as PurgeActions);
              await actions[subAction](
                isPurgeAction ? action.afterPurgeRedirectTo : status
              );
            }
          }
        },
        disabled: false,
        label: action.label,
        color: action.color as ButtonColor,
        isLoading: loading,
      }));
  };

  const getTimer = (status: CardStatus) => {
    if (!currentEvent) return [];
    if (!currentEvent.timer) return [];
    const timerConfig = currentEvent.timer.actions.reduce(
      (acc: any[], action: any) => {
        if (action.statuses.includes(status)) {
          acc.push({
            isVisible: currentEvent.timer.isVisible ?? true,
            time: action.time,
            direction: currentEvent.timer.direction,
          });
        }
        return acc;
      },
      []
    );
    return timerConfig;
  };

  const isValidResults = (results: ValidationResult[]) =>
    results.every(({ verdict, results }) => {
      if (!verdict) return false;
      const telemetryTypes = Object.values(results).map(
        (result: any) => result.telemetryType
      );
      return telemetryTypes.every(
        (telemetryType) =>
          currentEvent &&
          currentEvent.validate.rules.some(({ params }) =>
            params.includes(telemetryType)
          )
      );
    });

  const executeAction = async (
    action: any,
    actions: ExperimentActions,
    progressStatus: CardStatus
  ) => {
    if (!action?.name || !actions[action.name]) return;
    await actions[action.name](progressStatus, action.params);
  };

  useEffect(() => {
    if (!deviceQueries.device.data?.state.metrics) return;
    const metrics = deviceQueries.device.data?.state.metrics;
    if (!metrics) return;
    validate(metrics);
  }, [deviceQueries.device.data]);

  const evaluateCurrentStatus = () => {
    let status = CardStatus.PENDING;
    if (!currentEvent) return status;
    if (failedStep?.toLowerCase() === currentEvent.name.toLowerCase()) {
      status = CardStatus.FAIL;
      setLoading(false);
    } else if (!validation) {
      status = CardStatus.SUCCESS;
    } else if (validation.verdict) {
      status = CardStatus.SUCCESS;
    } else {
      status = CardStatus.WARNING;
    }

    updateEventStatus(currentEvent.name, status);
    return status;
  };

  useEffect(() => {
    if (!currentEvent) return;
    setProgressStatus(evaluateCurrentStatus());
  }, [failedStep, progressStatus, validation, currentEvent, timerStatus]);

  useEffect(() => {
    if (isExecutedRef.current) return;
    const executeValidateRulesActions = async () => {
      if (!validation?.results) return;
      if (!currentEvent) return;
      const controlsState = hasControlsState(validation);
      if (controlsState) return;

      const allActions = currentEvent.validate.rules.flatMap(
        (rule) => rule.actions || []
      );
      if (!allActions.length) return;

      const hasValidResultsForAllParams = currentEvent.validate?.rules.every(
        (rule) => {
          return rule.params.every((param) => {
            const resultWithParam = validation?.results?.find(
              (result) => result.results[param] && result.verdict
            );
            return Boolean(resultWithParam);
          });
        }
      );
      if (!hasValidResultsForAllParams) return;
      isExecutedRef.current = true;

      for (const action of allActions) {
        await executeAction(action, actions, progressStatus);
      }
    };
    executeValidateRulesActions();
  }, [validation]);

  useEffect(() => {
    if (isAllVerdictPass.current) return;
    const executeValidateActions = async () => {
      if (!validation?.results) return;
      if (!currentEvent) return;
      if (!currentEvent.validate?.actions) return;

      const allTrueVerdict = isValidResults(validation.results);
      if (!allTrueVerdict) return;
      isAllVerdictPass.current = true;
      for (const singleAction of currentEvent.validate.actions) {
        await executeAction(singleAction, actions, progressStatus);
      }
    };
    executeValidateActions();
  }, [validation]);

  const actionsList = getActionButtons(progressStatus);
  const timerActions = getTimer(progressStatus);
  return (
    <Grid container alignItems={"center"} justifyContent={"end"}>
      <Grid item>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"end"}
        >
          {timerActions.length > 0 && timerStatus && (
            <Grid item sx={{ color: theme.palette.text.secondary }}>
              {annotationStatus === AnnotationStatus.IN_PROGRESS &&
                timerActions?.map((data: any, i: number) => (
                  <Timer
                    key={`timer-${i}`}
                    direction={data.direction}
                    time={data.time}
                    sx={{ display: data.isVisible ? "block" : "none" }}
                  />
                ))}
            </Grid>
          )}
          {actionsList.length > 0 && (
            <Grid item>
              <Grid container justifyContent={"end"}>
                {actionsList.map(
                  ({ label, action, disabled, isLoading, color }, i) => (
                    <Grid
                      item
                      key={`action-button-${label}-${i}`}
                      sx={{ margin: "0.2rem" }}
                    >
                      <Button
                        size="large"
                        onClick={action}
                        disabled={disabled}
                        isLoading={isLoading}
                        color={color || ButtonColor.primary}
                        sx={{
                          width: "17rem",
                          ...(disabled && {
                            border: "none",
                            backgroundColor: theme.palette.pending.light,
                          }),
                        }}
                      >
                        {t(label)}
                      </Button>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimerButtonContainer;
