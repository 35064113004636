import { useEffect, useRef } from "react";
import moment from "moment";

import { IngestStatus } from "services/annotation.service";
import { CardStatus } from "components/Card";

import { useResultStore } from "../result.store";
import { isValidResults } from "utils/device.utils";

export interface ResultActions {
  [key: string]: (...args: any) => void;
}

interface ResultProps {
  actions: ResultActions;
}

export const TimerController: React.FC<ResultProps> = ({ actions }) => {
  const setTimeSinceEvent = useResultStore((state) => state.setTimeSinceEvent);
  const timeSinceEvent = useResultStore((state) => state.timeSinceEvent);
  const timerStatus = useResultStore((state) => state.timerStatus);
  const stopTimer = useResultStore((state) => state.stopTimer);
  const currentEvent = useResultStore((state) => state.currentEvent);
  const ingest = useResultStore((state) => state.ingest);
  const events = useResultStore((state) => state.events);
  const validate = useResultStore((state) => state.validate);
  const setEvent = useResultStore((state) => state.setEvent);
  const updateEvent = useResultStore((state) => state.updateEvent);
  const alignEvents = useResultStore((state) => state.alignEvents);
  const isExecutedRef = useRef(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSinceEvent();
    }, 250);
    if (!timerStatus) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timerStatus]);

  useEffect(() => {
    if (!ingest) return;
    if (!currentEvent) return;

    const event = events?.find((event) => event.name === currentEvent.name);
    if (!event) {
      const createdAt = moment();
      setEvent({
        name: currentEvent.name,
        status: CardStatus.PENDING,
        createdAt: createdAt,
      });
      return;
    }

    switch (event.status) {
      case CardStatus.SUCCESS:
      case CardStatus.WARNING:
      case CardStatus.FAIL: {
        setTimeout(stopTimer, 5000);
        break;
      }
      case CardStatus.IN_PROGRESS:
      case CardStatus.PENDING: {
        break;
      }
    }

    if (!currentEvent.validate) return;

    switch (currentEvent.validate.type) {
      case "ingest": {
        switch (ingest.status) {
          case IngestStatus.COMPLETE: {
            updateEvent(currentEvent.name, CardStatus.SUCCESS);
            alignEvents();
            return;
          }
          case IngestStatus.FAILED:
          case IngestStatus.TERMINATED: {
            updateEvent(currentEvent.name, CardStatus.FAIL);
            return;
          }
          case IngestStatus.PENDING:
          case IngestStatus.IN_PROGRESS: {
            updateEvent(currentEvent.name, CardStatus.IN_PROGRESS);
            return;
          }
        }
        return;
      }
      case "validationResults": {
        if (!currentEvent.validate) {
          updateEvent(currentEvent.name, CardStatus.SUCCESS);
        }
        switch (event.status) {
          case CardStatus.PENDING: {
            validate();
            updateEvent(currentEvent.name, CardStatus.IN_PROGRESS);
            return;
          }
          case CardStatus.SUCCESS: {
            if (event.status === CardStatus.SUCCESS) return;
            updateEvent(currentEvent.name, CardStatus.SUCCESS);
            alignEvents();
            return;
          }
          case CardStatus.FAIL: {
            if (event.status === CardStatus.FAIL) return;
            updateEvent(currentEvent.name, CardStatus.FAIL);
            alignEvents();
            return;
          }
          case CardStatus.IN_PROGRESS: {
            if (!event.validation) return;
            updateEvent(
              currentEvent.name,
              event.validation.verdict ? CardStatus.SUCCESS : CardStatus.WARNING
            );
            return;
          }
        }
        return;
      }
    }
  }, [timeSinceEvent, currentEvent]);

  const executeAction = async (
    action: any,
    actions: ResultActions,
    progressStatus: CardStatus
  ) => {
    if (!action?.name || !actions[action.name]) return;
    await actions[action.name](progressStatus, action.params);
  };

  useEffect(() => {
    if (isExecutedRef.current) return;
    const executeValidateActions = async () => {
      if (!currentEvent) return;
      const event = events?.find((event) => event.name === currentEvent.name);
      if (!event) return;
      if (!event.validation) return;
      if (!event.validation?.results) return;
      if (!currentEvent.validate?.actions) return;
      if (isExecutedRef.current) return;
      const allTrueVerdict = isValidResults(
        event.validation.results,
        currentEvent
      );
      if (!allTrueVerdict) return;
      isExecutedRef.current = true;
      for (const singleAction of currentEvent.validate.actions) {
        await executeAction(singleAction, actions, event.status);
      }
    };
    executeValidateActions();
  }, [events, currentEvent]);

  return <></>;
};
