import { CardStatus } from "components/Card";
import { useResultStore } from "../result.store";
import { useTheme } from "@mui/system";
import { Grid } from "@mui/material";
import MetricComponent from "components/MetricsContainer/Metric.component";

export const Metrics = () => {
  const currentEvent = useResultStore((state) => state.currentEvent);
  const theme = useTheme();
  return (
    <Grid container spacing={2} alignItems={"center"} direction={"row"}>
      {currentEvent?.progress.cards.map((card: any, index: number) => {
        const status = card.status;
        if (status === CardStatus.SUCCESS) return null;
        return (
          <MetricComponent
            key={card.label}
            label={card.label}
            status={status}
            color={theme.palette.grey[500]}
            sx={{ marginTop: "1.2rem" }}
          />
        );
      })}
    </Grid>
  );
};
