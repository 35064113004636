import { CardStatus } from "components/Card";
import { create } from "zustand";
import { backgroundType } from "./InformationBlock.component";
import { IllustrationProps } from "components/IllustrationCard/IllustrationCard.component";

interface InformationBlockState {
  show: boolean;
  status?: CardStatus;
  illustrations?: IllustrationProps[];
  title?: string | undefined;
  subTitle?: string | undefined;
  description?: string | undefined;
  background?: backgroundType;
  init: (content: {
    show: boolean;
    status?: CardStatus;
    illustrations?: IllustrationProps[];
    title?: string | undefined;
    subTitle?: string | undefined;
    description?: string | undefined;
    background?: backgroundType;
  }) => void;
}

export const useInformationBlockStore = create<InformationBlockState>(
  (set) => ({
    show: false,
    illustrations: [],
    status: CardStatus.PENDING,
    title: "",
    subTitle: "",
    description: "",
    background: "" as backgroundType,
    init: (content) => set(() => content),
  })
);
