import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const WarningIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 47 47" sx={sx}>
      <g clipPath="url(#clip0_1463_23371)">
        <path
          d="M25.2027 10.0288C25.0895 9.82447 24.9523 9.62476 24.7913 9.45726C23.6832 8.30042 21.8094 8.56547 21.0143 10.0003C19.296 13.1017 17.5907 16.2096 15.8798 19.3148C14.145 22.4632 12.4121 25.6116 10.6754 28.7582C10.3524 29.3435 10.2419 29.95 10.4288 30.6007C10.7371 31.6784 11.6427 32.3355 12.8465 32.3364C16.2544 32.3391 19.6623 32.3373 23.0703 32.3373C26.4782 32.3373 29.8862 32.3437 33.2941 32.3281C33.6788 32.3263 34.0902 32.2655 34.4417 32.1192C35.7467 31.5744 36.2373 30.0558 35.5378 28.784C32.0968 22.5295 28.652 16.2787 25.2036 10.0288H25.2027ZM23.1052 28.7941C22.4343 28.7959 21.9134 28.2713 21.918 27.5977C21.9226 26.9415 22.4426 26.4344 23.1071 26.4371C23.766 26.439 24.2796 26.9553 24.2796 27.6152C24.2796 28.2723 23.7614 28.7922 23.1043 28.7941H23.1052ZM24.2787 22.904C24.2759 23.5096 23.8213 24.0121 23.2405 24.0701C22.6267 24.1308 22.0772 23.748 21.941 23.1562C21.9171 23.0522 21.918 22.9418 21.918 22.8341C21.9171 20.5149 21.9162 18.1957 21.918 15.8765C21.918 15.1522 22.4058 14.6387 23.0896 14.6368C23.7918 14.6341 24.2787 15.1439 24.2796 15.8884C24.2814 17.0481 24.2796 18.2077 24.2796 19.3673C24.2796 20.5462 24.2833 21.726 24.2777 22.905L24.2787 22.904Z"
        />
        <g clipPath="url(#clip1_1463_23371)">
          <path
            d="M24.8004 10.2508C24.6973 10.0631 24.5823 9.90293 24.4599 9.775C24.1 9.39951 23.6233 9.19336 23.1162 9.19336C22.4094 9.19336 21.7744 9.57805 21.4173 10.2232C20.1896 12.4384 18.9481 14.695 17.7471 16.8771C17.2593 17.7634 16.7715 18.6506 16.2828 19.5368L14.8977 22.0502C13.6249 24.3611 12.3521 26.6711 11.0775 28.9802C10.7922 29.4965 10.7269 29.9714 10.8704 30.4739C11.1254 31.3638 11.845 31.8755 12.8464 31.8765C14.3354 31.8774 15.8245 31.8783 17.3136 31.8783H23.0693C23.8469 31.8783 26.9567 31.8783 27.7334 31.8783C29.2621 31.8783 31.2748 31.8774 33.2912 31.8682C33.6538 31.8663 33.999 31.8047 34.2631 31.6942C34.7545 31.489 35.1199 31.1061 35.2911 30.6165C35.4733 30.0947 35.4172 29.5223 35.1337 29.006C32.0313 23.3672 28.651 17.2323 24.7995 10.2508H24.8004ZM23.107 29.2545H23.1015C22.6551 29.2545 22.24 29.0842 21.9327 28.7741C21.6244 28.463 21.4559 28.0442 21.4587 27.5951C21.4651 26.6877 22.1876 25.9772 23.1033 25.9772C24.0245 25.98 24.7406 26.6997 24.7406 27.6154C24.7406 28.5311 24.0227 29.2517 23.107 29.2545ZM24.7396 22.9061C24.736 23.7491 24.112 24.4467 23.2874 24.5286C23.2294 24.5342 23.1714 24.5369 23.1134 24.5369C22.3321 24.5369 21.6658 24.0114 21.4937 23.2586C21.4596 23.1086 21.4596 22.965 21.4596 22.849V22.8343C21.4587 20.5151 21.4578 18.1959 21.4596 15.8767C21.4596 14.8947 22.1453 14.1806 23.0895 14.1769C24.0485 14.1769 24.7406 14.8966 24.7415 15.8878C24.7424 16.7345 24.7415 17.5802 24.7415 18.4269V19.3666V20.424C24.7415 21.2514 24.7424 22.0787 24.7396 22.9052V22.9061Z"
          />
          <path
            d="M23.1 0C10.3425 0 0 10.3425 0 23.1C0 35.8575 10.3425 46.2 23.1 46.2C35.8575 46.2 46.2 35.8575 46.2 23.1C46.2 10.3425 35.8575 0 23.1 0ZM38.692 30.6613L38.6543 30.7488C38.6451 30.7699 38.635 30.7911 38.6239 30.8123C38.1859 33.3698 36.0774 35.1525 33.4849 35.1543C30.1358 35.1562 26.7868 35.1571 23.4378 35.1571C19.854 35.1571 16.2703 35.1562 12.6866 35.1543C10.3352 35.1534 8.2709 33.5594 7.6681 31.278C7.63128 31.1399 7.59907 31 7.56594 30.861C7.55122 30.7994 7.50888 30.6218 7.50888 30.6218V29.337L7.51992 29.2882C7.85124 27.778 8.61786 26.4895 9.35964 25.2434C9.60997 24.8228 9.86858 24.3884 10.1033 23.9568C12.2402 20.0188 14.4435 16.032 16.5749 12.1767C17.2146 11.019 17.8542 9.86214 18.4938 8.70437C19.497 6.88767 21.09 5.92685 23.1028 5.92685C23.1598 5.92685 23.2169 5.92685 23.2749 5.92869C25.1615 5.97747 26.6506 6.86466 27.582 8.49362C28.4517 10.0149 29.3066 11.5767 30.1331 13.086C30.458 13.6787 30.7828 14.2723 31.1086 14.8641C31.6001 15.7559 32.0924 16.6476 32.5848 17.5404C34.3491 20.7385 36.174 24.0452 37.9493 27.3086C38.184 27.7402 38.3313 28.2022 38.473 28.6495C38.5374 28.852 38.5982 29.0425 38.6663 29.2302L38.6939 29.3066V30.6623L38.692 30.6613Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
