import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import {
  TrialDetail,
  fetchTrialDetail,
  fetchParticipantsList,
  createParticipant as _createParticipant,
} from "services/cde.service";

import { TranslationService } from "services/i18n.service";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import { ConfigurationService } from "services/configuration.service";
import { CustomError } from "utils/privateHttp.utils";
import { SortOrder } from "constants/index";

export interface Options {
  trialId?: string;
  take?: number;
  skip?: number;
  order?: string;
  name?: string;
  pui?: string;
  participants?: boolean;
  annotations?: boolean;
  expand?: string;
}

interface TrialData extends TrialDetail {
  configuration: any;
  serialNumbers: string[];
}

function useTrialApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);
  const queryClient = useQueryClient();
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const successNotification = useSnackbarStore((state) => state.openSuccess);
  const trial = useQuery({
    queryKey: [QueryKeys.TRIAL, options.trialId],
    queryFn: async (): Promise<TrialData> => {
      try {
        const result = await fetchTrialDetail(options.trialId || "");
        if (!Object.keys(result.configuration).length) {
          throw new Error("Annotation configuration fetch failed");
        }
        const localizationsUuid = result.configuration?.data.translations;
        const locales = await ConfigurationService.fetchTranslationData(
          localizationsUuid
        );
        TranslationService.updateTranslationFromAnnotationConfig(locales);
        const serialNumbers = result.serialNumbers;
        return {
          ...result,
          serialNumbers,
        };
      } catch (error: any) {
        httpErrorNotification(error);
        throw error;
      }
    },
    enabled: options.annotations ?? !!options.trialId,
  });

  const refreshTrial = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TRIAL, options.trialId],
    });
  };

  const createParticipant = useMutation({
    mutationFn: async (data: any) => await _createParticipant(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL_PARTICIPANTS],
      });
      successNotification("participant.created.success");
    },

    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  const participants = useQuery({
    queryKey: [QueryKeys.TRIAL_PARTICIPANTS, options.trialId, options],
    queryFn: async () =>
      await fetchParticipantsList(
        options.trialId || "",
        { pui: options.pui },
        options.take || 1000,
        options.skip || 0,
        options.expand,
        options.order || `createdAt|${SortOrder.DESC}`
      ),
    enabled: options.participants ?? !!options.trialId,
  });

  return {
    trial,
    refreshTrial,
    participants,
    setOptions,
    options,
    createParticipant,
  };
}

export default useTrialApi;
