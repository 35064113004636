import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const ProgressIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 100 100" sx={{ fontSize: "1.6rem", ...sx }}>
      <path
        d="M67.2812 48.2844C64.4365 45.4664 61.7318 42.7935 59.0372 40.1103C54.8084 35.8961 50.5719 31.6896 46.366 27.4525C43.3712 24.4362 43.3864 20.8247 46.3456 17.8211C47.1191 17.0378 47.8875 16.2494 48.6763 15.4813C51.5922 12.643 55.2638 12.6405 58.1492 15.5119C64.3602 21.692 70.5508 27.895 76.749 34.0879C79.9524 37.2898 83.1737 40.4765 86.3517 43.7014C88.975 46.3642 89.0132 50.1409 86.3746 52.7859C76.922 62.2621 67.4466 71.718 57.9533 81.1535C55.2969 83.7934 51.5439 83.7629 48.862 81.1764C47.9181 80.2659 46.9868 79.3402 46.0759 78.3941C43.478 75.6982 43.4729 71.9749 46.1166 69.3197C52.7474 62.6589 59.4062 56.0235 66.0573 49.3805C66.411 49.0295 66.8003 48.7142 67.2837 48.2844H67.2812Z"
      />
      <path
        d="M29.587 48.3595C25.3658 44.158 21.4041 40.2236 17.4501 36.2815C14.4807 33.3237 11.5063 30.3684 8.55472 27.3928C5.62863 24.4426 5.64644 20.8006 8.57508 17.8555C9.38166 17.0442 10.1806 16.2278 10.9999 15.4318C13.8522 12.6596 17.5366 12.6571 20.3761 15.4776C26.6227 21.6882 32.8413 27.9243 39.0701 34.1502C42.2455 37.3217 45.4337 40.4804 48.5863 43.6748C51.2528 46.3757 51.2605 50.155 48.5684 52.8509C39.1413 62.2915 29.7015 71.7168 20.2438 81.1243C17.5391 83.8151 13.7963 83.7744 11.0661 81.1142C10.2188 80.2876 9.3664 79.4636 8.53437 78.6192C5.66425 75.7123 5.62099 72.0678 8.50129 69.1735C14.7708 62.8713 21.0733 56.5996 27.3733 50.3279C28.0298 49.6743 28.7575 49.097 29.5895 48.362L29.587 48.3595ZM15.7453 80.8395C16.7274 80.2469 17.7503 79.8654 18.4576 79.166C27.8746 69.8322 37.2483 60.4552 46.6194 51.0782C47.0011 50.6967 47.3497 50.2415 47.5787 49.7583C48.3954 48.0339 48.0036 46.7648 46.1741 44.9388C40.9631 39.7327 35.7547 34.5241 30.5386 29.3206C26.554 25.3429 22.5669 21.3652 18.5721 17.3952C16.7045 15.5386 14.6741 15.5234 12.781 17.3494C11.8676 18.2294 10.9541 19.117 10.089 20.0427C8.62597 21.6094 8.66413 23.7305 10.1501 25.2895C10.6742 25.8414 11.2187 26.3755 11.7582 26.9146C18.4118 33.5526 25.0655 40.1905 31.7192 46.8284C32.8464 47.9525 32.854 48.4663 31.7803 49.5853C31.6047 49.7684 31.4241 49.9465 31.2434 50.127C24.2666 57.0956 17.2821 64.0565 10.318 71.0403C8.55727 72.8079 8.55981 74.8679 10.2697 76.6507C11.1755 77.5968 12.0584 78.5862 13.0736 79.4025C13.7963 79.9824 14.725 80.3079 15.7402 80.8395H15.7453Z"
      />
    </SvgIcon>
  );
};
