import React, { ErrorInfo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);
  const handleReset = () => {
    window.location.reload();
  };

  const handleError = (error: Error, errorInfo: ErrorInfo) => {
    setError(error);
  };

  if (error) {
    return <ErrorFallback error={error} resetErrorBoundary={handleReset} />;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={handleReset}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
