export enum QueryKeys {
  TRIALS = "TRIALS",
  TRIAL = "TRIAL",
  TRIAL_ANALYTICS = "TRIAL_ANALYTICS",
  TOTAL_TRIAL_PARTICIPANTS = "TOTAL_TRIAL_PARTICIPANTS",
  ANNOTATIONS_ANALYTICS_BY_IDENTITY = "ANNOTATIONS_ANALYTICS_BY_IDENTITY",
  TRIAL_PARTICIPANTS = "TRIAL_PARTICIPANTS",
  PARTICIPANT_ANNOTATIONS = "PARTICIPANT_ANNOTATIONS",
  GROUP_PRODUCTS = "GROUP_PRODUCTS",
  DEVICE_STATUS = "DEVICE_STATUS",
  DEVICES_STATUS = "DEVICES_STATUS",
  ANNOTATION_CONFIGURATION = "ANNOTATION_CONFIGURATION",
  ANNOTATION = "ANNOTATION",
  INGEST = "INGEST",
  STUDIES_DASHBOARD = "STUDIES_DASHBOARD",
  STUDIES_DASHBOARD_TRIALS = "STUDIES_DASHBOARD_TRIALS",
  STUDY_OVERVIEW = "STUDY_OVERVIEW",
  STUDY_PARTICIPANTS = "STUDY_PARTICIPANTS",
  TRIAL_PARTICIPANT = "TRIAL_PARTICIPANT",
}
