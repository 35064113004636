import { Box, Grid, Skeleton } from "@mui/material";
import CardContainer from "./CardContainer";
import CardContainerHeader from "./CardContainerHeader";
import {
  StyledTrialName,
  StyledTrialDescription,
} from "features/trials/components.styles";

const LoadingTrialCard = () => {
  return (
    <CardContainer>
      <Grid container spacing={0}>
        <Grid item xs={12} md={0} sm={0}>
          <CardContainerHeader>
            <Box>
              <StyledTrialName variant="h6">
                <Skeleton
                  variant="text"
                  width={250}
                  height={30}
                  sx={{ fontSize: "1rem" }}
                />
              </StyledTrialName>
            </Box>
            <Box>
              <Skeleton variant="rectangular" width={150} height={50} />
            </Box>
          </CardContainerHeader>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12}>
          <StyledTrialDescription>
            <Skeleton variant="text" height={60} sx={{ fontSize: "1rem" }} />
          </StyledTrialDescription>
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: "2rem" }}>
        <Grid item md={6} sm={6} xs={12}>
          <Grid container>
            <Grid item xs={6} md={6} sm={6}>
              <Skeleton
                variant="text"
                height={100}
                width={120}
                sx={{ fontSize: "1rem" }}
              />
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <Skeleton
                variant="text"
                height={100}
                width={120}
                sx={{ fontSize: "1rem" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "end", sm: "end" },
              }}
            >
              <Box
                sx={{
                  marginRight: { md: "1rem", sm: "1rem", xs: "1rem" },
                  marginTop: { xs: "1rem" },
                }}
              >
                <Skeleton variant="circular" width={50} height={50} />
              </Box>
              <Box
                sx={{
                  marginRight: { md: "2rem", sm: "2rem" },
                  marginTop: { xs: "1rem" },
                  position: "relative",
                }}
              >
                <Skeleton variant="circular" width={50} height={50} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default LoadingTrialCard;
