import {
  PendingIcon,
  ProgressIconPie,
  SuccessIcon,
  TerminateIcon,
} from "components/icons";
import { TrialStatus } from "services/cde.service";

export const statusIcon: Record<TrialStatus, React.ElementType> = {
  [TrialStatus.PENDING]: PendingIcon,
  [TrialStatus.IN_PROGRESS]: ProgressIconPie,
  [TrialStatus.COMPLETE]: SuccessIcon,
  [TrialStatus.TERMINATED]: TerminateIcon,
};