import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "constants/queryKeys.constants";
import { UpdateTrialData, updateTrial } from "services/cde.service";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import useTrialApi from "hooks/useTrialApi.hook";
import { CustomError } from "utils/privateHttp.utils";

interface Options {
  trialId: string;
}

function useTrialDetailsApi(options: Options) {
  const queryClient = useQueryClient();
  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const trialQuery = useTrialApi({
    trialId: options.trialId,
    participants: false,
  });

  const updateTrialDetails = useMutation({
    mutationFn: async (data: UpdateTrialData) => await updateTrial(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL, options.trialId],
      });
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.TRIALS] });
    },
    onError: (error: CustomError) => {
      httpErrorNotification(error);
    },
  });

  return {
    trialQuery: trialQuery.trial,
    refreshTrialQuery: trialQuery.refreshTrial,
    updateTrialDetails,
  };
}

export default useTrialDetailsApi;
