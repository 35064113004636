import React, { useEffect, useState } from "react";
import GaugeLinear from "components/GaugeLinear/GaugeLinear.component";
import { useExperimentStore } from "stores/experiment.store";
import { DeviceValidate } from "services/annotation.service";

interface GaugeLinearProps {
  metric: DeviceValidate;
}

const GaugeLinearController: React.FC<GaugeLinearProps> = ({ metric }) => {
  const { validation } = useExperimentStore((state) => ({
    validation: state.validation,
  }));

  const [currentValue, setCurrentValue] = useState<number>(0);

  useEffect(() => {
    if (!validation?.results) {
      setCurrentValue(0);
      return;
    }

    const sum = metric.params.reduce((acc, param) => {
      const result = validation?.results?.find(
        (item) => item.name === metric.title
      );
      if (result) {
        const data = result.results[param];
        const value = data?.value;
        switch (data?.comparisonType) {
          case "MIN_MAX_ARRAY":
            if (Array.isArray(value)) {
              return (
                acc +
                value.reduce((acc, val) => acc + Number(val), 0) / value.length
              );
            }
            break;
          case "MIN_MAX":
            return acc + Number(value);
          default:
            return acc;
        }
      }
      return acc;
    }, 0);

    setCurrentValue(sum);
  }, [metric, validation]);

  if (!validation || !metric?.options) return null;

  return <GaugeLinear currentValue={currentValue} options={metric.options} />;
};

export default GaugeLinearController;
