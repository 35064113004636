import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FieldConfig } from "utils/form.utils";

import FormContainer from "components/FormContainer";

import useTrialApi from "hooks/useTrialApi.hook";
import DialogBox from "components/DialogBox/DialogBox.component";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";

interface ExperimentFormModalProps {
  isModalOpen: any;
  onSubmit: (value: any) => void;
  onClose: () => void;
}

const ExperimentFormModal: React.FC<ExperimentFormModalProps> = ({
  isModalOpen,
  onSubmit,
  onClose,
}) => {
  const { trialId } = useParams();
  const { t } = useTranslation();
  const queries = useTrialApi({ trialId: trialId || "" });
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);

  const schemaData: FieldConfig[] =
    queries.trial.data?.configuration?.data.forms.experiment.fields;

  const initialValues = queries.trial.isFetched
    ? schemaData.reduce((acc, { name }) => ({ ...acc, [name]: "" }), {})
    : {};

  const handleOnSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    setSubmitting(true);
    onSubmit(values);
    resetForm();
    setSubmitting(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      const descriptionElement = (
        <FormContainer
          schemaData={schemaData}
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onClose={onClose}
          labels={{
            preSubmit: t("trial.noze.experimentFormFields.button.label"),
            postSubmit: t("trial.noze.experimentFormFields.button.label"),
          }}
        />
      );

      openDialogBox("experiment", {
        title: "",
        description: descriptionElement,
        showCloseButton: false,
      });
    } else {
      closeDialogBox("experiment");
    }
  }, [isModalOpen]);

  return (
    <DialogBox
      dialogKey="experiment"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        margins: 0,
      }}
    />
  );
};

export default ExperimentFormModal;
