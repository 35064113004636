import { CardStatus } from "components/Card";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/system";
import { Grid } from "@mui/material";
import Button, { ButtonColor } from "components/Button";
import { useTranslation } from "react-i18next";
import { Timer } from "./Timer.component";
import { usePurgeStore } from "../purge.store";
import { ProgressBarStatus } from "stores/header.store";
import { hasControlsState, isValidResults } from "utils/device.utils";

export interface PurgeActions {
  [key: string]: (...args: any) => void;
}
interface PergeProps {
  actions: PurgeActions;
  loading: boolean;
}

const TimerButtonContainer: React.FC<PergeProps> = ({ actions, loading }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const status = usePurgeStore((state) => state.status);
  const currentEvent = usePurgeStore((state) => state.currentEvent);
  const validation = usePurgeStore((state) => state.validation);
  const purgeEvent = usePurgeStore((state) => state.purgeEvent);
  const timeSinceEvent = usePurgeStore((state) => state.timeSinceEvent);
  const [progressStatus, setProgressStatus] = useState(CardStatus.PENDING);
  const isExecutedRef = useRef(false);

  const getActionButtons = (status: CardStatus) => {
    if (!currentEvent?.actions) return [];
    const isTimeoutExceeded = timeSinceEvent > currentEvent?.timer.timeout;

    if (status === CardStatus.WARNING && !isTimeoutExceeded) return [];
    return currentEvent.actions
      .filter((action) => action.showInStatuses.includes(status))
      .map((action) => ({
        action: async () => {
          for (const subAction of action.actions) {
            if (typeof subAction === "object") {
              const { name, params } = subAction;
              await actions[name](status, params);
            } else {
              await actions[subAction](status);
            }
          }
        },
        disabled: false,
        label: action.label,
        color: action.color as ButtonColor,
        isLoading: loading,
      }));
  };

  const getTimer = (status: CardStatus) => {
    if (!currentEvent) return [];
    if (!currentEvent.timer) return [];
    const timerConfig = currentEvent.timer.actions.reduce(
      (
        acc: any[],
        action: {
          actions: string[];
          statuses: CardStatus[];
          time: number;
        }
      ) => {
        if (action.statuses.includes(status)) {
          acc.push({
            action: () => {
              action.actions.forEach((subAction: any) => {
                actions[subAction](status);
              });
            },
            time: action.time,
            direction: currentEvent.timer.direction,
            isVisible: currentEvent.timer.isVisible ?? true,
          });
        }
        return acc;
      },
      []
    );
    return timerConfig;
  };

  useEffect(() => {
    if (!currentEvent) return;
    if (!currentEvent.status) return;
    setProgressStatus(currentEvent?.status as any);
  }, [currentEvent]);

  const actionsList = getActionButtons(
    (purgeEvent?.status as CardStatus) || CardStatus.IN_PROGRESS
  );

  const executeAction = async (
    action: any,
    actions: PurgeActions,
    progressStatus: CardStatus
  ) => {
    if (!action?.name || !actions[action.name]) return;
    await actions[action.name](progressStatus, action.params);
  };

  const timerActions = getTimer(progressStatus);

  useEffect(() => {
    if (isExecutedRef.current) return;
    const executeValidateActions = async () => {
      if (!validation?.results) return;
      if (!currentEvent) return;
      if (!currentEvent.validate?.actions) return;
      if (isExecutedRef.current) return;
      const controlsState = hasControlsState(validation);
      if (controlsState) return;
      const allTrueVerdict = isValidResults(validation.results, currentEvent);
      if (!allTrueVerdict) return;
      isExecutedRef.current = true;
      for (const singleAction of currentEvent.validate.actions) {
        await executeAction(singleAction, actions, progressStatus);
      }
    };
    executeValidateActions();
  }, [validation]);

  return (
    <Grid container spacing={2} direction={"column"} alignItems={"end"}>
      {timerActions.length > 0 &&
        status === ProgressBarStatus.IN_PROGRESS &&
        purgeEvent?.status !== CardStatus.FAIL && (
          <Grid item sx={{ color: theme.palette.text.secondary }}>
            {timerActions?.map((data: any, i: number) => (
              <Timer
                key={`timer-${i}`}
                direction={data.direction}
                time={data.time}
                sx={{ display: data.isVisible ? "block" : "none" }}
              />
            ))}
          </Grid>
        )}
      {actionsList.length > 0 && (
        <Grid item>
          <Grid container>
            {actionsList.map(
              ({ label, action, disabled, isLoading, color }, i) => (
                <Grid
                  item
                  key={`action-button-${label}-${i}`}
                  sx={{ margin: "0.2rem" }}
                >
                  <Button
                    size="large"
                    onClick={action}
                    disabled={disabled}
                    isLoading={isLoading}
                    color={color || ButtonColor.primary}
                    sx={{
                      width: "17rem",
                      ...(disabled && {
                        border: "none",
                        backgroundColor: theme.palette.pending.light,
                      }),
                    }}
                  >
                    {t(label)}
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TimerButtonContainer;
