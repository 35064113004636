import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const Alveolar: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 15 10" sx={sx}>
      <g clipPath="url(#clip0_171_22743)">
        <path d="M11.3929 2.61838C10.9273 1.44399 9.76406 0.632324 8.46663 0.632324C6.90869 0.632324 5.62008 1.74319 5.36869 3.22866L1.02101 4.93114C0.801146 5.06968 0.667969 5.31042 0.667969 5.56922C0.667969 5.82801 0.801146 6.06875 1.02101 6.20729L5.36869 7.90977C5.62015 9.39524 6.90869 10.5061 8.46663 10.5061C9.76406 10.5061 10.9273 9.69444 11.3929 8.52005C12.638 8.08955 13.4999 6.91508 13.4999 5.56929C13.4999 4.22342 12.638 3.04887 11.3929 2.61838ZM10.6543 7.1559C10.3547 7.21089 10.1171 7.43856 10.0508 7.7343C9.88691 8.4649 9.22072 8.99526 8.46671 8.99526C7.58863 8.99526 6.87626 8.31216 6.8448 7.44015C6.83584 7.1911 6.70395 6.96252 6.49222 6.82912L2.84689 5.56922L6.49222 4.30931C6.70388 4.17591 6.83576 3.94733 6.8448 3.69828C6.87626 2.82626 7.58863 2.14317 8.46671 2.14317C9.22064 2.14317 9.88683 2.67345 10.0508 3.40406C10.1171 3.69979 10.3547 3.92746 10.6543 3.98245C11.4228 4.12364 11.9806 4.79094 11.9806 5.56922C11.9806 6.34749 11.4227 7.01479 10.6543 7.1559Z" />
      </g>
    </SvgIcon>
  );
};
