import { useState } from "react";
import StudiesListActionsHandler from "./studiesListActionsHandler.component";
import { SortOrder } from "constants/index";
import { TrialsSortingState } from "services/cde.service";
import { QueryObserverResult } from "@tanstack/react-query";

interface ManageStudiesProps {
  queries: {
    query: QueryObserverResult<any, unknown>;
    updateTrialStatus: {
      mutate: (args: {
        uuid: string;
        name: string;
        description: string;
        preferences: any;
        status: string;
      }) => void;
    };
  };
  handleSorting: (order: string) => void;
}

const StudiesList: React.FC<ManageStudiesProps> = ({
  queries,
  handleSorting,
}) => {
  const [sortable, setSortable] = useState<TrialsSortingState>({
    name: SortOrder.DESC,
  });
  const studies = queries.query.data?.data;

  return (
    <StudiesListActionsHandler
      queries={queries}
      studies={studies}
      handleSorting={handleSorting}
      sortable={sortable}
      setSortable={setSortable}
    />
  );
};

export default StudiesList;
