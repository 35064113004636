import React, { ReactNode } from "react";
import { StyledCardPaper } from "features/trials/components.styles";
import { Grid } from "@mui/material";

interface CardContainerProps {
  flexBasis?: string | number;
  maxWidth?: string | number;
  children: ReactNode;
}

const CardContainer: React.FC<CardContainerProps> = ({
  flexBasis,
  maxWidth,
  children,
}) => {
  return (
    <Grid item flexGrow={1} flexBasis={flexBasis || "50%"} sx={{ maxWidth: maxWidth || "640px" }}>
      <StyledCardPaper variant="outlined">{children}</StyledCardPaper>
    </Grid>
  );
};

export default CardContainer;
