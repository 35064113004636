import { SortOrder } from "constants/index";
import { FormikFormProps } from "formik";
import { Options } from "hooks/useTrialApi.hook";
import { UseMutationResult } from "@tanstack/react-query";

interface Queries {
  options: Options;
  setOptions: (options: Options) => void;
  createParticipant: UseMutationResult<void, unknown, any, unknown>;
}

export const useHelpers = (queries: Queries, trialId: string) => {
  const createNewParticipant = async (values: FormikFormProps) => {
    const payload = { trialId: trialId, ...values };
    await queries.createParticipant.mutateAsync(payload);
    const options = {
      ...queries.options,
      skip: 0,
      order: `createdAt|${SortOrder.DESC}`,
      participants: true,
    };
    queries.setOptions(options);
  };

  const handleSorting = async (order: string) => {
    const options = {
      ...queries.options,
      skip: 0,
      order,
    };
    queries.setOptions(options);
  };

  return { createNewParticipant, handleSorting };
};
