export const ROUTES = {
  ROOT: "/",
  TRIALS_MANAGEMENT: "/trials-management",
  TRIAL_DETAILS: "/trials-management/:trialId",
  TRIAL_PARTICIPANTS: "/trials-management/:trialId/participants",
  TRIAL_PARTICIPANT_DETAILS:
    "/trials-management/:trialId/participants/:trialMemberId",
  TRIAL_PARTICIPANT_DETAILS_SUMMARY:
    "/trials-management/:trialId/participants/:pui/summary",
  TRIAL_PARTICIPANT_DEVICES:
    "/trials-management/:trialId/participants/:trialMemberId/devices",
  DEVICES_PURGE: "/trials-management/:trialId/devices/:serialNumber/purge/",
  TRIAL_PARTICIPANT_EXPERIMENT:
    "/trials-management/:trialId/participants/:trialMemberId/experiments",
  TRIAL_PARTICIPANT_EXPERIMENT_RESULTS:
    "/trials-management/:trialId/participants/:trialMemberId/:annotationId/results/:ingestId",
  TRIAL_PARTICIPANT_EXPERIMENT_RESULTS_DETAILS:
    "/trials-management/:trialId/participants/:trialMemberId/:annotationId/results/:serialNumber",
  STUDIES_DASHBOARD: "/studies-dashboard",
  STUDIES_DASHBOARD_TRIAL_DETAILS: "/studies-dashboard/:trialId/details",
  STUDIES_DASHBOARD_STUDY_OVERVIEW: "/studies-dashboard/:trialId/overview",
  STUDIES_DASHBOARD_STUDY_PARTICIPANTS:
    "/studies-dashboard/:trialId/participants",
  STUDIES_DASHBOARD_STUDY_DEVICES: "/studies-dashboard/:trialId/devices",
  PARTICIPANT_DASHBOARD:
    "/participant-dashboard/:trialId/participants/:trialMemberId/participant",
};
