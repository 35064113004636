import React, { ReactNode } from "react";
import { IconButton as MuiIconButton } from "@mui/material";
import { Theme } from "@mui/system";

interface IconButtonProps {
  children?: ReactNode;
  [key: string]: any;
}

const getSize = (theme: Theme, size: string) => {
  switch (size) {
    case "small":
      return theme.spacing(5);
    case "medium":
      return theme.spacing(6);
    case "large":
      return theme.spacing(10);
    default:
      return theme.spacing(6);
  }
};

const IconButton: React.FC<IconButtonProps> = ({ children, ...rest }) => {
  return (
    <MuiIconButton
      sx={(theme) => ({
        padding: theme.spacing(1),
        backgroundColor: theme.palette.grey[100],
        fill: theme.palette.grey[100],
        fontSize: theme.spacing(6),
        color: theme.palette.grey[100],
        height: getSize(theme, rest.size),
        width: getSize(theme, rest.size),
      })}
      {...rest}
    >
      {children}
    </MuiIconButton>
  );
};

export default IconButton;
