import React, { CSSProperties } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { containerStyle } from "constants/index";
import { useTheme } from "@mui/system";

interface CommonLayoutProps {
  children: React.ReactNode;
  sx?: CSSProperties;
}

const FullWidthCardContainer: React.FC<CommonLayoutProps> = ({
  children,
  sx,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.pending.gradient,
        padding: "2.5rem",
        ...sx,
      }}
    >
      <Grid
        container
        display={"flex"}
        flexDirection="column"
        gap={"2rem"}
        sx={containerStyle}
      >
        {children}
      </Grid>
    </Box>
  );
};

export default FullWidthCardContainer;
