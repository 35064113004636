import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

interface NotFoundSearchProps {
  message: string;
  data: { key: string; value: any };
}
const NotFoundSearch: React.FC<NotFoundSearchProps> = ({ message, data }) => {
  const { t } = useTranslation();
  const value = data.value.includes(",")
    ? data.value.split(",").join(", ")
    : data.value;
  return (
    <Grid
      item
      flexGrow={1}
      display={"flex"}
      justifyContent={"center"}
      sx={{ margin: "0.75rem" }}
    >
      {t(message).replace(data.key, t(value))}
    </Grid>
  );
};

export default NotFoundSearch;
