import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const TerminateIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 46 47" sx={{ fontSize: "1.6rem", ...sx }}>
      <path d="M22.9993 0.662109C10.2825 0.662109 0 10.9431 0 23.6614C0 36.3796 10.2795 46.6621 22.9993 46.6621C35.7175 46.6621 46 36.3826 46 23.6614C46 10.9402 35.7175 0.662109 22.9993 0.662109ZM34.4996 31.9196L31.256 35.1617L22.9993 26.905L14.7425 35.1617L11.5004 31.9196L19.7571 23.6614L11.5004 15.4047L14.7425 12.161L22.9993 20.4192L31.256 12.161L34.4996 15.4047L26.2444 23.6614L34.4996 31.9196Z" />
    </SvgIcon>
  );
};
