import { get, baseURL } from "utils/privateHttp.utils";

export interface Storage {
  cid: string;
  uuid: string;
  fileName: string;
  signedUrl: string;
  createdAt: string;
  createdBy: string;
}

export const getStorageDetail = async (storageId: string): Promise<Storage> => {
  const url = new URL(
    "/analytics-service/analytics-service/rest/v1/storage/storageId",
    baseURL
  );
  url.searchParams.append("storageId", storageId);
  url.searchParams.append("timeout", "1800");

  return await get(url.toString());
};
