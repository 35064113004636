import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAssetsPath } from "utils/assets.utils";
import { styled, useTheme } from "@mui/material/styles";
import DataCard from "features/trials/components/experiment/summary/DataCard";
import moment from "moment";
import CustomDrawer from "components/CustomDrawer";
import SkeletonExperimentSummaryContainer from "components/SkeletonExperimentSummaryContainer.component";
import useParticipantExperimentSummaryApi from "./useParticipantExperimentSummaryApi.hook";

const findParticipantId = (pui: string | undefined, annotation: any) => {
  if (!pui || !annotation) return undefined;
  const identityDetail = annotation?.identities?.find(
    (identity: { type: string; metadata: { pui: string } }) =>
      identity.type === "PARTICIPANT"
  );
  return identityDetail?.reference;
};

interface IsOpenProps {
  isOpen: boolean;
  annotation: any;
  onClose: () => void;
}
const ParticipantExperimentSummary: React.FC<IsOpenProps> = ({
  isOpen,
  annotation,
  onClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { pui } = useParams();
  const { t } = useTranslation();
  const participantId = findParticipantId(pui, annotation);
  const queries = useParticipantExperimentSummaryApi({
    participantId,
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  return (
    <CustomDrawer
      open={isOpen}
      onClose={onClose}
      additionalProps={{
        id: "participant-details",
      }}
    >
      <Grid container sx={{ margin: "0 0 24px 0" }}>
        <Grid item xs={11.2} md={12} sm={11.3}>
          <DrawerHeader sx={{ border: "none" }}>
            <Typography
              sx={{
                margin: {
                  md: "2rem 0 1.8rem 1rem",
                  xs: "1.2rem 0 1.2rem 1.2rem",
                },
              }}
            >
              <img
                style={{ width: "38px" }}
                onClick={onClose}
                alt=""
                src={getAssetsPath("icons/back.svg")}
              />
            </Typography>
            <Grid
              container
              sx={{
                marginLeft: {
                  md: "1rem",
                  xs: "1.2rem",
                },
              }}
            >
              <Grid item xs={6} md={9} sm={9}>
                <Typography
                  sx={{
                    fontSize: { md: "24px", xs: "20px" },
                    fontWeight: "600",
                    lineHeight: "36px",
                  }}
                >
                  {t("summary")}
                </Typography>
              </Grid>
            </Grid>
          </DrawerHeader>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ margin: "24px 20px 24px 20px" }}>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          sx={{ fontSize: "1rem", fontWeight: "400" }}
        >
          <Typography>Participant: {annotation?.metadata.pui}</Typography>
        </Grid>

        <Grid item xs={12} md={6} sm={6}>
          <Typography>
            {`${t("Started")}: ${moment(annotation?.createdAt).format(
              "DD MMM YYYY hh:mm A"
            )}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <Typography>
            {`${t("Completed")}: ${moment(annotation?.completedAt).format(
              "DD MMM YYYY hh:mm A"
            )}`}
          </Typography>
        </Grid>
        <Grid item xs={10} md={6} sm={6}></Grid>
        <Grid item xs={10} md={11} sm={11}>
          <Typography
            component={"div"}
            sx={{
              fontSize: { md: "1.5rem", xs: "1.2rem" },
              display: "flex",
              justifyContent: "center",
              padding: "1rem",
              borderRadius: "0.8rem",
            }}
          >
            {annotation?.status === "COMPLETE"
              ? t("experiment_success")
              : t("experiment_fail")}
          </Typography>
        </Grid>
        {queries.annotationAnalyticsQuery.isFetched ? (
          <Grid item xs={10} md={11} sm={11}>
            <Grid container spacing={isMobile ? 2 : 5}>
              <Grid item xs={12} md={12} sm={12} sx={{ marginTop: "20px" }}>
                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                  {t("participant_analytics")}
                </Typography>
              </Grid>
              <DataCard
                image={"icons/execution.svg"}
                result={
                  queries.annotationAnalyticsQuery.data?.annotations?.total
                }
                resultType={t("total_experiment")}
              />
              <DataCard
                image={"icons/execution.svg"}
                result={
                  queries.annotationAnalyticsQuery.data?.annotations?.complete
                }
                resultType={t("Completed")}
              />
              <DataCard
                image={"icons/result.svg"}
                result={`${queries.annotationAnalyticsQuery.data?.annotations?.pending}/${queries.annotationAnalyticsQuery.data?.annotations?.terminated}`}
                resultType={t("pending_terminated")}
              />
            </Grid>
          </Grid>
        ) : (
          <SkeletonExperimentSummaryContainer />
        )}
      </Grid>
    </CustomDrawer>
  );
};

export default ParticipantExperimentSummary;
