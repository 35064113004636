import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAssetsPath } from "utils/assets.utils";
import CustomPopoverWrapper from "components/CustomPopoverWrapper";
import { TrialStatus, TrialStatusActions } from "services/cde.service";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import { useState } from "react";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";
import DialogBox, {
  ButtonTypes,
} from "components/DialogBox/DialogBox.component";
import React from "react";
import {
  StyledPopoverBox,
  StyledPopoverItem,
} from "features/trials/components.styles";

interface EditPopoverProps {
  trialStatus: string;
  open: boolean;
  trialId: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  handleDetailClickOpen: (uuid: string) => void;
  handleUpdateStatus: (status: string) => void;
  handleParticipantClickOpen: (uuid: string) => void;
}

const TrialPopover: React.FC<EditPopoverProps> = ({
  trialStatus,
  open,
  anchorEl,
  trialId,
  onClose,
  handleDetailClickOpen,
  handleUpdateStatus,
  handleParticipantClickOpen,
}) => {
  const { t } = useTranslation();
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);
  const [status, setStatus] = useState("");

  const handleClickOpen = async (key: string) => {
    let title = "";
    let message = "";
    const allowedKeys: TrialStatusActions[] = [
      TrialStatusActions.COMPLETE,
      TrialStatusActions.TERMINATED,
      TrialStatusActions.ACTIVATE,
    ];

    if (allowedKeys.includes(key as TrialStatusActions)) {
      setStatus(key);
      switch (key) {
        case TrialStatusActions.COMPLETE:
          title = "trials.list.dialog.complete.title";
          message = "trials.list.dialog.complete.description";
          break;
        case TrialStatusActions.ACTIVATE:
          title = "trials.list.dialog.activate.title";
          message = "trials.list.dialog.activate.description";
          break;
        default:
          title = "trials.list.dialog.terminate.title";
          message = "trials.list.dialog.terminate.description";
          break;
      }
      openDialogBox(`trialTerminateOrComplete_${trialId}`, {
        title: title,
        description: message,
        showCloseButton: false,
        buttons: [
          {
            label: "trials.list.dialog.terminate.cancel.button.label",
            action: "handleCloseDialogBox",
            type: ButtonTypes.SECONDARY,
          },
          {
            label: "trials.list.dialog.terminate.apply.button.label",
            action: "handleConfirmClick",
            type: ButtonTypes.PRIMARY,
          },
        ],
      });
    } else {
      handleDetailClickOpen(trialId);
    }
  };

  const handleConfirmClick = () => {
    handleUpdateStatus(status);
    closeDialogBox(`trialTerminateOrComplete_${trialId}`);
  };

  const handleParticipantClick = () => {
    handleParticipantClickOpen(trialId);
  };

  const handleCloseDialogBox = async () => {
    closeDialogBox(`trialTerminateOrComplete_${trialId}`);
  };

  const actions = {
    handleCloseDialogBox: handleCloseDialogBox,
    handleConfirmClick: handleConfirmClick,
  };

  return (
    <>
      <CustomPopoverWrapper
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        applyZIndex={false}
      >
        <StyledPopoverBox>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <StyledPopoverItem
                onClick={() => {
                  handleClickOpen("view_trail_details");
                  onClose();
                }}
              >
                <img
                  style={{ marginRight: "1rem" }}
                  src={getAssetsPath("icons/file-icons_test-generic.svg")}
                  alt="Icon"
                />{" "}
                {t("view_trail_details")}
              </StyledPopoverItem>
            </Grid>
            {trialStatus !== TrialStatus.TERMINATED &&
            trialStatus !== TrialStatus.COMPLETE ? (
              <Grid item xs={12} md={12} sm={12}>
                <StyledPopoverItem
                  onClick={() => {
                    handleParticipantClick();
                    onClose();
                  }}
                >
                  <img
                    style={{ marginRight: "1rem" }}
                    src={getAssetsPath("icons/ic_person.svg")}
                    alt="Icon"
                  />{" "}
                  {t("manage_participants")}
                </StyledPopoverItem>
              </Grid>
            ) : null}
            {trialStatus !== TrialStatus.TERMINATED &&
            trialStatus !== TrialStatus.COMPLETE ? (
              <>
                <Grid item xs={12} md={12} sm={12}>
                  <StyledPopoverItem
                    onClick={() => {
                      handleClickOpen(TrialStatus.COMPLETE);
                      onClose();
                    }}
                  >
                    <img
                      style={{ marginRight: "1rem" }}
                      src={getAssetsPath("icons/trial_update_line.svg")}
                      alt="Icon"
                    />

                    {t("complete")}
                  </StyledPopoverItem>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <StyledPopoverItem
                    onClick={() => {
                      handleClickOpen(TrialStatus.TERMINATED);
                      onClose();
                    }}
                  >
                    <img
                      style={{ marginRight: "1rem" }}
                      src={getAssetsPath(
                        "icons/ic_diagonal_arrow_right_up.svg"
                      )}
                      alt="Icon"
                    />
                    {t("terminate")}
                  </StyledPopoverItem>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={12} sm={12}>
                <StyledPopoverItem
                  onClick={() => {
                    handleClickOpen(TrialStatusActions.ACTIVATE);
                    onClose();
                  }}
                >
                  <ToggleOnOutlinedIcon style={{ marginRight: "1rem" }} />
                  {t("activate")}
                </StyledPopoverItem>
              </Grid>
            )}
          </Grid>
        </StyledPopoverBox>
      </CustomPopoverWrapper>
      <DialogBox
        dialogKey={`trialTerminateOrComplete_${trialId}`}
        actions={actions}
      />
    </>
  );
};

export default TrialPopover;
