import { CardStatus } from "components/Card";
import InformationBlock, { backgroundType } from "components/InformationBlock";
import { useEffect, useState } from "react";
import { useExperimentStore } from "stores/experiment.store";
import { useInformationBlockStore } from "components/InformationBlock/informationblock.store";

interface IllustrationProps {
  url: string;
  alt: string;
  timeout?: number;
}
interface InformationProps {
  illustrations: IllustrationProps[];
}

const Information: React.FC<InformationProps> = ({ illustrations }) => {
  const [progressStatus, setProgressStatus] = useState(CardStatus.PENDING);
  const currentEvent = useExperimentStore((state) => state.currentEvent);
  const validation = useExperimentStore((state) => state.validation);
  const setInformationBlock = useInformationBlockStore((state) => state.init);
  const failedStep = useExperimentStore((state) => state.failedStep);

  useEffect(() => {
    if (!currentEvent) return;
    setProgressStatus(evaluateCurrentStatus());
    const content = getCardContent(progressStatus);
    setInformationBlock({
      show: true,
      status: progressStatus || CardStatus.INFO,
      illustrations: illustrations as any,
      title: content.title,
      description: content.description,
      background: backgroundType.GRADIENT,
    });
  }, [failedStep, progressStatus, validation, currentEvent]);

  const evaluateCurrentStatus = () => {
    let status = CardStatus.PENDING;
    if (!currentEvent) return status;
    if (!validation.results) return status;
    if (failedStep?.toLowerCase() === currentEvent.name.toLowerCase()) {
      status = CardStatus.FAIL;
    } else if (!validation) {
      status = CardStatus.SUCCESS;
    } else if (validation.verdict) {
      status = CardStatus.SUCCESS;
    } else {
      status = CardStatus.WARNING;
    }
    return status;
  };

  type CardContent = {
    title: string | undefined;
    description: string | undefined;
  };

  const getCardContent = (status: CardStatus): CardContent => {
    switch (status) {
      case CardStatus.SUCCESS:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.success,
        };
      case CardStatus.FAIL:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.fail,
        };
      case CardStatus.WARNING:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.warning,
        };
      default:
        return {
          title: currentEvent?.content?.title,
          description: currentEvent?.content?.description.pending,
        };
    }
  };
  return <InformationBlock />;
};

export default Information;
