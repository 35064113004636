export const BrowserRefreshHandler = (customMessage: string) => {
  const handleBrowserRefresh = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    const message = customMessage || "";
    e.returnValue = message;
    return message;
  };

  return handleBrowserRefresh;
};
