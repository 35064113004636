import * as React from "react";
import NotFoundSearch from "components/NotFoundSearch";
import { useEffect, useState } from "react";
import { SortOrder, participantPerPage } from "constants/index";
import { Paginator } from "components/Paginator.component";
import Loading from "components/Loading";
import { ParticipantsSortingState } from "services/cde.service";
import ParticipantsListActionsHandler from "./ParticipantsListActionsHandler.component";

interface ManageParticipantsProps {
  queries: Record<string, any>;
  handleDeleteParticipant: (participant: any) => void;
  showStatus?: boolean | false;
  handleSorting: (order: string) => void;
}

const ParticipantsList: React.FC<ManageParticipantsProps> = ({
  queries,
  handleDeleteParticipant,
  showStatus,
  handleSorting,
}) => {
  const [sortable, setSetsortable] = useState<ParticipantsSortingState>({
    pui: SortOrder.DESC,
    createdAt: SortOrder.DESC,
  });

  useEffect(() => {
    const [fieldName, sortOrder] = queries.options.order.split("|");
    sortable[fieldName as keyof ParticipantsSortingState] = sortOrder;
    setSetsortable(sortable);
  }, [queries.options.order]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
    recordPerPage?: number
  ) => {
    const perPageRecords = recordPerPage || participantPerPage;
    const options = {
      ...queries.options,
      take: perPageRecords,
      skip: (page - 1) * perPageRecords,
    };
    queries.setOptions(options);
  };

  const totalRecords = queries.participants?.data?.total;
  const currentPage = (queries.options.skip || 0) / queries.options.take + 1;
  const totalPages = Math.ceil((totalRecords || 0) / queries.options.take) || 0;

  useEffect(() => {
    const [fieldName, sortOrder] = queries.options.order.split("|");
    sortable[fieldName as keyof ParticipantsSortingState] = sortOrder;
    setSetsortable(sortable);
  }, [queries.options.order]);

  return (
    <>
      {queries.participants.isFetched ? (
        queries.participants?.data?.data.length ? (
          <>
            <ParticipantsListActionsHandler
              queries={queries}
              handleSorting={handleSorting}
              sortable={sortable}
              handleDeleteParticipant={handleDeleteParticipant}
              showStatus={showStatus}
            />
            <Paginator
              showRecordsPerPage={true}
              recordPerPage={queries.options.take}
              total={totalPages}
              page={currentPage}
              show={true}
              onChange={handlePageChange}
            />
          </>
        ) : (
          <NotFoundSearch
            message="trialParticipant.participants.list.noParticipants"
            data={{ key: "", value: "" }}
          />
        )
      ) : (
        <Loading height="50vh" />
      )}
    </>
  );
};

export default ParticipantsList;
