import { TrialStatus } from "services/cde.service";
import { create } from "zustand";
import React from "react";

interface NavigationOption {
  key: TrialStatus;
  value: string;
  icon: React.ElementType;
  color: Record<string, string> & { mode: "light" | "dark" };
  totalCount: number;
  defaultSelected: boolean;
  disabled?: boolean;
}

interface NavigationOptionsStore {
  navigationOptions: NavigationOption[];
  setNavigationOptions: (newData: NavigationOption[]) => void;
}

const useNavigationOptionsStore = create<NavigationOptionsStore>((set) => ({
  navigationOptions: [],
  setNavigationOptions: (newData) => set({ navigationOptions: newData }),
}));

export default useNavigationOptionsStore;
