import React, { ReactNode } from "react";
import { StyledCardHeader } from "features/trials/components.styles";

interface CardContainerProps {
  children: ReactNode;
}

const CardContainerHeader: React.FC<CardContainerProps> = ({ children }) => {
  return <StyledCardHeader>{children}</StyledCardHeader>;
};

export default CardContainerHeader;
