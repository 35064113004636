import React from "react";
import { Popover, Box, useMediaQuery, useTheme } from "@mui/material";

interface CustomPopoverWrapperProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  children: React.ReactNode;
  applyZIndex: boolean;
}

const CustomPopoverWrapper: React.FC<CustomPopoverWrapperProps> = ({
  open,
  onClose,
  anchorEl,
  children,
  applyZIndex,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={(theme) => ({
        width: "auto",
        zIndex: applyZIndex ? 1400 : null,
        top: isMobile
          ? `calc(${theme.spacing(3)})`
          : `calc(${theme.spacing(3)} - 3rem)`,
        left: `calc(${theme.spacing(3)})`,
      })}
      PaperProps={{
        style: {
          borderRadius: "1rem",
        },
      }}
      anchorOrigin={
        isMobile
          ? { vertical: "top", horizontal: "left" }
          : { vertical: "bottom", horizontal: "left" }
      }
      transformOrigin={
        isMobile
          ? { vertical: "bottom", horizontal: "right" }
          : { vertical: "top", horizontal: "right" }
      }
    >
      <Box sx={{ borderRadius: "8px" }}>{children}</Box>
    </Popover>
  );
};

export default CustomPopoverWrapper;
