import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css, styled, useTheme } from "@mui/system";
import { Link, Grid } from "@mui/material";
import { ProgressBarStatus, useHeaderStore } from "stores/header.store";
import { StyledBreadcrumbs } from "./Breadcrumb.styles";
import Separator from "./Separator";

const Breadcrumb = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { steps, activeStep, allowAction, onClick } = useHeaderStore(
    (state) => ({
      steps: state.steps,
      activeStep: state.activeStep,
      allowAction: state.allowAction,
      onClick: state.onClick,
    })
  );

  const handleClick = React.useCallback(
    (step: any) => () => {
      if (!allowAction || !step.enableLink || !step.link) return;
      onClick ? onClick(step.link, step.label) : navigate(step.link);
    },
    [allowAction, onClick, navigate]
  );

  const StyledLink = styled(Link)(({ theme, ...props }) => {
    let color;
    switch (props.className) {
      case ProgressBarStatus.FAIL:
        color = theme.palette.error.main;
        break;
      case ProgressBarStatus.WARNING:
        color = theme.palette.warning.main;
        break;
      default:
        color = props.color;
    }

    return {
      ...css({ color }),
    };
  });

  return (
    <StyledBreadcrumbs separator="">
      {steps.map((step, index) => (
        <Grid key={step.label} container alignItems="start">
          <StyledLink
            className={step.status}
            onClick={handleClick(step)}
            color={
              activeStep >= index
                ? theme.palette.grey[700]
                : theme.palette.grey[400]
            }
            sx={{
              cursor: step.enableLink && allowAction ? "pointer" : "default",
              textDecoration: "none",
              fontWeight: activeStep === index ? "bold" : "normal",
            }}
          >
            {t(step.label)}
          </StyledLink>
          {index < steps.length - 1 && (
            <Separator activeStep={activeStep === index} />
          )}
        </Grid>
      ))}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumb;
