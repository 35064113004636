import React from "react";
import { Box, SliderThumb, Grid } from "@mui/material";
import { useTheme } from "@mui/system";
import SvgImage from "components/SvgImage.component";
import {
  GaugeSliderStyle,
  VisualSliderStyle,
  LabelStyle,
  GridStyle,
} from "./GuageLinear.styles";
import { CardStatus } from "components/Card";
import { Alveolar, Exogenous } from "components/icons";
import { GaugeLinearOptions } from "services/annotation.service";
import { useTranslation } from "react-i18next";
import { getAssetsPath } from "utils/assets.utils";

interface StepOption {
  icon: string;
  value: number;
  status: CardStatus;
  label: string;
}

interface GaugeLinearProps {
  options: GaugeLinearOptions;
  currentValue: number;
}

const GaugeLinear: React.FC<GaugeLinearProps> = ({ currentValue, options }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const valueLabelFormat = (index: number) => {
    return options.steps[index];
  };

  const getColor = (status: CardStatus) => {
    switch (status) {
      case CardStatus.IN_PROGRESS:
        return theme.palette.success.main;
      case CardStatus.FAIL:
        return theme.palette.error.main;
      case CardStatus.WARNING:
        return theme.palette.warning.main;
      case CardStatus.SUCCESS:
        return theme.palette.success.main;
      default:
        return theme.palette.grey[400];
    }
  };

  const getStepByValue = (value: number): StepOption => {
    return options.steps.reduce((prev, curr) =>
      value >= Math.abs(prev.value) && value < Math.abs(curr.value)
        ? prev
        : curr
    );
  };

  const nearestStep = getStepByValue(currentValue);

  const getIcon = (icon: string, color: string) => {
    switch (icon) {
      case "alveolar":
        return <Alveolar sx={{ fill: color }} />;
      case "exogenous":
        return <Exogenous sx={{ fill: color }} />;
      default:
        return null;
    }
  };

  return (
    <GridStyle container>
      <Grid item>
        <Box position="relative">
          <GaugeSliderStyle
            defaultValue={options.steps.map((item) => item.value)}
            min={options.range.start}
            max={options.range.stop}
            disabled
            slots={{
              thumb: (props) => {
                const option = valueLabelFormat(props["data-index"]);
                const color =
                  option.value <= nearestStep.value
                    ? getColor(nearestStep.status)
                    : theme.palette.grey[400];
                return (
                  <SliderThumb
                    {...props}
                    sx={{
                      zIndex: 2,
                      border: `2px solid ${color}`,
                    }}
                  >
                    {option.icon.includes("icons/") ? (
                      <SvgImage
                        url={getAssetsPath(option.icon)}
                        primaryColor={color}
                        sx={{ width: 20 }}
                      />
                    ) : (
                      getIcon(option.icon, color)
                    )}
                    <LabelStyle>{t(option.label)}</LabelStyle>
                  </SliderThumb>
                );
              },
            }}
          />
          <VisualSliderStyle
            value={currentValue}
            min={options.range.start}
            max={options.range.stop}
            sx={{
              color: getColor(nearestStep.status),
            }}
          />
        </Box>
      </Grid>
    </GridStyle>
  );
};

export default GaugeLinear;
