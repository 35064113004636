import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ActiveListItem,
  StyleLeftNavigationItem,
} from "layouts/layouts.styles";
import { Grid, ListItemText, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getAssetsPath } from "utils/assets.utils";

interface MenuItemProps {
  item: {
    icon: string;
    label: string;
    link?: string;
    color?: string;
    backgroundColor?: string;
    onClick?: () => void;
  };
  open: boolean;
  allowAction: boolean;
}

const LeftNavigationLinks: React.FC<MenuItemProps> = ({
  item,
  open,
  allowAction,
}) => {
  const isSelected = item.link === window.location.pathname;
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    if (!allowAction) return;
    if (item.link && !item.onClick) navigate(item.link);
    if (item.onClick) item.onClick();
  };

  return (
    <Typography
      onClick={handleClick}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      component="div"
    >
      <StyleLeftNavigationItem
        key={item.icon}
        {...(isSelected && { selected: true })}
        open={open}
        color={item.backgroundColor}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="start"
          flexWrap="nowrap"
        >
          <img
            src={getAssetsPath(item.icon)}
            alt="Icon"
            style={{ marginRight: 16 }}
          />
          <Grid
            item
            sx={{
              color: item.color || "inherit",
            }}
          >
            {isSelected ? (
              <ActiveListItem
                sx={{
                  opacity: open ? 1 : 0,
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                {t(item.label)}
              </ActiveListItem>
            ) : (
              <ListItemText
                sx={{ opacity: open ? 1 : 0 }}
                primary={t(item.label)}
              />
            )}
          </Grid>
        </Grid>
      </StyleLeftNavigationItem>
    </Typography>
  );
};

export default LeftNavigationLinks;
