import { Grid } from "@mui/material";
import { Theme } from "@mui/system";
import { useTranslation } from "react-i18next";

interface StatusProps {
  text: string;
  Icon: React.ElementType;
  color: Theme["palette"];
}

const DeviceStatus: React.FC<StatusProps> = ({ text, Icon, color }) => {
  const { t } = useTranslation();
  return (
    <Grid
      display={"flex"}
      justifyContent={"space-between"}
      sx={{ color: color.dark }}
    >
      <Grid item sx={{ margin: "0 0.3rem" }}>
        <Icon sx={{ baseColor: color.main }} />
      </Grid>
      <Grid item sx={{ margin: "0 0.3rem" }}>
        {t(text)}
      </Grid>
    </Grid>
  );
};

export default DeviceStatus;
