import React from "react";
import { useTranslation } from "react-i18next";

import { getStorageDetail, Storage } from "services/analytics.service";

import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";

import { CardBackgrounds, CardSize, CardStatus } from "components/Card";
import Button, { ButtonColor } from "components/Button";

interface DownloadProps {
  storageId?: string;
  status: CardStatus;
  label: string;
  actionLabel: string;
  background?: CardBackgrounds;
  size?: CardSize;
}

const DownloadRawDataCard: React.FC<DownloadProps> = ({
  storageId,
  status,
  label,
  actionLabel,
  background,
  size,
}) => {
  const errorNotification = useSnackbarStore((state) => state.openError);
  const { t } = useTranslation();
  const handleDownloadClick = async () => {
    try {
      if (!storageId) return;
      const storageData: Storage = await getStorageDetail(storageId);
      const response = await fetch(storageData.signedUrl);
      if (!response.ok) {
        throw new Error("Network error");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = storageData.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      errorNotification(error);
    } finally {
    }
  };

  return (
    <Button color={ButtonColor.primary} onClick={handleDownloadClick}>
      {t(actionLabel)}
    </Button>
  );
};

export default DownloadRawDataCard;
