import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const Exogenous: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 15 10" sx={sx}>
      <g clipPath="url(#clip0_171_22743)">
        <path
          d="M11.9141 8.67969H2.89062C2.23301 8.67969 1.6111 8.42465 1.13948 7.96153C0.664133 7.4948 0.402344 6.87585 0.402344 6.21875C0.402344 5.56165 0.664133 4.9427 1.13948 4.47597C1.51173 4.11044 1.97762 3.87454 2.4808 3.79134C2.48058 3.78023 2.48047 3.76905 2.48047 3.75781C2.48047 1.94853 3.95244 0.476562 5.76172 0.476562C6.43268 0.476562 7.07747 0.678551 7.6264 1.06071C8.01282 1.32971 8.33364 1.67438 8.57214 2.0736C8.95711 1.83481 9.40114 1.70703 9.86328 1.70703C11.0906 1.70703 12.111 2.61009 12.2947 3.78669C12.8091 3.86544 13.2858 4.10336 13.6652 4.47597C14.1406 4.9427 14.4023 5.56163 14.4023 6.21875C14.4023 6.87587 14.1406 7.4948 13.6652 7.96153C13.1936 8.42465 12.5717 8.67969 11.9141 8.67969ZM2.89062 4.57812C1.97089 4.57812 1.22266 5.31411 1.22266 6.21875C1.22266 7.12339 1.97089 7.85938 2.89062 7.85938H11.9141C12.8338 7.85938 13.582 7.12339 13.582 6.21875C13.582 5.31411 12.8338 4.57812 11.9141 4.57812H11.5039V4.16797C11.5039 3.26333 10.7679 2.52734 9.86328 2.52734C9.43218 2.52734 9.02437 2.69469 8.71498 2.99856L8.27349 3.43218L8.04613 2.85662C7.67182 1.90913 6.77513 1.29688 5.76172 1.29688C4.40476 1.29688 3.30078 2.40085 3.30078 3.75781C3.30078 3.85472 3.31598 3.95876 3.33206 4.06893L3.40554 4.57812H2.89062Z"
          strokeWidth="0.8"
        />
      </g>
    </SvgIcon>
  );
};
