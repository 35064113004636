import Input from "./Input";
import Textarea from "./Textarea";
import Select from "./Select";
import RadioButtons from "./RadioButtons";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./Datepicker";
import DateTimePicker from "./DateTimepicker";
import TimePicker from "./Timepicker";
import Title from "./Title";

function FormikControl(props: any) {
  const { control, onClose, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "datetime":
      return <DateTimePicker {...rest} />;
    case "time":
      return <TimePicker {...rest} />;
    case "title":
      return <Title onClose={onClose} {...rest} />;
    case "section":
      return <Title {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
