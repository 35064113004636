import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const HumidityIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 175 175" sx={sx}>
      <g clipPath="url(#clip0_1424_15243)">
        <path d="M124.045 162.999C109.453 162.999 96.6831 152.012 94.343 137.44C93.0718 129.543 94.8867 121.892 99.8903 114.054C105.886 104.658 111.834 95.0202 117.583 85.6975C119.784 82.1304 121.984 78.5634 124.192 75L125.1 76.4517C126.79 79.1536 128.439 81.7931 130.078 84.4327C132.671 88.612 135.261 92.7949 137.851 96.9778C141.786 103.331 145.72 109.681 149.666 116.027C154.545 123.872 155.805 133.257 153.116 141.769C150.437 150.26 144.324 156.917 135.904 160.525C132.069 162.167 128.079 162.999 124.042 162.999H124.045ZM122.752 88.117C122.612 88.315 122.491 88.4726 122.388 88.612C122.183 88.8906 122.017 89.1142 121.859 89.3708C120.485 91.5778 119.111 93.7847 117.737 95.9917C114.108 101.813 110.357 107.837 106.716 113.79C106.301 114.472 105.875 115.147 105.449 115.821C103.344 119.157 101.169 122.607 100.133 126.779C98.329 134.056 100.015 141.703 104.762 147.756C109.552 153.867 116.602 157.372 124.1 157.372C125.32 157.372 126.554 157.277 127.774 157.09C136.227 155.781 143.083 150.641 146.58 142.983C150.129 135.218 149.449 126.141 144.761 118.703C142.447 115.033 140.129 111.272 137.884 107.639C136.613 105.578 135.338 103.514 134.06 101.458C132.186 98.4442 130.309 95.4381 128.388 92.355L124.354 85.8918L122.752 88.117Z" />
        <path d="M50.8865 144.372C48.1569 144.372 45.431 143.995 42.7859 143.25C33.4473 140.622 26.0887 134.012 22.5913 125.122C19.127 116.316 20.0013 106.59 24.9866 98.4369C31.1401 88.37 37.4847 78.1821 43.6162 68.3315C46.0225 64.4638 48.4325 60.5962 50.8351 56.7285L51.0335 57.0438C52.2128 58.9245 53.348 60.7428 54.4795 62.5648C56.6029 65.9852 58.73 69.402 60.8534 72.8224C66.1436 81.3276 71.6138 90.1224 76.9408 98.7998C83.1604 108.925 82.4073 122.666 75.1112 132.208C69.1965 139.94 60.3685 144.376 50.8865 144.376V144.372ZM47.5838 72.5658C46.3752 74.5088 45.2106 76.3821 44.0497 78.2554C42.5582 80.6567 41.0556 83.0543 39.553 85.4482C36.2099 90.7749 32.7529 96.2813 29.5016 101.799C25.6038 108.416 25.0527 116.342 27.9991 123.553C30.9013 130.661 36.7206 135.863 43.9615 137.825C46.2356 138.441 48.55 138.752 50.8425 138.752C58.7447 138.752 66.0297 135.042 70.8313 128.568C76.8526 120.451 77.1061 109.45 71.4742 100.541C69.2443 97.0108 67.0106 93.3925 64.8505 89.8914C63.6859 87.9997 62.5176 86.1117 61.3457 84.2237C59.3178 80.961 57.2899 77.7019 55.2032 74.3584L50.8314 67.3417L47.5838 72.5658Z" />
        <path d="M96.8336 73.8715C96.5728 73.8715 96.3156 73.8679 96.0511 73.8569C88.3804 73.5856 81.6611 69.4723 78.0755 62.8515C74.4862 56.2233 74.7214 48.3413 78.7037 41.7755C82.8109 35.0007 86.9733 28.1085 90.9997 21.44C92.8991 18.2909 94.7984 15.1455 96.7014 12C97.5721 13.4444 98.4391 14.8778 99.2987 16.3039C102.557 21.6966 105.632 26.7888 108.733 32.0018C109.435 33.1823 110.169 34.3628 110.882 35.5066C112.811 38.608 114.809 41.8158 116.202 45.1262C118.917 51.5711 118.241 58.6245 114.343 64.4792C110.423 70.3668 103.88 73.8789 96.8336 73.8789V73.8715ZM95.4193 25.304C95.3458 25.3847 95.2797 25.4507 95.2209 25.513C95.0445 25.6926 94.8241 25.9162 94.6331 26.2279C93.6375 27.8739 92.6382 29.5163 91.6353 31.1623C88.8359 35.7559 85.9373 40.507 83.2261 45.2765C82.0982 47.2598 81.3635 49.7564 81.2129 52.1247C80.7904 58.7345 84.8462 64.9814 91.0695 67.313C92.8917 67.9949 94.791 68.3432 96.7124 68.3432C101.268 68.3432 105.551 66.3708 108.465 62.9321C112.833 57.774 113.413 50.6546 109.945 44.7926C108.994 43.1869 108.017 41.5665 107.069 40.0011L106.54 39.1286C104.596 35.9098 102.646 32.6911 100.68 29.4393L97.0761 23.482L95.4193 25.3077V25.304Z" />
      </g>
    </SvgIcon>
  );
};
