import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/system";

import StepLabel from "@mui/material/StepLabel";
import { Step as MuiStep } from "@mui/material";
import {
  ProgressBarStatus,
  ProgressBarStep,
  useHeaderStore,
} from "stores/header.store";
import {
  StyledStepper,
  PendingStep,
  InProgressStep,
  SuccessStep,
  WarningStep,
  FailStep,
} from "components/ProgressBar/styles/index";

const Step = ({ step, ...props }: any) => {
  switch (step.status) {
    case ProgressBarStatus.PENDING:
      return <PendingStep className={step.status.toLowerCase()} {...props} />;
    case ProgressBarStatus.IN_PROGRESS:
      return (
        <InProgressStep className={step.status.toLowerCase()} {...props} />
      );
    case ProgressBarStatus.SUCCESS:
      return <SuccessStep className={step.status.toLowerCase()} {...props} />;
    case ProgressBarStatus.WARNING:
      return <WarningStep className={step.status.toLowerCase()} {...props} />;
    case ProgressBarStatus.FAIL:
      return <FailStep className={step.status.toLowerCase()} {...props} />;
    default:
      return <MuiStep {...props} />;
  }
};

const ProgressBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { steps, activeStep, allowAction, onClick } = useHeaderStore(
    (state) => ({
      steps: state.steps,
      activeStep: state.activeStep,
      allowAction: state.allowAction,
      onClick: state.onClick,
    })
  );
  const { t } = useTranslation();

  const handleClick = (step: ProgressBarStep) => () => {
    if (!allowAction) return;
    if (!step.enableLink) return;
    if (!step.link) return;

    if (allowAction && !onClick) navigate(step.link);
    if (onClick) {
      onClick(step.link, step.label);
    }
  };

  return (
    <StyledStepper activeStep={activeStep} alternativeLabel>
      {steps.map((step: ProgressBarStep) => (
        <Step
          key={step.label}
          onClick={handleClick(step)}
          step={step}
          sx={{
            cursor: step.enableLink && allowAction ? "pointer" : "cursor",
            width: "7rem",
            color: theme.palette.primary.success,
          }}
        >
          <StepLabel className={step.status.toLowerCase()}>
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t(step.label)}
            </Box>
          </StepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
};

export default ProgressBar;
