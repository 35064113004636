import React from "react";
import { BoxContainer, BoxCount, BoxBackground, BoxText } from "./styles";
import { Theme } from "@mui/system";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DynamicIcon from "components/icons/DynamicIcon";

interface QuickNavigationProps {
  text: string;
  count: number;
  icon: React.ElementType;
  color: Theme["palette"];
  onClick?: () => void;
  defaultSelected: boolean;
  textColor?: string;
  disabled?: boolean;
}

const QuickNavigation: React.FC<QuickNavigationProps> = ({
  text,
  count,
  onClick,
  defaultSelected,
  icon,
  color,
  textColor,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Typography
      component={"div"}
      sx={{
        margin: "1rem 0",
        fontWeight: 500,
        fontSize: "1.1rem",
        ...(defaultSelected && {
          border: `0.1rem solid ${color.main}`,
          borderRadius: "2.75rem",
          padding: "0.2rem",
        }),
      }}
    >
      <BoxContainer
        key={t(text)}
        onClick={!disabled ? onClick : () => {}}
        sx={{
          position: "relative",
          padding: defaultSelected ? "0.425rem" : "0.725rem",
        }}
      >
        <BoxBackground
          sx={{
            background: defaultSelected ? color.main : color.lighter,
          }}
        />
        <BoxCount
          sx={{
            position: "relative",
            color: defaultSelected ? color.main : color.dark,
            zIndex: 2,
          }}
        >
          {count}
        </BoxCount>
        <BoxText
          sx={{
            color: defaultSelected ? theme.palette.common.white : color.dark,
          }}
        >
          {t(text)}
        </BoxText>
        <DynamicIcon
          component={icon}
          sx={{
            position: "relative",
            width: "1.8rem",
            fontSize: "1.6rem",
            fill: defaultSelected ? theme.palette.common.white : color.main,
            zIndex: 2,
          }}
        />
      </BoxContainer>
    </Typography>
  );
};

export default QuickNavigation;
