import { Grid } from "@mui/material";
import React from "react";

interface SpacerProps {
  width?: string;
  height?: string;
}

const Spacer: React.FC<SpacerProps> = ({ width, height }) => {
  const spacerStyle: React.CSSProperties = {
    width: width || "auto",
    height: height || "auto",
  };

  return <Grid item sx={spacerStyle}></Grid>;
};

export default Spacer;
