import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const PendingIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 46 46" sx={{ fontSize: "1.6rem", ...sx }}>
      <path d="M22.9993 0C35.696 0 46 10.304 46 22.9978C46 35.6945 35.696 46 22.9993 46C10.3041 46 0 35.6959 0 22.9978C0.00447224 10.3026 10.3085 0 22.9993 0ZM31.1343 11.6159L19.7435 22.9978L22.9993 26.2536L34.3856 14.8672L31.1343 11.6159Z" />
    </SvgIcon>
  );
};
