import { styled } from "@mui/system";
import Typography, { TypographyProps } from "@mui/material/Typography";

export const StyleTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: "600",
  fontSize: "1.5rem",
  marginBottom: "1rem",
}));

export const StyleSubTitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontWeight: "600",
    fontSize: "1.0rem",
  })
);
