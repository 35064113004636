import { Field, FieldProps, ErrorMessage } from "formik";
import { FormControl, TextField } from "@mui/material";
import TextError from "./TextError";
import TextLabel from "../TextLabel";
import { useTranslation } from "react-i18next";

interface InputProps {
  label: string;
  name: string;
  isrequired?: boolean;
  type?: string;
  [key: string]: any;
}

const Input = (props: InputProps) => {
  const { label, name, variant, placeholder, isrequired, type, ...rest } =
    props;
  const variantProp = variant ? `${variant}` : {};
  const { t } = useTranslation();
  return (
    <FormControl sx={{ width: "100%" }}>
      {label && <TextLabel label={label} required={isrequired} />}
      <Field name={name}>
        {({ field }: FieldProps) => {
          if (type === "hidden") {
            return <input type="hidden" {...field} {...rest} />;
          }
          return (
            <TextField
              {...field}
              {...rest}
              {...variantProp}
              placeholder={t(placeholder)}
              id={name}
              error={!!(rest.form && rest.form.errors[name])}
            />
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg: string) => <TextError>{msg}</TextError>}
        className="error"
      />
    </FormControl>
  );
};

export default Input;
