import { Typography } from "@mui/material";
import { graphType } from "constants/index";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLeftNavigationStore } from "stores/navigation.store";
import { getTitle } from "utils/chart.utils";

interface PlotData {
  data: object[];
  layout: object;
  config?: any;
  sx?: any;
}

const Chart: React.FC<PlotData> = ({ data, layout, config = {}, sx }) => {
  const plotRef = useRef<HTMLDivElement>(null);
  const open = useLeftNavigationStore((state) => state.open);
  const plotlyRef = useRef<any>(null);
  const { t } = useTranslation();

  const modifiedLayout = useMemo(() => {
    const hasEmptyType = data.some(
      (item: any) => item?.type === graphType.EMPTY
    );
    if (hasEmptyType) {
      return {
        ...layout,
        margin: {},
        xaxis: {
          visible: false,
        },
        yaxis: {
          visible: false,
        },
        shapes: [],
        annotations: [
          {
            text: getTitle(t("studiesDashboard.graphs.noData.label")),
            xref: "paper",
            yref: "paper",
            showarrow: false,
          },
        ],
      };
    }
    return layout;
  }, [data, layout]);

  useEffect(() => {
    let isMounted = true;
    import("plotly.js-basic-dist-min").then((Plotly) => {
      plotlyRef.current = Plotly;
      if (plotRef.current && isMounted && data.length) {
        Plotly.newPlot(plotRef.current, data, modifiedLayout, config);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [data, layout, config]);

  useEffect(() => {
    setTimeout(() => {
      if (plotRef.current && plotlyRef.current) {
        plotlyRef.current.Plots.resize(plotRef.current);
      }
    }, 100);
  }, [open]);

  return <Typography ref={plotRef} sx={{ ...sx }} />;
};

export default Chart;
