import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardStatus, getFiltersColor } from "components/Card";
import { TrialStatus, TrialsSortingState } from "services/cde.service";
import { useTheme } from "@mui/material/styles";
import { statusIcon } from "utils/trial.utils";
import Spacer from "components/Spacer";
import { SortOrder, trialStatusConfig } from "constants/index";
import Button, { ButtonColor } from "components/Button";
import { getAssetsPath } from "utils/assets.utils";
import IconButton from "components/IconButton";
import EditIcon from "components/icons/EditIcon";
import { useNavigate, useParams } from "react-router-dom";
import { buildLocalUrl } from "utils/url.utils";
import { ROUTES } from "constants/routes.constants";
import TrialPopover from "features/trials/components/trialDetails/TrialPopover.component";
import TrialDetails from "features/trials/components/trialDetails/TrialDetails.component";
import Table from "components/Table";
import { SvgIcon, Typography } from "@mui/material";
import { QueryObserverResult } from "@tanstack/react-query";

interface Study {
  uuid: string;
  name: string;
  status: TrialStatus;
  description: string;
  preferences: any;
}

interface TrialActionsHandlerProps {
  queries: {
    query: QueryObserverResult<any, unknown>;
    updateTrialStatus: {
      mutate: (args: {
        uuid: string;
        name: string;
        description: string;
        preferences: any;
        status: string;
      }) => void;
    };
  };
  studies: Study[];
  handleSorting: (order: string) => void;
  sortable: TrialsSortingState;
  setSortable: React.Dispatch<React.SetStateAction<TrialsSortingState>>;
}

const StudiesListActionsHandler: React.FC<TrialActionsHandlerProps> = ({
  queries,
  studies,
  handleSorting,
  sortable,
  setSortable,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const [editOpen, setEditOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTrial, setSelectedTrial] = useState<Study | null>(null);
  const [openTrialDetails, setOpenTrialDetails] = useState(false);

  const handleSortToggle = (column: keyof TrialsSortingState) => {
    const newOrder =
      sortable[column] === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    handleSorting(`${column}|${newOrder}`);
    setSortable((prevSortable) => ({
      ...prevSortable,
      [column]: newOrder,
    }));
  };

  const handleNavigation = (path: string, params: Record<string, string>) => {
    const url = buildLocalUrl({ path, params });
    navigate(url);
  };

  const handleOpenParticipantClick = (uuid: string) => {
    const { trialId, pui } = params;
    handleNavigation(
      trialId && pui
        ? ROUTES.TRIAL_PARTICIPANT_DETAILS
        : ROUTES.TRIAL_PARTICIPANTS,
      trialId && pui ? { trialId: uuid, trialMemberId: pui } : { trialId: uuid }
    );
  };

  const handleOverviewClickOpen = (uuid: string) => {
    setOpenTrialDetails(true);
    handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW, {
      trialId: uuid,
    });
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setAnchorEl(null);
  };

  const handleDetailClickOpen = (uuid: string) => {
    setOpenTrialDetails(true);
    handleNavigation(ROUTES.STUDIES_DASHBOARD_TRIAL_DETAILS, { trialId: uuid });
  };

  const handleDetailClose = () => {
    handleNavigation(ROUTES.STUDIES_DASHBOARD, {});
    setOpenTrialDetails(false);
  };

  const handleTrialStatusClick = (status: string, study: Study) => {
    queries.updateTrialStatus.mutate({
      uuid: study.uuid,
      name: study.name,
      description: study.description,
      preferences: study.preferences,
      status,
    });
  };

  const handleEditClickOpen = (
    event: React.MouseEvent<HTMLElement>,
    study: Study
  ) => {
    setSelectedTrial(study);
    setEditOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const columns = [
    {
      key: "name",
      label: t("studiesDashboard.trials.list.studies.label"),
      width: { md: 5, xs: 6, sm: 4 },
      sx: { display: "flex", alignItems: "center", cursor: "pointer" },
      sortable: {
        onColumnClick: handleSortToggle,
        defaultDirection: sortable.name,
      },
    },
    {
      key: "status",
      label: t("studiesDashboard.trials.list.status.label"),
      width: { md: 3, xs: 6, sm: 3 },
      sx: { display: "flex", justifyContent: "center" },
    },
    {
      key: "action",
      label: t("studiesDashboard.trials.list.action.label"),
      width: { md: 2, xs: 6, sm: 3 },
      sx: { display: "flex", justifyContent: "center" },
    },
    {
      key: "more",
      label: t("studiesDashboard.trials.list.more.label"),
      width: { md: 2, xs: 6, sm: 2 },
      sx: {
        display: "flex",
        justifyContent: "end",
      },
    },
  ];

  const data = studies.map((study) => {
    const isStudyInProgress = [
      TrialStatus.PENDING,
      TrialStatus.IN_PROGRESS,
    ].includes(study.status);
    const statusColor = getFiltersColor(
      (study.status === TrialStatus.COMPLETE
        ? CardStatus.SUCCESS
        : study.status === TrialStatus.TERMINATED
        ? CardStatus.FAIL
        : study.status === TrialStatus.IN_PROGRESS
        ? CardStatus.WARNING
        : study.status) as CardStatus,
      theme
    ).dark;

    return {
      name: study.name,
      status: (
        <Typography
          component="div"
          display={"flex"}
          justifyContent={"left"}
          sx={{ color: statusColor }}
        >
          <SvgIcon component={statusIcon[study.status]} />
          <Spacer width="0.5rem" />
          {t(trialStatusConfig[study.status]?.text)}
        </Typography>
      ),
      action: (
        <Button
          disabled={!isStudyInProgress}
          color={ButtonColor.primary}
          onClick={() => handleOpenParticipantClick(study.uuid)}
        >
          {t("select")}
        </Button>
      ),
      more: (
        <>
          <img
            src={getAssetsPath("icons/study_button.svg")}
            alt={study.name}
            style={{ cursor: "pointer" }}
            onClick={() => handleOverviewClickOpen(study.uuid)}
          />
          <Spacer width="0.5rem" />
          <IconButton
            sx={{ cursor: "pointer" }}
            onClick={
              editOpen
                ? handleEditClose
                : (event: any) => handleEditClickOpen(event, study)
            }
          >
            <EditIcon />
          </IconButton>
        </>
      ),
    };
  });

  return (
    <>
      <Table columns={columns} data={data} hideHeaderOnMobile={true} />
      {openTrialDetails && selectedTrial && (
        <TrialDetails
          isOpen={openTrialDetails}
          trialId={selectedTrial.uuid}
          onClose={handleDetailClose}
        />
      )}
      {selectedTrial && (
        <TrialPopover
          trialStatus={selectedTrial.status}
          open={editOpen}
          anchorEl={anchorEl}
          trialId={selectedTrial.uuid}
          onClose={handleEditClose}
          handleDetailClickOpen={() =>
            handleDetailClickOpen(selectedTrial.uuid)
          }
          handleUpdateStatus={(status) =>
            handleTrialStatusClick(status, selectedTrial)
          }
          handleParticipantClickOpen={() =>
            handleOpenParticipantClick(selectedTrial.uuid)
          }
        />
      )}
    </>
  );
};

export default StudiesListActionsHandler;
