import { ReactNode } from "react";
import { create } from "zustand";
import { ButtonTypes } from "./DialogBox.component";

interface DialogBoxState {
  dialogs: {
    [key: string]: {
      isOpen: boolean;
      showCloseButton: boolean;
      title?: string;
      description: ReactNode;
      buttons?: {
        label: string;
        action: string[] | string;
        type?: ButtonTypes;
      }[];
    };
  };
  open: (key: string, content: DialogBoxContent) => void;
  close: (key: string) => void;
}

interface DialogBoxContent {
  title?: string;
  description: ReactNode;
  buttons?: {
    label: string;
    action: string[] | string;
    type?: ButtonTypes;
  }[];
  showCloseButton: boolean;
}

export const useDialogBoxStore = create<DialogBoxState>((set) => ({
  dialogs: {},
  open: (key, content) =>
    set((state) => ({
      dialogs: {
        ...state.dialogs,
        [key]: {
          ...content,
          isOpen: true,
        },
      },
    })),
  close: (key) =>
    set((state) => ({
      dialogs: {
        ...state.dialogs,
        [key]: {
          ...state.dialogs[key],
          isOpen: false,
        },
      },
    })),
}));
