import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import { containerStyle, iconsLink, graphType } from "../../constants";
import { ROUTES } from "constants/routes.constants";
import { useInformationBlockStore } from "components/InformationBlock/informationblock.store";
import { useParams } from "react-router-dom";
import useTrialApi from "hooks/useTrialApi.hook";
import StudyTabs from "./Components/studyTabs.component";
import StudyHeader from "./Components/studyHeader.component";
import { useLeftNavigationStore } from "stores/navigation.store";
import { useNavigate } from "react-router-dom";
import { buildLocalUrl } from "utils/url.utils";
import StudyTabContent from "./Components/studyTabContent.component";
import { StyledGrid } from "./StudiesDashboard.styles";
import { Typography } from "@mui/material";
import Chart from "components/Chart/Chart.component";
import Loading from "components/Loading";
import { useStudiesStore } from "./studies.store";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useStudiesDashboardApi from "hooks/useStudiesDashboardApi.hook";
import { TrialStatus } from "services/cde.service";
import { CardStatus, getCardColor } from "components/Card";
import StudyOverviewStats from "./Components/studyOverviewStats.component";
import { CustomError } from "utils/privateHttp.utils";
import { getTitle } from "utils/chart.utils";
import { useIsBetween768And1400, useIsMobile } from "hooks/media.hook";

const StudyOverview = () => {
  const { trialId } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isBetween768And1400 = useIsBetween768And1400();
  const isMobile = useIsMobile();
  const initHeader = useHeaderStore((state) => state.initialize);
  const setInformationBlock = useInformationBlockStore((state) => state.init);
  const pieChartconfig = useStudiesStore(
    (state) => state.studiesPieChartConfig
  );
  const barChartconfig = useStudiesStore(
    (state) => state.studiesBarChartConfig
  );
  const setStudiesPieChartData = useStudiesStore(
    (state) => state.setStudiesPieChartData
  );
  const setStudiesBarChartData = useStudiesStore(
    (state) => state.setStudiesBarChartData
  );
  const reset = useStudiesStore((state) => state.reset);

  const studiesPieChartLayout = useStudiesStore(
    (state) => state.studiesPieChartLayout
  );
  const studiesBarChartLayout = useStudiesStore(
    (state) => state.studiesBarChartLayout
  );

  const pieChartData = useStudiesStore((state) => state.studiesPieChartData);
  const barChartData = useStudiesStore((state) => state.studiesBarChartData);
  const { addNavigationState, switchNavigationState } = useLeftNavigationStore(
    (state) => state
  );
  const dashboardCdes = useLeftNavigationStore((state) => state.dashboardCdes);

  const queries = useTrialApi({
    trialId: trialId || "",
    take: 0,
    skip: 0,
  });

  const cdeId = queries.trial.data?.cdeId;
  const devicesQueries = useStudiesDashboardApi({
    trialId: trialId,
    cdeId: cdeId,
    resultsGraph: true,
  });

  const handleNavigation = (path: string) => {
    navigate(
      buildLocalUrl({
        path,
        params: { trialId },
      })
    );
  };

  const pieChartLayout = {
    ...studiesPieChartLayout,
    title: {
      ...studiesPieChartLayout.title,
      text: getTitle(
        t("studyOverview.piechart.heading.label"),
        isBetween768And1400 || isMobile ? "1.5rem" : "2rem"
      ),
      font: { color: theme.palette.text.primary, size: 24 },
    },
  };
  studiesBarChartLayout.shapes.forEach((_: any, index: number) => {
    studiesBarChartLayout.shapes[index].line.color = theme.palette.grey[300];
  });
  const barChartLayout = {
    ...studiesBarChartLayout,
    title: {
      text: getTitle(
        t("studyOverview.barchart.heading.label"),
        isBetween768And1400 || isMobile ? "1.5rem" : "2rem"
      ),
      x: 0.02,
      font: { color: theme.palette.text.primary, size: 24 },
    },
  };

  useEffect(() => {
    const cdeId = queries.trial.data?.cdeId;
    if (!cdeId) return;
    const cdeExists = dashboardCdes.some((item) => item.cdeId === cdeId);
    if (!cdeExists) {
      throw new CustomError(
        t("studiesDashboard.forbidden.error.message"),
        "403"
      );
    }
  }, [queries.trial.isFetched, dashboardCdes]);

  useEffect(() => {
    reset();
    setInformationBlock({ show: false });
    addNavigationState("studiesDashboard", [
      {
        icon: iconsLink.back,
        label: "studiesDashboard.leftnavigation.backtostudies.label",
        onClick: () => handleNavigation(ROUTES.STUDIES_DASHBOARD),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
      },
      {
        icon: iconsLink.studyOverview,
        label: "studiesDashboard.study.overview.tab.label",
        onClick: () =>
          handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW),
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
      },
      {
        icon: iconsLink.studyParticipants,
        label: "studiesDashboard.study.participants.tab.label",
        onClick: () =>
          handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_PARTICIPANTS),
        color: theme.palette.grey[600],
      },
      {
        icon: iconsLink.studyDevices,
        label: "studiesDashboard.study.devices.tab.label",
        onClick: () => handleNavigation(ROUTES.STUDIES_DASHBOARD_STUDY_DEVICES),
        color: theme.palette.grey[600],
      },
    ]);
    switchNavigationState("studiesDashboard");
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.study",
          text: queries.trial.data?.name || "",
        },
        subTitleItems: [],
        showMenu: true,
      } as InitHeaderOptions,
      {
        activeStep: 1,
        allowAction: true,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.STUDIES_DASHBOARD,
            enableLink: true,
          },
          {
            label: "progressBar.step.studyOverview",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
      ]
    );
  }, [queries.trial.isFetched]);

  useEffect(() => {
    const statistics = devicesQueries.CdeAnalytics.data;
    if (!statistics) return;
    const { total, complete } = statistics;
    const statusList = [TrialStatus.COMPLETE, TrialStatus.IN_PROGRESS];
    setStudiesPieChartData([{ type: graphType.EMPTY }]);
    if (!total) return;
    setStudiesPieChartData([
      {
        values: Object.values([complete, total - complete]).filter(
          (value) => value !== 0
        ),
        labels: statusList.map((status: string) => {
          return status === TrialStatus.IN_PROGRESS
            ? t("studyOverview.piechart.fail.label")
            : t("studyOverview.piechart.complete.label");
        }),
        type: graphType.PIE,
        marker: {
          colors: statusList.map((status: string) => {
            const statisticsStatus =
              status === TrialStatus.COMPLETE
                ? CardStatus.SUCCESS
                : CardStatus.FAIL;
            return getCardColor(statisticsStatus as CardStatus, theme).main;
          }),
        },
        pull: [0.01, 0.01, 0.01, 0.01],
        textinfo: "value",
        textfont: {
          size: 20,
          color: theme.palette.common.white,
        },
      },
    ]);
  }, [devicesQueries.CdeAnalytics.isFetched]);

  useEffect(() => {
    const data = devicesQueries.CdeMonthyAnalytics.data;
    if (!data) return;
    setStudiesBarChartData([{ type: graphType.EMPTY }]);
    if (!data.failedTrials.x.length && !data.completedTrials.x.length) return;
    const passData = {
      x: data.completedTrials.x,
      y: data.completedTrials.y,
      name: t("studyOverview.barchart.complete.label"),
      type: graphType.BAR,
      marker: {
        color: getCardColor(CardStatus.SUCCESS as CardStatus, theme).main,
      },
    };
    const failData = {
      x: data.failedTrials.x,
      y: data.failedTrials.y,
      name: t("studyOverview.barchart.fail.label"),
      type: graphType.BAR,
      marker: {
        color: getCardColor(CardStatus.FAIL as CardStatus, theme).main,
      },
    };
    setStudiesBarChartData([passData, failData]);
  }, [devicesQueries.CdeMonthyAnalytics.isFetched]);

  return (
    <>
      {!queries.trial.isFetched ? (
        <Loading />
      ) : (
        <Grid
          display="flex"
          flexDirection={"column"}
          gap={2}
          sx={containerStyle}
        >
          <Grid item flexGrow={1}>
            <StudyHeader
              name={queries.trial.data?.name || ""}
              trialId={trialId}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <StudyTabs tabValue={0} trialId={trialId} />
            <StudyTabContent value={0} index={0}>
              <Grid container>
                <Grid item flexGrow={1}>
                  <Grid
                    container
                    display={"flex"}
                    pt={3}
                    gap={3}
                    sx={{ flexDirection: { md: "row", xs: "column" } }}
                  >
                    <StyledGrid item p={1}>
                      {pieChartData.length ? (
                        <Typography sx={{ width: "20rem" }} component="div">
                          <Chart
                            data={pieChartData}
                            layout={{
                              ...pieChartLayout,
                              font: { family: theme.typography.fontFamily },
                              hoverlabel: {
                                font: {
                                  family: theme.typography.fontFamily,
                                  color: theme.palette.common.white,
                                },
                              },
                            }}
                            config={pieChartconfig}
                            sx={{ margin: "1rem" }}
                          />
                        </Typography>
                      ) : (
                        <Loading sx={{ height: "inherit" }} />
                      )}
                    </StyledGrid>
                    <StyledGrid item flexGrow={1} p={1}>
                      {barChartData.length ? (
                        <Typography component="div" sx={{ width: "100%" }}>
                          <Chart
                            data={barChartData}
                            layout={{
                              ...barChartLayout,
                              font: { family: theme.typography.fontFamily },
                              hoverlabel: {
                                font: { family: theme.typography.fontFamily },
                              },
                            }}
                            config={barChartconfig}
                            sx={{ margin: "0.3rem" }}
                          />
                        </Typography>
                      ) : (
                        <Loading sx={{ height: "inherit" }} />
                      )}
                    </StyledGrid>
                  </Grid>
                </Grid>
              </Grid>
            </StudyTabContent>
          </Grid>
          <Grid item flexGrow={1}>
            <StudyOverviewStats
              cdeAnalytics={devicesQueries.CdeAnalytics}
              trial={queries.trial}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StudyOverview;
