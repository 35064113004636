import { create } from "zustand";

interface studiesStore {
  studiesPieChartLayout: Record<string, any>;
  studiesPieChartData: Record<string, any>[];
  setStudiesPieChartData: (studiesPieChartData: Record<string, any>[]) => void;
  studiesPieChartConfig: Record<string, any>;
  studiesBarChartLayout: Record<string, any>;
  studiesBarChartData: Record<string, any>[];
  setStudiesBarChartData: (studiesBarChartData: Record<string, any>[]) => void;
  studiesBarChartConfig: Record<string, any>;
  reset: () => void;
}

const init = {
  studiesPieChartLayout: {
    showlegend: false,
    title: { x: 0.0 },
    margin: { l: 0, r: 0, b: 0 },
    hovermode: true,
    hoverinfo: "label",
  },
  studiesPieChartConfig: {
    displayModeBar: false,
    responsive: true,
    scrollZoom: false,
    editable: false,
  },
  studiesPieChartData: [],
  studiesBarChartLayout: {
    barmode: "stack",
    showlegend: false,
    yaxis: {
      griddash: "dash",
      fixedrange: true,
      zeroline: false,
    },
    shapes: [
      {
        type: "line",
        x0: 0,
        x1: 1,
        y0: 0,
        y1: 0,
        xref: "paper",
        yref: "y",
        line: {
          color: "",
          width: 1,
          dash: "dash",
        },
      },
    ],
    xaxis: {
      showgrid: false,
      zeroline: false,
      fixedrange: true,
      rangemode: "tozero",
    },
    bargap: 0.7,
    margin: {
      r: 50,
      b: 60,
      pad: 20,
    },
  },
  studiesBarChartConfig: {
    displayModeBar: false,
    responsive: true,
    scrollZoom: false,
    editable: false,
  },
  studiesBarChartData: [],
};

export const useStudiesStore = create<studiesStore>((set) => ({
  ...init,
  reset: () => {
    set(init);
  },
  setStudiesPieChartData: (studiesPieChartData: object[]) =>
    set({ studiesPieChartData }),
  setStudiesBarChartData: (studiesBarChartData: object[]) =>
    set({ studiesBarChartData }),
}));
