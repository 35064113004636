import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactNode } from "react";
import StatsCard from "features/trials/components/StatsCard";

interface StatisticsBarProps {
  statsData: {
    icon?: string;
    value: number | string | undefined;
    label: string;
    hasBorder?: boolean;
  }[];
  childIcons?: { show?: boolean; node: ReactNode }[];
}

const StatisticsBar: React.FC<StatisticsBarProps> = ({
  statsData,
  childIcons,
}) => {
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="row"
        justifyContent="start"
        sx={{ padding: "0.9rem" }}
      >
        <Grid item flexGrow={1}>
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
            spacing={2}
          >
            {statsData.map((stat, i) => (
              <React.Fragment key={`stats-label-${i}`}>
                <Grid key={`stats-data-${i}`} item>
                  <StatsCard
                    key={`${stat.label}-${i + 1}`}
                    imgSrc={stat.icon ? stat.icon : ""}
                    statsData={stat.value}
                    statsLabel={stat.label}
                  />
                </Grid>
                {stat.hasBorder && (
                  <Grid
                    key={`stats-value-${i}`}
                    item
                    sx={{
                      borderRight: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  ></Grid>
                )}
              </React.Fragment>
            ))}

            <Grid item display="flex" alignItems="center" justifyContent="end">
              {childIcons &&
                childIcons.map((icon, i) => (
                  <div key={`icon-${i}`}>{icon.show && icon.node}</div>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StatisticsBar;
