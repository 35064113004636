import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { StyledChip } from "../Participants.styles";
import { useTheme } from "@mui/system";
import Tooltip from "components/Tooltip";

interface ParticipantSampleCountProps {
  label: string;
  count: number;
  isMobile: boolean;
  minExperiments: number;
  tooltipText: string;
}

const ParticipantSampleCount: React.FC<ParticipantSampleCountProps> = ({
  label,
  count,
  isMobile,
  minExperiments,
  tooltipText,
}) => {
  const theme = useTheme();
  const backgroundColor =
    count === 0
      ? theme.palette.pending.main
      : count >= minExperiments
      ? theme.palette.success.main
      : theme.palette.warning.main;

  return isMobile ? (
    <Grid container flexDirection={"column"} sx={{ width: "auto" }}>
      <Grid item display={"flex"} justifyContent={"center"}>
        {count}
      </Grid>
      <Grid item>{label}</Grid>
    </Grid>
  ) : (
    <>
      {count != null && count >= minExperiments ? (
        <StyledChip
          sx={{
            background: backgroundColor,
            color: theme.palette.white.light,
          }}
          label={count}
        />
      ) : (
        <Tooltip title={tooltipText}>
          {count != null ? (
            <StyledChip
              sx={{
                background: backgroundColor,
              }}
              label={count}
            />
          ) : (
            <CircularProgress size={20} />
          )}
        </Tooltip>
      )}
    </>
  );
};

export default ParticipantSampleCount;
