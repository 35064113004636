import { styled } from "@mui/system";
import { Typography, Grid } from "@mui/material";

export const StyledErrorFallbackWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "100vh",
  width: "100vw",
  margin: "0",
  padding: "0",
  backgroundColor: theme.palette.grey[200],
  rowGap: "1rem",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    width: "fit-content",
    padding: "2rem 0",
    height: "130vh",
  },
}));

export const StyledErrorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.progress.main,
  fontWeight: "700",
  fontSize: "32px",
  textAlign: "center",
}));

export const StyledErrorSubTitleContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.progress.main,
  fontWeight: "500",
  fontSize: "20px",
}));
