import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";

interface SeparatorProps {
  activeStep: boolean;
}

const Separator: React.FC<SeparatorProps> = ({ activeStep }) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        margin: "0 1rem 0 0.3rem",
        fontWeight: activeStep ? "bold" : "normal",
        color: activeStep
          ? theme.palette.grey[700]
          : theme.palette.text.disabled,
      }}
    >
      {">"}
    </Typography>
  );
};

export default Separator;
