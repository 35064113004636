import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  ICreateRpcEvent,
  getDevicesStatus,
  createRpcEvent as _createRpcEvent,
  ICreateMultiRpcEvent,
} from "services/fleet.service";

import { QueryKeys } from "constants/queryKeys.constants";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import { CustomError } from "utils/privateHttp.utils";

interface Options {
  serialNumbers: string[];
  showRpcError?: boolean;
  refetchInterval?: number;
}

function useDevicesApi(initialOptions: Options) {
  const [options, setOptions] = useState<Options>(initialOptions);
  const queryClient = useQueryClient();
  const [refetchInterval, setRefetchInterval] = useState(
    options.refetchInterval || 5000
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showRpcError, setShowRpcError] = useState(
    options.showRpcError || false
  );

  const httpErrorNotification = useSnackbarStore(
    (state) => state.httpErrorNotification
  );
  const devices = useQuery({
    queryKey: [QueryKeys.DEVICES_STATUS, options.serialNumbers],
    queryFn: async () => getDevicesStatus(options.serialNumbers),
    refetchInterval,
    refetchIntervalInBackground: false,
  });

  const refreshDevice = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.DEVICES_STATUS, options.serialNumbers],
    });
  };

  const createRpcEvents = useMutation({
    mutationFn: async (rpcEvents: ICreateRpcEvent[] | ICreateMultiRpcEvent) => {
      const isSingleRpc = (rpcEvents: any): rpcEvents is ICreateRpcEvent[] =>
        Array.isArray(rpcEvents) &&
        rpcEvents.every((event: any) => "method" in event);
      const results = isSingleRpc(rpcEvents)
        ? await Promise.all(rpcEvents.map((event) => _createRpcEvent(event)))
        : await _createRpcEvent(rpcEvents);
      return results;
    },
    onError: (error: CustomError) => {
      if (showRpcError) httpErrorNotification(error);
    },
  });

  return {
    options,
    setOptions,
    devices,
    setRefetchInterval,
    refreshDevice,
    createRpcEvents,
  };
}

export default useDevicesApi;
