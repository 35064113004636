function getAssetsPath(path: string): string {
  return `${process.env.REACT_APP_GCP_BUCKET_URL}/assets/${path}`;
}

function getBucketPath(path: string): string {
  return `${process.env.REACT_APP_GCP_BUCKET_URL}/${path}`;
}

function getBucketUrl(): string {
  return `${process.env.REACT_APP_GCP_BUCKET_URL}`;
}

function checkEvaluationTimeout(timerSeconds: string, timeOut: string) {
  const [hours, minutes, timeSeconds] = timerSeconds.split(":").map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + timeSeconds;
  if (totalSeconds === Number(timeOut)) {
    return true;
  }
  return false;
}

export { getAssetsPath, getBucketPath, checkEvaluationTimeout, getBucketUrl };
