import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

interface TextErrorProps {
  children: React.ReactNode;
  sx?: { [key: string]: string };
}

const TextError = (props: TextErrorProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Typography style={{ color: theme.palette.error.dark, ...props.sx }}>
      {t(props.children as string)}
    </Typography>
  );
};

export default TextError;
