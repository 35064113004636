import { useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { StyleTitle, StyleSubTitle } from "./styles";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/system";
import Card, { getCardColor } from "components/Card";
import IllustrationCard from "components/IllustrationCard";
import { useInformationBlockStore } from "./informationblock.store";
import { maxPageWidth } from "constants/index";

export enum backgroundType {
  NONE = "NONE",
  GRADIENT = "GRADIENT",
}

const InformationBlock = () => {
  const show = useInformationBlockStore((state) => state.show);
  const status = useInformationBlockStore((state) => state.status);
  const title = useInformationBlockStore((state) => state.title);
  const subTitle = useInformationBlockStore((state) => state.subTitle);
  const description = useInformationBlockStore((state) => state.description);
  const illustrations = useInformationBlockStore(
    (state) => state.illustrations
  );
  const background = useInformationBlockStore((state) => state.background);

  const { t } = useTranslation();
  const theme = useTheme();
  const cardColor = useMemo(
    () => (status ? getCardColor(status, theme) : null),
    [status, theme]
  );
  if (!show) return;

  const cardBackgroundColor = status ? cardColor?.light : "transparent";
  const backgroundStyles = {
    display: "flex",
    minHeight: "17rem",
    borderRadius: "0",
    height: "100%",
    border: "none",
    maxWidth: maxPageWidth,
    width: "100%",
    margin: "1.5rem auto 2rem auto",
    padding: "1rem",
  };

  return (
    <Box
      sx={{
        background:
          background === backgroundType.GRADIENT
            ? cardColor.gradient
            : cardBackgroundColor,
      }}
    >
      <Card
        sx={{
          backgroundColor: "transparent",
          ...backgroundStyles,
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={title || subTitle || description ? 8 : 12}>
            {title && (
              <StyleTitle
                variant="h5"
                component="h1"
                sx={{ color: cardColor.darker }}
              >
                {t(title)}
              </StyleTitle>
            )}
            {subTitle && (
              <StyleSubTitle variant="subtitle1" component="h2">
                {t(subTitle)}
              </StyleSubTitle>
            )}
            {description && (
              <Typography
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{ __html: t(description) }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            sm={illustrations ? 4 : 0}
            sx={{
              maxWidth: { xs: "85%" },
              height: { xs: "auto", sm: "100%" },
            }}
          >
            {illustrations?.length ? (
              <IllustrationCard
                status={status}
                illustrations={illustrations}
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  backgroundImage: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default InformationBlock;
