import { SuccessIcon, TerminateIcon } from "components/icons";
import { AnnotationStatus } from "services/annotation.service";

const statusIcon: Record<AnnotationStatus, React.ElementType> = {
  [AnnotationStatus.COMPLETE]: SuccessIcon,
  [AnnotationStatus.FAILED]: TerminateIcon,
  [AnnotationStatus.PENDING]: TerminateIcon,
  [AnnotationStatus.TERMINATED]: TerminateIcon,
  [AnnotationStatus.IN_PROGRESS]: TerminateIcon,
  [AnnotationStatus.TIMEOUT]: TerminateIcon,
};

const replaceCenterSpace = (str: string, replaceStr: string) => {
  const middleIndex = Math.floor(str.length / 2);
  const leftPart = str.slice(0, middleIndex);
  const rightPart = str.slice(middleIndex);
  const lastSpaceIndex = leftPart.lastIndexOf(" ");
  const firstSpaceIndex = rightPart.indexOf(" ");
  let replaceIndex;
  if (
    lastSpaceIndex !== -1 &&
    middleIndex - lastSpaceIndex <= firstSpaceIndex
  ) {
    replaceIndex = lastSpaceIndex;
  } else if (firstSpaceIndex !== -1) {
    replaceIndex = middleIndex + firstSpaceIndex;
  } else {
    replaceIndex = -1;
  }

  if (replaceIndex !== -1) {
    return str.slice(0, replaceIndex) + "<br>" + str.slice(replaceIndex + 1);
  }
  return str;
};

export { statusIcon, replaceCenterSpace };
