import React from "react";
import { SvgIcon } from "@mui/material";

interface IconProps {
  sx?: any;
}

export const BaselineIcon: React.FC<IconProps> = ({ sx }) => {
  return (
    <SvgIcon viewBox="0 0 100 100" sx={sx}>
      <g clipPath="url(#clip0_1424_15995)">
        <path d="M96.2176 93.8408C96.2176 94.2633 96.0881 94.6546 95.8422 94.9676L89.9136 102.01C89.6677 102.307 89.3311 102.448 89.0075 102.448C88.671 102.448 88.3344 102.307 88.0755 101.994C87.5836 101.368 87.5837 100.367 88.0885 99.7565L91.7259 95.4058H7.57427C6.84938 95.4058 6.26688 94.7015 6.26688 93.8408V5.62223L2.70717 10.2703C2.46123 10.6146 2.09878 10.7711 1.74929 10.7711C1.43862 10.7711 1.10207 10.6302 0.856122 10.3485C0.338345 9.76946 0.312456 8.76786 0.791399 8.12622L6.61638 0.50471C7.13416 -0.168237 8.02732 -0.168237 8.53216 0.50471L14.3442 8.12622C14.8361 8.76786 14.8102 9.76946 14.2795 10.3485C13.7487 10.9432 12.9333 10.9119 12.4414 10.2703L8.86871 5.62223V43.7141C11.0563 40.7563 16.0658 35.0128 22.0461 35.0128C29.9422 35.0128 36.1555 44.9974 36.4144 45.4356C36.4791 45.5139 42.1617 54.6378 48.8022 54.6378C55.4556 54.6378 61.1253 45.5139 61.1771 45.4356C61.4359 44.9974 67.6622 35.0128 75.5453 35.0128C83.3767 35.0128 92.153 46.7815 92.5155 47.2823C92.9815 47.9083 92.9297 48.8473 92.4119 49.4107C91.8941 49.9741 91.1175 49.9115 90.6515 49.2855C90.5738 49.1603 82.2505 38.0176 75.5324 38.0176C68.879 38.0176 63.2093 47.1258 63.1576 47.2197C62.8987 47.6423 56.6724 57.6425 48.7893 57.6425C40.8932 57.6425 34.6669 47.6266 34.421 47.2197C34.3562 47.1258 28.6737 38.0176 22.0332 38.0176C15.3797 38.0176 9.71009 47.1258 9.65832 47.2197C9.46415 47.5327 9.16643 47.7362 8.84282 47.7988V64.9198H16.9201C17.4638 64.9198 17.9168 65.4675 17.9168 66.1248C17.9168 66.7821 17.4638 67.3298 16.9201 67.3298H8.84282V78.0657L8.79104 92.1819L11.6388 92.2758H91.7L88.0626 87.9251C87.5448 87.3304 87.5448 86.3288 88.0496 85.7029C88.5674 85.0769 89.3959 85.0612 89.8878 85.6872L95.8163 92.7297C96.0752 93.027 96.2176 93.4026 96.2176 93.8408Z" />
        <path d="M93.5192 64.9199H91.4481C90.9044 64.9199 90.4514 65.4677 90.4514 66.1249C90.4514 66.7822 90.9044 67.3299 91.4481 67.3299H93.5192C94.0629 67.3299 94.5159 66.7822 94.5159 66.1249C94.5159 65.4677 94.0758 64.9199 93.5192 64.9199ZM83.1766 64.9199H74.8922C74.3485 64.9199 73.8955 65.4677 73.8955 66.1249C73.8955 66.7822 74.3485 67.3299 74.8922 67.3299H83.1766C83.7203 67.3299 84.1733 66.7822 84.1733 66.1249C84.1733 65.4677 83.7203 64.9199 83.1766 64.9199ZM66.6207 64.9199H58.3363C57.7926 64.9199 57.3396 65.4677 57.3396 66.1249C57.3396 66.7822 57.7926 67.3299 58.3363 67.3299H66.6207C67.1644 67.3299 67.6174 66.7822 67.6174 66.1249C67.6174 65.4677 67.1644 64.9199 66.6207 64.9199ZM50.0648 64.9199H41.7804C41.2367 64.9199 40.7837 65.4677 40.7837 66.1249C40.7837 66.7822 41.2367 67.3299 41.7804 67.3299H50.0648C50.6085 67.3299 51.0615 66.7822 51.0615 66.1249C51.0486 65.4677 50.6085 64.9199 50.0648 64.9199ZM33.496 64.9199H25.2116C24.6679 64.9199 24.2148 65.4677 24.2148 66.1249C24.2148 66.7822 24.6679 67.3299 25.2116 67.3299H33.496C34.0397 67.3299 34.4927 66.7822 34.4927 66.1249C34.4927 65.4677 34.0526 64.9199 33.496 64.9199Z" />
      </g>
    </SvgIcon>
  );
};
