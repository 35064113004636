import { useParams } from "react-router-dom";
import { FieldConfig } from "utils/form.utils";
import FormContainer from "components/FormContainer";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";
import { useTranslation } from "react-i18next";
import { updateAnnotation } from "services/annotation.service";
import { useEffect, useState } from "react";
import DialogBox from "components/DialogBox/DialogBox.component";
import { useDialogBoxStore } from "components/DialogBox/useDialogBoxStore";

interface ParticipantLogFormModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleRedirect: () => void;
  schemaData: FieldConfig[];
  initialValues: any;
  isParticipantLogged: boolean;
}

const ParticipantLogFormModal: React.FC<ParticipantLogFormModalProps> = ({
  isModalOpen,
  handleCloseModal,
  handleRedirect,
  schemaData,
  initialValues,
  isParticipantLogged,
}) => {
  const { annotationId } = useParams();
  const { t } = useTranslation();
  const openErrorNotification = useSnackbarStore((state) => state.openError);
  const [formError, setFormError] = useState({
    isError: false,
    message: "",
  });
  const openDialogBox = useDialogBoxStore((state) => state.open);
  const closeDialogBox = useDialogBoxStore((state) => state.close);

  const onSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    setSubmitting(true);

    const { title, options, ...restValues } = values;

    const isAtLeastOneCheckboxChecked = options && options.length > 0;
    const areFieldDependenciesSatisfied = schemaData.every((field) => {
      if (field.dependencies) {
        const dependentFieldValue = values[field.name];
        const dependency = field.dependencies[0];
        if (values[dependency.field].includes(dependency.key)) {
          return dependentFieldValue !== "";
        }
      }
      return true;
    });

    const isFormValid =
      isAtLeastOneCheckboxChecked && areFieldDependenciesSatisfied;

    if (!isFormValid) {
      setFormError({
        isError: true,
        message: !areFieldDependenciesSatisfied
          ? t("trial.noze.participantLog.notFilled.other.error")
          : t("trial.noze.participantLog.notFilled.error"),
      });
    } else {
      try {
        annotationId &&
          updateAnnotation({
            annotationId,
            metadata: { participantLog: { options, ...restValues } },
          });
        handleCloseModal();
        handleRedirect();
      } catch (error: any) {
        openErrorNotification(error.message.toString());
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isParticipantLogged && isModalOpen) {
      const descriptionElement = (
        <FormContainer
          schemaData={schemaData}
          initialValues={initialValues}
          onSubmit={onSubmit}
          customError={formError}
          onClose={handleCloseModal}
          labels={{
            preSubmit: t("trial.noze.participantLog.button.label"),
            postSubmit: t("trial.noze.participantLog.button.label"),
          }}
        />
      );

      openDialogBox("participantLog", {
        title: "",
        description: descriptionElement,
        showCloseButton: false,
      });
    } else {
      setFormError({
        isError: false,
        message: "",
      });
      closeDialogBox("participantLog");
    }
  }, [isModalOpen, formError]);

  return (
    <DialogBox
      dialogKey="participantLog"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        margins: 0,
      }}
    />
  );
};

export default ParticipantLogFormModal;
